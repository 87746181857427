import React, { useState } from 'react'
import { updateClubPlayer } from '../../../Apis/clubs';
import Form from './Form';

export default function UpdatePlayer(props) {
  // onsubmit
  const onSubmit = async (e, data) => {
    e.preventDefault();
    let x = {...data, _method: "patch"}
    console.log(x)
    return await updateClubPlayer(props.id, x).then(res => {
      props.getNewData(props.obj)
      return 'done';
    }).catch(err => {
      console.log(err);
      return err.response.data.errors;
    })
  };

  return (
    <div className="modal-dialog modal-dialog-centered">
      {props.id && <Form onSubmit={onSubmit} id={props.id} clubId={props.clubId} />}
    </div>
  )
}
