import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { deleteClubPlayer, getClub, getClubOverView, getClubPlans, getClubPlayers, getCompitions } from '../../Apis/clubs'
import TitleContext from '../../Contexts/TitleContext'
import noImage from "../../Assets/Images/noImage.jpg"
import edit from "../../Assets/Icons/edit.svg"
import delete_icon from "../../Assets/Icons/delete.svg"
import '../profile.scss'
import Pagination from '../../Components/Pagination/Pagination'
import CreatePlayer from './PlayerForm/Create'
import UpdatePlayer from './PlayerForm/Update'
import { Chip, Tooltip } from '@mui/material'
import DeleteModal from '../../Utilities/deleteModal'
import CreatePresident from './PresdientForm/Create'
import UpdatePresident from './PresdientForm/Update'
import ClubPlan from '../../Components/Clubs/ClubPlanModal'
import plan from '../../Assets/Icons/plan.svg'
import plan1 from '../../Assets/Icons/plan1.svg'
import plan2 from '../../Assets/Icons/plan2.svg'
import NotDataFound from '../../Components/NotDataFound/notDataFound'
import Videos from '../../Components/Videos/Videos'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { map_key } from '../../Configuration/config';
import ProFeatures from '../../Components/Clubs/ProFeatures'
import mytype from '../../Assets/Icons/type.svg'
import SubscribersModal from '../../Components/CourseCard/SubscribersModal'
import OverviewCard from '../../Components/OverviewCard/OverviewCard'
import InfoCard from '../../Components/InfoCard/InfoCard'
import PermissionsContext from '../../Contexts/permissionsContext'

export default function ClubDetails() {
    const { changeTitle } = useContext(TitleContext)
    const { id } = useParams()
    const [club, setclub] = useState()
    const [plans, setPlans] = useState()
    const [Planid, setPlanid] = useState()
    const [players, setPlayers] = useState()
    const [pageCount, setPageCount] = useState(0)
    const [deletedId, setdeletedId] = useState('')
    const [selectedId, setselectedId] = useState('')
    const [filterObj, setfilterObj] = useState({ per_page: 12, page: 1, club_id: id })
    const [overview, setOverview] = useState([])
    const [competitions, setCompetitions] = useState([])
    const [open, setOpen] = useState(false)
    const { Permissions } = useContext(PermissionsContext);


    async function getData() {
        await getClub(id).then((res) => {
            setclub(res.data.data);
            setCoords({ lat: Number(res.data.data.latitude), lng: Number(res.data.data.longitude) })
            competitionsData(res.data.data.user_id)
        })
    }
    async function overviewData() {
        await getClubOverView(id).then((res) => {
            setOverview(res.data.data)
            console.log("OVERVIEW:::::::::::::", res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }
    async function competitionsData(userId) {
        await getCompitions(userId).then((res) => {
            setCompetitions(res.data.data)
            console.log(res.data.data)
        })
    }

    function getPlayersAtclub(filter) {
        let params = `per_page=${filter.per_page}&page=${filter.page}&club_id=${filter.club_id}`;
        //console.log(params)
        getClubPlayers(params).then((res) => {
            setPlayers(res.data.data)
            //console.log(res.data.data);
            let total = res.data.pagination.total;
            setPageCount(Math.ceil(total / filter.per_page));
        })
    }

    function clubPlans() {
        getClubPlans(id).then((res) => {
            setPlans(res.data.data)
        })
    }

    useEffect(() => {
        getData();
        getPlayersAtclub(filterObj)
        clubPlans()
        overviewData(id)

        changeTitle(t('club-details'));
        console.log(plans);
        // eslint-disable-next-line
    }, [])


    function handlepageChange(data) {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        getPlayersAtclub(filter)
    }

    async function onSubmitDelete() {
        return await deleteClubPlayer(deletedId).then(res => {
            getPlayersAtclub(filterObj);
            return "done";
        }).catch(err => {
            console.log(err.response.data.message)
            return err.response.data.message;
        })
    }

    /***********Location***** */
    const [libraries] = useState(['places']);
    const containerStyle = { width: '100%', height: '400px' };
    let [coords, setCoords] = useState({ lat: 21.492500, lng: 39.177570 });

    return (
        <React.Fragment>
            {club && <section className={`profile pt-0 mt-4`}>

                <InfoCard user={club} hasPresedent={true} club={true} />
                <OverviewCard overview={overview} />

                <div className='mx-auto my-4 mb-0 container postcard  blue text-dark d-flex align-items-center justify-content-evenly p-2' >
                    <div className={`d-flex flex-column align-items-center justify-content-center mb-0 mx-2 `}><h6 className='mb-0 '>{t('current-subscribers')} </h6>  {club.subscribers_count}</div>
                    <hr className="my-2 mx-3" style={{ borderLeft: "1px solid #ccc", height: "30px" }} />
                    <div className={`d-flex flex-column align-items-center justify-content-center mb-0 mx-2`}><h6 className='mb-0'>{t('total-subscribers')} </h6>  {club.subscribtions_count}</div>
                </div>

                <div className='mb-5'>
                    <div className='filterAtProfile'>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                            <div className={`tablinks p-1 active m-2`} id={`players-tab`} data-bs-toggle="tab" data-bs-target={`#players`} aria-controls="players"  >
                                {`${t("players")}`}
                            </div>
                            <div className={`tablinks p-1 m-2`} id={`videos-tab`} data-bs-toggle="tab" data-bs-target={`#videos`} aria-controls="videos" >
                                {`${t("videos")}`}
                            </div>
                            <div className={`tablinks p-1 m-2`} id={`compitions-tab`} data-bs-toggle="tab" data-bs-target={`#compitions`} aria-controls="compitions">
                                {`${t("competitions")}`}
                            </div>
                            <div className={`tablinks p-1 m-2`} id={`plans-tab`} data-bs-toggle="tab" data-bs-target={`#plans`} aria-controls="plans">
                                {`${t("Plans")}`}
                            </div>
                            <div className={`tablinks p-1 m-2`} id={`proAccFeature-tab`} data-bs-toggle="tab" data-bs-target={`#proAccFeature`} aria-controls="proAccFeature">
                                {`${t("pro-features")}`}
                            </div>
                            <div className={`tablinks p-1 m-2`} id={`location-tab`} data-bs-toggle="tab" data-bs-target={`#location`} aria-controls="plans">
                                {`${t("location")}`}
                            </div>
                        </div>
                    </div>

                    <div className='tab-content' id="pills-tabContent">
                        <div className={`tab-pane fade m-0 p-0 show`} id="compitions" role="tabpanel" aria-labelledby="compitions">
                            {console.log(competitions)}
                            {competitions.length > 0 ?
                                competitions?.map((comp) => (
                                    <article className="postcard blue myModal mx-4 my-2">
                                        <div className="postcard__img_link bg-light position-relative">
                                            <img className="postcard__img position-relative" src={comp?.media?.url} alt="" />
                                        </div>
                                        <div className="postcard__text t-dark w-100">
                                            <h1 className="postcard__title blue d-flex align-items-center justify-content-between">
                                                <div className='text-dark' >{comp?.title}</div>
                                                {<Chip className={` fw-bold px-3 ${comp?.type === 1 ? "bg-primary text-light" : "bg-warning text-light"}`}
                                                    icon={<img alt='' width={"30px"} src={mytype} />} label={comp?.type === 1 ? t("public") : t("private")} />}
                                            </h1>
                                            <div className="postcard__subtitle small d-flex justify-content-between align-items-center w-100">
                                                <b> {t("prize")}: {comp.prize}</b>
                                                {comp?.winners?.length > 0 &&
                                                    <b className="bg-warning text-dark subscriber p-2 border rounded" style={{ cursor: "pointer" }}>
                                                        <div className="mr-2" onClick={() => setOpen(true)}> {t("winners")} :  {comp?.winners?.length}</div>
                                                    </b>
                                                }
                                            </div>
                                            <div className="postcard__bar"></div>
                                            <ul className="postcard__tagbox mt-1 text-light justify-content-evenly align-items-center text-center border rounded mb-2">
                                                <li className="tag__item bg-transparent text-dark flex-grow-1 p-1"><i className="mr-2"></i> <b>{t("winners-count")} :  </b>{comp?.winners_count}</li>
                                                <hr style={{ borderLeft: "2px solid #0005", height: "15px", padding: "0", margin: "0" }} />
                                                <li className="tag__item bg-transparent text-dark flex-grow-1 p-1"><i className=" mr-2"></i><b>{t("ends-at")} : </b> {comp?.ends_at_days} {t('days')} {/**at {comp.ends_at_hours}*/} </li>
                                                <hr style={{ borderLeft: "2px solid #0005", height: "15px", padding: "0", margin: "0" }} />
                                                <li className="tag__item bg-transparent text-dark flex-grow-1 p-1"><i className="mr-2"></i> <b>{t("subscribers")} :  </b> {comp?.subscribers_count}</li>
                                            </ul>
                                            <div className="postcard__preview-txt">
                                                <b>
                                                    {t("conditions")}:
                                                </b>
                                                <p>
                                                    {comp?.description}
                                                </p>
                                            </div>

                                        </div>
                                        <SubscribersModal open={open} handleClose={() => setOpen(false)} data={comp?.winners} />
                                    </article>
                                ))
                                :
                                <div className='w-100'>
                                    <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
                                </div>}

                        </div>
                        <div className={`tab-pane fade m-0 p-0 active show`} id="players" role="tabpanel">
                            <div className='d-flex justify-content-between'>
                                <div className='addBtn mx-3 bg-success text-light border-0' >
                                    {t('players-count') + " : " + players?.length ?? 0}
                                </div>
                                {Permissions?.club?.edit &&
                                    <div className='addBtn ' data-bs-toggle="modal" data-bs-target='#playerModal' onClick={() => setselectedId('')}>
                                        {t('add-player')}
                                    </div>}
                            </div>
                            {players?.length > 0 ?
                                <>
                                    <div className='m-3 p-0 d-flex flex-wrap '>
                                        {players?.map((item, index) => {
                                            return (
                                                <div className='m-1 flex-grow-1  p-2 d-flex rounded justify-content-between straightPostcard   blue ' style={{ maxWidth: "400px" }} key={index}>
                                                    <div className='d-flex' style={{ maxWidth: "75%", width: "75%", overflow: 'hidden' }}>
                                                        <div className='playerImg border text-dark' style={{ backgroundImage: `url(${item?.media?.webp ?? noImage})` }}></div>
                                                        <div>
                                                            <p className={`fullName`}>{item?.full_name}</p>
                                                            <p className={`editedUserEmail text-dark`}>{item?.player_position?.name}</p>
                                                            <p className={`editedUserEmail text-dark`}>{item?.email}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ zIndex: "9" }}>
                                                        {Permissions?.club?.edit && <>
                                                            <Tooltip title='edit'>
                                                                <img className='iconActions' src={edit} alt="edit" data-bs-toggle="modal" data-bs-target='#playerModal' onClick={() => setselectedId(item?.id)} />
                                                            </Tooltip>
                                                            <Tooltip title='delete'>
                                                                <img className='iconActions' data-bs-toggle="modal" data-bs-target='#deleteModal' src={delete_icon} alt="delete" onClick={() => setdeletedId(item?.id)} />
                                                            </Tooltip></>}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {pageCount > 1 && <div className='d-flex justify-content-end mt-4'>
                                        <Pagination pagesNumber={pageCount} page={0} pageChangeHandler={handlepageChange} />
                                    </div>}

                                </>
                                :
                                <div className='w-100'>
                                    <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
                                </div>}
                        </div>

                        <div className={`tab-pane fade m-0 p-0 show`} id="videos" role="tabpanel" aria-labelledby="videos">
                            <Videos type={club} />
                        </div>

                        <div className={`tab-pane fade m-1 my-0 py-0 p-0 show`} id="proAccFeature" role="tabpanel" aria-labelledby="proAccFeature">
                            <ProFeatures id={club.id} />
                        </div>

                        <div className={`tab-pane fade m-4 show`} id="location" role="tabpanel" aria-labelledby="location">
                            <LoadScript id="script-loader" googleMapsApiKey={map_key} libraries={libraries}>
                                <GoogleMap mapContainerStyle={containerStyle} center={coords} zoom={18} draggable={true}  >
                                    <Marker position={coords} />
                                </GoogleMap>
                            </LoadScript>
                        </div>

                        <div className={`tab-pane fade m-0 p-0 show px-4`} id="plans" role="tabpanel" aria-labelledby="plans">
                            <div className='d-flex justify-content-end'>
                                {Permissions?.club?.edit &&
                                    // plans?.length < 3 &&
                                    <div className='addBtn' data-bs-toggle="modal" data-bs-target='#plansModal' onClick={() => setPlanid('')}>
                                        {t('add-plans')}
                                    </div>
                                }
                            </div>
                            {plans?.length ?
                                <>
                                    <div className='d-flex flex-wrap '>
                                        {plans?.map((item, index) => {
                                            return (
                                                <div className=' postcard  yellow myModal mx-4 my-2 text-dark mx-2 mb-2 flex-grow-1 flex-wrap' key={index} >
                                                    <div className=' d-flex playerContainer justify-content-between flex-grow-1'>
                                                        <div className='d-flex w-100 pb-1 mb-2 flex-grow-1' style={{ maxWidth: "100%", width: "75%", overflow: 'hidden' }}>
                                                            <div className='d-flex flex-column justify-content-center align-items-center m-1 '  >
                                                                <p className={`fullName text-center m-0 fw-bold`}>{item?.type?.name}</p>
                                                                <div className='playerImg m-0 ' style={{ backgroundImage: `url(${item?.type?.id === 1 ? plan : item?.type?.id === 2 ? plan1 : plan2})`, scale: ".9" }}></div>
                                                                <p className={`text-center p-1 fw-semibold mx-1`}><b className='px-1'>{item?.price}</b>{t("sar")}</p>
                                                            </div>
                                                            <div className='flex-grow-1 my-2  w-100 d-flex flex-column justify-content-start'>
                                                                <div className='d-flex justify-content-evenly align-items-center text-center border-bottom fw-bold' style={{ scale: ".92" }}>
                                                                    <div className={`d-flex flex-column align-items-center justify-content-center m-0  `} ><h6 className='mb-0 '>{t('current-subscribers')} </h6>  {item?.subscribtions_count}</div>
                                                                    <hr className=" " style={{ borderLeft: "1px solid #ccc", height: "30px" }} />
                                                                    <div className={`d-flex flex-column align-items-center justify-content-center m-0 `} ><h6 className='mb-0 '>{t('total-subscribers')} </h6>  {item?.subscribtions_count}</div>
                                                                </div>
                                                                <p className={`editedUserEmail text-center my-2 h-100 `}>  {item?.description} </p>
                                                            </div>
                                                        </div>
                                                        {/* <div style={{ zIndex: 9, margin: "8px" }}>
                                                            <Tooltip title='edit'>
                                                                <img className='iconActions' src={edit} alt="edit" data-bs-toggle="modal" data-bs-target='#plansModal' onClick={() => setPlanid((item?.type?.id))} />
                                                            </Tooltip>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                                :
                                <div className='w-100'>
                                    <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </section>}

            <div className="modal fade" id="playerModal" tabIndex={-1} aria-hidden="true">
                {
                    selectedId ?
                        <UpdatePlayer id={selectedId} getNewData={getPlayersAtclub} clubId={id} obj={filterObj} />
                        :
                        <CreatePlayer getNewData={getPlayersAtclub} clubId={id} obj={filterObj} />
                }
            </div>

            <div className="modal fade" id="presidentModal" tabIndex={-1} aria-hidden="true">
                {
                    club?.club_president ?
                        <UpdatePresident getNewData={getData} clubId={id} data={club?.club_president} />
                        :
                        <CreatePresident getNewData={getData} clubId={id} />
                }
            </div>

            <div className='clubPlanModal'>
                <div className="modal fade" id="plansModal" tabIndex={-1} aria-hidden="true">
                    <ClubPlan allPlans={plans} id={Planid} clubId={id} Refresh={clubPlans} />
                </div>
            </div>

            <DeleteModal onSubmitDelete={onSubmitDelete} name='player'></DeleteModal>
            <DeleteModal onSubmitDelete={onSubmitDelete} name='plan'></DeleteModal>
        </React.Fragment>
    )
}
