import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router';
import Subside from '../../Components/SubSide/Subside';
import { Route, Routes } from "react-router-dom";
import TitleContext from '../../Contexts/TitleContext';
import AddRole from './RolesForm/Add';
import EditRole from './RolesForm/Edit';
import { useState } from 'react';
import { getRoles } from '../../Apis/roles';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import PermissionsContext from '../../Contexts/permissionsContext';

export default function Roles() {
    const { pathname } = useLocation();
    const { i18n } = useTranslation();
    const { Permissions } = useContext(PermissionsContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    let { changeTitle } = useContext(TitleContext);
    const [allRoles, setAllRoles] = useState([])

    useEffect(() => {
        changeTitle(t("roles"),);
        getAllRoles()
        // eslint-disable-next-line
    }, [i18n.language])

    function getAllRoles() {
        getRoles().then(res => {
            setAllRoles(res.data.data);
            console.log(res.data.data)
        })
    }
    return (
        <>
            {
                Permissions?.role &&
                <section className='subside_action_wrapper'>
                    {Permissions?.role?.view && <Subside items={allRoles} btnText={`+ ${t('add-role')}`} btnUrl="admins/roles" />}
                    <Routes>
                        {Permissions?.role?.create && <Route path='add' element={<AddRole getAll={getAllRoles} />} />}
                        {Permissions?.role?.edit && <Route path='update/:id' element={<EditRole getAll={getAllRoles} />} />}
                    </Routes>
                </section>
            }
        </>
    )
}
