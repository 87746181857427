import { useContext } from 'react';
import React from 'react'
import PermissionsContext from '../Contexts/permissionsContext';
import { Route, Routes } from 'react-router';
import AcademyLevels from '../Pages/AcademyLevel/AcademyLevels';
import AddAcademyLevels from '../Pages/AcademyLevel/Form/Add';
import EditAcademyLevels from '../Pages/AcademyLevel/Form/Edit';
import Articles from '../Pages/Articles/Articles';
import Cities from '../Pages/Cities/Cities';
import AddCities from '../Pages/Cities/Form/Add';
import EditCities from '../Pages/Cities/Form/Edit';
import Countries from '../Pages/Countries/Countries';
import AddCountry from '../Pages/Countries/Form/Add';
import EditCountry from '../Pages/Countries/Form/Edit';
import Overview from '../Pages/Overview/overview';
import AddPlayerFoots from '../Pages/PlayerFoots/Form/Add';
import EditPlayerFoots from '../Pages/PlayerFoots/Form/Edit';
import PlayerFoots from '../Pages/PlayerFoots/PlayerFoots';
import AddPlayerPositions from '../Pages/PlayersPositions/Form/Add';
import EditPlayerPositions from '../Pages/PlayersPositions/Form/Edit';
import PlayerPositions from '../Pages/PlayersPositions/PlayersPositions';
import AddTags from '../Pages/Tags/Form/Add';
import EditTags from '../Pages/Tags/Form/Edit';
import Tags from '../Pages/Tags/Tags';
import AddTrainerLevels from '../Pages/TrainerLevel/Form/Add';
import EditTrainerLevels from '../Pages/TrainerLevel/Form/Edit';
import TrainerLevels from '../Pages/TrainerLevel/TrainerLevels';
import AddArticles from '../Pages/Articles/Form/Add';
import EditArticles from '../Pages/Articles/Form/Edit';
import EditAccountSettings from '../Pages/Account/Edit';
import EditSecuritySettings from '../Pages/Security/Edit';
import Academies from '../Pages/Academies/Academies';
import Players from '../Pages/Players/Players';
import Clubs from '../Pages/Clubs/Clubs';
import AcademyDetails from '../Pages/Academies/Details';
import PlayerDetails from '../Pages/Players/Details';
import ClubDetails from '../Pages/Clubs/Details';
import AddClubs from '../Pages/Clubs/ClubForm/Add';
import EditClubs from '../Pages/Clubs/ClubForm/Edit';
import Trainers from '../Pages/Trainers/Trainers';
import TrainersDetails from '../Pages/Trainers/Details';
import Journalists from '../Pages/Journalists/Journalists';
import JournalistDetails from '../Pages/Journalists/Details';
import Businesses from '../Pages/Businesses/Businesses';
import BusinessDetails from '../Pages/Businesses/Details';
import Federations from '../Pages/Federations/Federations';
import AddFederations from '../Pages/Federations/Form/Add';
import EditFederations from '../Pages/Federations/Form/Edit';
import Influencers from '../Pages/Influencers/Influencers';
import InfluencerDetails from '../Pages/Influencers/Details';
import Fans from '../Pages/Fans/Fans';
import FanDetails from '../Pages/Fans/Details';
import Spams from '../Pages/Spams/Spams';
import AddSpam from '../Pages/Spams/Form/Add';
import EditSpam from '../Pages/Spams/Form/Edit';
import AdminsList from '../Pages/Admins/AdminsList';
import AddAdmin from '../Pages/Admins/AdminsForm/Add';
import EditAdmin from '../Pages/Admins/AdminsForm/Edit';
import Roles from '../Pages/Roles/Roles';
import StaticPages from '../Pages/Appearance/Pages';
import Aboutus from '../Pages/Appearance/Aboutus/Aboutus';
import PrivacyPolicy from '../Pages/Appearance/Privacy policy/PrivacyPolicy';
import CoursesConditions from '../Pages/Appearance/Courses Conditions/CoursesConditions';
import TermsAndConditions from '../Pages/Appearance/Terms and conditions/TermsAndConditions';
import FederationDetails from '../Pages/Federations/Details';
import Music from '../Pages/Music/Music';
import MusicForm from '../Pages/Music/Form';
import AppUsers from '../Pages/AllUsers/AllUsersUsers';
import Courses from '../Pages/Courses/Courses';
import CourseDetails from '../Pages/Courses/Details';
import Financials from '../Pages/Fees/Financials';
import ContactUs from '../Pages/ContactUs/ContactUs';
import Reports from '../Pages/Reports/Reports';
import Stickers from '../Pages/Stickers/Stickers';
import Currency from '../Pages/Currency/Currency';
import AddCurrency from '../Pages/Currency/Form/Add';
import EditCurrency from '../Pages/Currency/Form/Edit';
import PromotedVideos from '../Pages/PromotedVideos/Videos';
import DeleteRequests from '../Pages/DeleteRequests/DeleteRequests';

export default function AllRoutes() {

    const { Permissions } = useContext(PermissionsContext)

    return (
        <>
            <Routes>
                <Route path="overview" element={<Overview />} />
                {Permissions?.contact?.view && <Route path="contactus" element={<ContactUs />} />}

                {(Permissions?.admin?.view || Permissions?.role?.view) &&
                    <Route path='admins'>
                        {Permissions?.admin?.view && <Route path='' element={<AdminsList />} />}
                        {Permissions?.admin?.create && <Route path='create' element={<AddAdmin />} />}
                        {Permissions?.admin?.edit && <Route path=':id/update' element={<EditAdmin />} />}
                        {Permissions?.role?.view && <Route path='roles/*' element={<Roles />} />}
                    </Route>}

                <Route path='levels'>
                    {Permissions['academy-level']?.view &&
                        <Route path='academy'>
                            {Permissions['academy-level']?.view && <Route path='' element={<AcademyLevels />} />}
                            {Permissions['academy-level']?.create && <Route path='create' element={<AddAcademyLevels />} />}
                            {Permissions['academy-level']?.edit && <Route path=':id/update' element={<EditAcademyLevels />} />}
                        </Route>}
                    {Permissions['trainer-experience-level']?.view &&
                        <Route path='trainer'>
                            {Permissions['trainer-experience-level']?.view && <Route path='' element={<TrainerLevels />} />}
                            {Permissions['trainer-experience-level']?.create && <Route path='create' element={<AddTrainerLevels />} />}
                            {Permissions['trainer-experience-level']?.edit && <Route path=':id/update' element={<EditTrainerLevels />} />}
                        </Route>}
                </Route>

                {Permissions['player-position']?.view &&
                    <Route path='player-positions'>
                        {Permissions['player-position']?.view && <Route path='' element={<PlayerPositions />} />}
                        {Permissions['player-position']?.create && <Route path='create' element={<AddPlayerPositions />} />}
                        {Permissions['player-position']?.edit && <Route path=':id/update' element={<EditPlayerPositions />} />}
                    </Route>}

                {Permissions['player-footnesse']?.view &&
                    <Route path='player-foots'>
                        {Permissions['player-footnesse']?.view && <Route path='' element={<PlayerFoots />} />}
                        {Permissions['player-footnesse']?.create && <Route path='create' element={<AddPlayerFoots />} />}
                        {Permissions['player-footnesse']?.edit && <Route path=':id/update' element={<EditPlayerFoots />} />}
                    </Route>}

                {Permissions['sound']?.view &&
                    <Route path='/sound'>
                        {Permissions['player-footnesse']?.view && <Route path='' element={<Music />} />}
                        {Permissions['player-footnesse']?.create && <Route path='create' element={<MusicForm />} />}
                        {Permissions['player-footnesse']?.edit && <Route path=':id/update' element={<MusicForm />} />}
                    </Route>}

                {Permissions['sticker']?.view && <Route path='stickers'>
                    <Route path='' element={<Stickers />} />
                </Route>}

                {Permissions['trainer']?.view && <Route path='courses'>
                    <Route path='' element={<Courses />} />
                    <Route path=':id/details' element={<CourseDetails />} />
                </Route>}
                
                {/* /***************U need To change Delete req Permissions ************* */}
                {Permissions?.user?.['block-unblock'] && <Route path='deletedreq' element={<DeleteRequests />} />} 

                <Route path='settings'>
                    {Permissions['setting']?.view && <Route path='appearance' element={<StaticPages />} />}
                    {Permissions['setting']?.view && <Route path='appearance/about-us' element={<Aboutus />} />}
                    {Permissions['setting']?.view && <Route path='appearance/terms-and-conditions' element={<TermsAndConditions />} />}
                    {Permissions['setting']?.view && <Route path='appearance/privacy-policy' element={<PrivacyPolicy />} />}
                    {Permissions['setting']?.view && <Route path='appearance/courses-conditions' element={<CoursesConditions />} />}
                    {Permissions['setting']?.view && <Route path='countries'>
                        {Permissions['setting']?.view && <Route path='' element={<Countries />} />}
                        {Permissions['setting']?.edit && <Route path='create' element={<AddCountry />} />}
                        {Permissions['setting']?.edit && <Route path=':id/update' element={<EditCountry />} />}
                    </Route>}
                    {Permissions['setting']?.view && <Route path='cities'>
                        {Permissions['setting']?.view && <Route path='' element={<Cities />} />}
                        {Permissions['setting']?.edit && <Route path='create' element={<AddCities />} />}
                        {Permissions['setting']?.edit && <Route path=':id/update' element={<EditCities />} />}
                    </Route>}
                    {Permissions['setting']?.view && <Route path='currency'>
                        {Permissions['setting']?.view && <Route path='' element={<Currency />} />}
                        {Permissions['setting']?.edit && <Route path='create' element={<AddCurrency />} />}
                        {Permissions['setting']?.edit && <Route path=':id/update' element={<EditCurrency />} />}
                    </Route>}
                    <Route path='account' element={<EditAccountSettings />} />
                    <Route path='security' element={<EditSecuritySettings />} />
                </Route>

                {Permissions['setting']?.view && <Route path='Financials'>
                    <Route path='' element={<Financials />} />
                </Route>}
                {Permissions?.promote?.view && <Route path='promotes'>
                    <Route path='' element={<PromotedVideos />} />
                </Route>}

                {(Permissions['blog']?.view || Permissions['tag']?.view) &&
                    <Route path='articles'>
                        {Permissions['blog']?.view && <Route path='' element={<Articles />} />}
                        {Permissions['blog']?.create && <Route path='create' element={<AddArticles />} />}
                        {Permissions['blog']?.edit && <Route path=':id/update' element={<EditArticles />} />}

                        {Permissions['tag']?.view &&
                            <Route path='tags'>
                                {Permissions['tag']?.view && <Route path='' element={<Tags />} />}
                                {Permissions['tag']?.create && <Route path='create' element={<AddTags />} />}
                                {Permissions['tag']?.edit && <Route path=':id/update' element={<EditTags />} />}
                            </Route>}
                    </Route>}

                <Route path='app-users'>
                    {Permissions?.user?.view && <Route path='users'>
                        <Route path='' element={<AppUsers />} />
                    </Route>}
                    {Permissions?.academy?.view && <Route path='academies'>
                        <Route path='' element={<Academies />} />
                        <Route path=':id/details' element={<AcademyDetails />} />
                    </Route>}
                    {Permissions?.player?.view && <Route path='players'>
                        <Route path='' element={<Players />} />
                        <Route path=':id/details' element={<PlayerDetails />} />
                    </Route>}
                    {Permissions?.club?.view && <Route path='clubs'>
                        <Route path='' element={<Clubs />} />
                        {Permissions?.club?.view && <Route path=':id/details' element={<ClubDetails />} />}
                        {Permissions?.club?.create && <Route path='create' element={<AddClubs />} />}
                        {Permissions?.club?.edit && <Route path=':id/update' element={<EditClubs />} />}
                    </Route>}
                    {Permissions?.trainer?.view && <Route path='trainers'>
                        <Route path='' element={<Trainers />} />
                        <Route path=':id/details' element={<TrainersDetails />} />
                    </Route>}
                    {Permissions?.journalist?.view && <Route path='journalists'>
                        <Route path='' element={<Journalists />} />
                        <Route path=':id/details' element={<JournalistDetails />} />
                    </Route>}
                    {Permissions?.business?.view && <Route path='businesses'>
                        <Route path='' element={<Businesses />} />
                        <Route path=':id/details' element={<BusinessDetails />} />
                    </Route>}
                    {Permissions?.federation?.view && <Route path='federations'>
                        <Route path='' element={<Federations />} />
                        <Route path=':id/details' element={<FederationDetails />} />
                        {Permissions?.federation?.create && <Route path='create' element={<AddFederations />} />}
                        {Permissions?.federation?.edit && <Route path=':id/update' element={<EditFederations />} />}
                    </Route>}
                    {Permissions?.influencer?.view && <Route path='influencers'>
                        <Route path='' element={<Influencers />} />
                        <Route path=':id/details' element={<InfluencerDetails />} />
                    </Route>}
                    {Permissions?.fan?.view && <Route path='fans'>
                        <Route path='' element={<Fans />} />
                        <Route path=':id/details' element={<FanDetails />} />
                    </Route>}
                </Route>

                {Permissions?.spam?.view &&
                    <Route path='spams' >
                        <Route path='' element={<Spams />} />
                        {Permissions?.spam?.create && <Route path='create' element={<AddSpam />} />}
                        {Permissions?.spam?.edit && <Route path=':id/update' element={<EditSpam />} />}
                    </Route>}

                {Permissions?.report?.view && <Route path="reports" element={<Reports />} />}


            </Routes>

        </>
    )
}
