import React, { useState, useEffect, useContext } from 'react'
import '../tables.scss'
import { t } from 'i18next'
import i18n from '../../i18n'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import Tooltip from '@mui/material/Tooltip'
import noImg from "../../Assets/Images/noImg.png"
import search from "../../Assets/Icons/search.svg"
import edit_icon from "../../Assets/Icons/edit.svg"
import { basename } from '../../Configuration/config'
import DeleteModal from '../../Utilities/deleteModal'
import TitleContext from '../../Contexts/TitleContext'
import delete_icon from "../../Assets/Icons/delete.svg"
import { getSounds, deleteSound } from '../../Apis/sounds'
import { dateLanguage } from '../../Utilities/dateLanguage'
import Pagination from '../../Components/Pagination/Pagination'
import NotDataFound from '../../Components/NotDataFound/notDataFound'
import PermissionsContext from '../../Contexts/permissionsContext'

export default function Stickers() {

    const [sound, setSound] = useState([])
    const { pathname } = useLocation()
    const [deletedId, setDeletedId] = useState('')
    const [pageCount, setPageCount] = useState('')
    const { changeTitle } = useContext(TitleContext)
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '' })
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '' })
    const { Permissions } = useContext(PermissionsContext)

    useEffect(() => {
        window.scrollTo(0, 0)
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    }, [pathname, i18n.language])


    useEffect(() => {
        changeTitle(t(`sound`), Permissions?.sound?.create && { url: 'sound/create', text: t('add-sound') ,canAdd:Permissions?.sound?.create  })
        Sounds(filterObj)
        // eslint-disable-next-line
    }, [])

    const Sounds = async (obj) => {
        let filter = { ...obj }
        let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}`;
        try {
            const res = await getSounds(params)
            console.log(res.data.data)
            setSound(res.data.data)
            let total = res.data.pagination.total;
            setPageCount(Math.ceil(total / filter.per_page))
        } catch (err) {
            console.log(err)
        }
    }

    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj }
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        setfilterObj(thisObj);
        Sounds(thisObj)
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        Sounds(filterObj)
        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        setfilterObj2(thisObj2);
    }

    async function onSubmitDelete() {
        return await deleteSound(deletedId).then(res => {
            Sounds(filterObj)
            return "done"
        }).catch(err => {
            console.log(err.response.data.message)
            return err.response.data.message
        })
    }

    return (
        <>
            {<section className='componentGlobalWrapper d-flex flex-column'>
                <div>

                    <div className='searchActionsBar w-100'>
                        <div className='searchActionsBar mt-0 mb-0'>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                        </div>

                        {/* show select */}
                        <div className='perPageContainer'>
                            <label htmlFor='selectShow' className='perPageLabel'>
                                {t('show')}
                            </label>
                            <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                            </select>
                        </div>

                        {/*** apply search btn  ***/}
                        <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
                    </div>

                    <div className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr >
                                    <th>{t('cover')}</th>
                                    <th>{t('title')}</th>
                                    <th>{t('singer')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sound.length > 0
                                    ? sound.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='sliderImg'> <img className='w-75' src={item?.image ? item?.image : noImg} alt="slider" /></td>
                                                <td className='desc'> <div className='descriptionTd'> {item?.title} </div> </td>
                                                <td> {item?.singer} </td>
                                                <td>
                                                    <div>
                                                        {Permissions?.sound?.edit && <Tooltip title={t("edit")}>
                                                            <Link to={`${basename}/dashboard/sound/${item.id}/update`}>
                                                                <img className='iconActions' src={edit_icon} alt="edit" />
                                                            </Link>
                                                        </Tooltip>}
                                                        {Permissions?.sound?.delete && <Tooltip title={t("delete")}>
                                                            <img data-bs-toggle="modal" onClick={() => setDeletedId(item.id)} data-bs-target='#deleteModal' className='iconActions' src={delete_icon} alt="delete" />
                                                        </Tooltip>}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : <tr>
                                        <td colSpan={6} >
                                            <NotDataFound />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <DeleteModal onSubmitDelete={onSubmitDelete} name='sound'></DeleteModal>
                    </div>
                </div>
                <div className='d-flex justify-content-end mt-auto'>
                    <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
                </div>
            </section>}
        </>
    )
}
