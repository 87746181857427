import React from 'react';
import Form from './Form';
import { useParams } from 'react-router';
import { updateAdmin } from '../../../Apis/admins';

export default function EditAdmin() {
    const { id } = useParams();
    // onsubmit
    const onSubmit = async (data) => {
        console.log(data)
        let edited = { ...data }
        edited.role = edited.role.id ?? edited.role
        return await updateAdmin(id, edited).then(res => {
            console.log(res)
            return 'done';
        }).catch(err => {
            console.log(err)
            return err.response.data.errors;
        })
    };

    return (
        <Form onSubmit={onSubmit}></Form>
    )
}