import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getInfluencer, getInfluencerOverView } from '../../Apis/influencers'
import TitleContext from '../../Contexts/TitleContext'
import noImg from "../../Assets/Images/noImg.png"
import '../profile.scss'
import NotDataFound from '../../Components/NotDataFound/notDataFound'
import Videos from '../../Components/Videos/Videos'
import OverviewCard from '../../Components/OverviewCard/OverviewCard'
import InfoCard from '../../Components/InfoCard/InfoCard'

export default function InfluencerDetails() {
    const { changeTitle } = useContext(TitleContext)
    const { id } = useParams()
    const [influencer, setInfluencer] = useState()
    const [overview, setOverview] = useState()

    //const { Permissions } = useContext(PermissionsContext);
    function getData() {
        getInfluencer(id).then((res) => {
            setInfluencer(res.data.data);
            console.log(res.data.data)
        })
    }

    useEffect(() => {
        getData();
        overviewData()
        changeTitle(t('influencer-details'));
        // eslint-disable-next-line
    }, [])
    async function overviewData() {
        await getInfluencerOverView(id).then((res) => {
            setOverview(res.data.data)
            console.log("OVERVIEW:::::::::::::", res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }
    console.log(influencer)


    return (
        <React.Fragment>
            {influencer && <section className={`profile pt-0 mt-4`}>
                <InfoCard user={influencer}  />
                {/*******Statics*********/}

                <OverviewCard overview={overview} />

                <div className='mb-5'>
                    <div className='filterAtProfile'>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                            {/* <div className={`tablinks active`} id={`courses-tab`} data-bs-toggle="tab" data-bs-target={`#courses`} aria-controls="courses"  >
                                {t("courses")}
                            </div> */}
                            <div className={`tablinks active`} id={`videos-tab`} data-bs-toggle="tab" data-bs-target={`#videos`} aria-controls="videos" >
                                {t("videos")}
                            </div>
                        </div>
                    </div>

                    <div className='tab-content' id="pills-tabContent">

                        <div className={`tab-pane fade m-0 p-0 active  show`} id="videos" role="tabpanel" aria-labelledby="videos">
                            <Videos type={influencer} />
                        </div>
                        {/* 
                        <div className={`tab-pane fade m-0 p-0 active show`} id="courses" role="tabpanel" aria-labelledby="courses">
                            {courses
                                ??
                                <div className='w-100'>
                                    <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
                                </div>
                            }
                        </div> */}
                    </div>

                </div>
            </section>}



        </React.Fragment>
    )
}
