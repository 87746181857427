export default function VideoModal({ video }) {
    return (
        <div className="modal fade " id="videoModel" tabIndex={-1} aria-labelledby="toggleblockIpModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content ">
                    <label className="m-3 mt-4">Reported Video </label>
                    <video controls height="500px" className='border rounded m-1'>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}