import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllPlayers(obj){
    return await https.get(`${api_url}admin/players?${obj}`);
}

//get players by id
export async function getPlayer(id){
    return await https.get(`${api_url}admin/players/${id}`);
}
//get playersOverView by id
export async function getPlayerOverview(id){
    return await https.get(`${api_url}admin/players/${id}/overview`);
}

// accept players
export async function unblockPlayer(id){
    return await https.post(`${api_url}admin/players/${id}/unblock`);
}

// block players
export async function blockPlayer(id){
    return await https.post(`${api_url}admin/players/${id}/block`);
}

// accept players
export async function acceptPlayer(id){
    return await https.post(`${api_url}admin/players/${id}/accept`);
}

// reject players
export async function rejectPlayer(id,data){
    return await https.post(`${api_url}admin/players/${id}/reject`,data);
}
