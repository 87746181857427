import { https } from './https';
import { api_url } from './../Configuration/config';

// get all Sounds
export async function getSounds(obj){
    return await https.get(`${api_url}admin/sounds?${obj}`);
}

// get Sound by id
export async function getSound(id){
    return await https.get(`${api_url}admin/sounds/${id}`);
}

// Add Sound
export async function AddSound(data){
    return await https.post(`${api_url}admin/sounds`,data);
}

// Update Sound
export async function UpdateSound(id, data){
    return await https.post(`${api_url}admin/sounds/${id}`,data);
}

// Delete Sound
export async function deleteSound(id){
    return await https.delete(`${api_url}admin/sounds/${id}`);
}