import { https } from './https';
import { api_url } from './../Configuration/config';

//get All Courses
export async function getAllCourses(obj){
    return await https.get(`${api_url}admin/courses?${obj}`);
}

//get Course by id
export async function getCourse(id){
    return await https.get(`${api_url}admin/courses/${id}`);
}

//Accept Course
export async function acceptCourse(id){
    return await https.post(`${api_url}admin/courses/${id}/accept`);
}
//Accept Course
export async function rejectCourse(id){
    // return await https.post(`${api_url}admin/courses/${id}/reject`);
}
