import React, { useContext, useEffect, useState } from "react";
import { supportedLanguages } from "../../Utilities/SupportedLanguages";
import { t } from "i18next";
import { getClubProFeatures, updateClubProFeatures } from "../../Apis/clubs";
import PermissionsContext from "../../Contexts/permissionsContext";

export default function ProFeatures(id) {
  const { Permissions } = useContext(PermissionsContext);
  const [Locales, setLocales] = useState("");
  const [errors, setErrors] = useState("");
  const [loader, setLoader] = useState(false);
  let featureid = id.id

  useEffect(() => {
    proFeaturesData();
    // eslint-disable-next-line
  }, []);


  async function proFeaturesData() {
    try {
      const res = await getClubProFeatures(featureid);
      let data = res.data.data[0]?.locales
      setLocales(data);
    } catch (err) {
      console.log(err)
    }
  }

  const submitHandler = async () => {
    setLoader(true)
    let featureid = id.id
    console.log(Locales)
    try {
      const res = await updateClubProFeatures(featureid, { locales: Locales })
      console.log(res)
      setLoader(false)
    } catch (err) {
      setLoader(false)
      console.log(err);
      setErrors(err.message)
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setLocales((prevState) => ({
      ...prevState,
      [name]: { description: value }
    }));
    setErrors(" ")
    console.log(Locales)
  }

  return (
    <>
      <section className="componentGlobalWrapper   m-0 border-0" style={{ minHeight: "auto" }} >
        <h5 className="pageSubtitle">{t("pro-account-features")}</h5>
        <hr />
        {errors && <div className="text-danger">{errors}</div>}

        <div className="w-100 pt-5 " >
          <div className='d-flex flex-column'>
            {supportedLanguages?.map((language, index) => {
              return (
                <div key={index} className="d-flex flex-column mb-4 ">
                  <label htmlFor="content" className="formLabel">{`${t(
                    "content-in"
                  )} (${t(`${language.name}`)})`}</label>
                  <div {...(language.slug === "ar" ? { dir: "rtl" } : {})}>
                    <textarea
                      disabled={!Permissions?.club?.edit}
           name={`${language.slug}`}
                      className="textArea w-100 "
                      value={
                        Locales ? Locales[language?.slug]?.description : " "
                      }
                      onChange={(e) => handleChange(e, language.slug)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {Permissions?.club?.edit &&
            <>
              {!loader ? (
                <div>
                  <button className="confirmBtn" onClick={() => submitHandler()}>
                    {t("save-changes")}
                  </button>
                </div>
              ) : (
                <button className="confirmBtn" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {t("saving")}...
                </button>
              )}
            </>
          }
        </div>
      </section>
    </>
  );
}
