import { t } from 'i18next';
import React, { useState, useEffect, useContext } from 'react';
import '../tables.scss';
import { useLocation } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../Assets/Icons/edit.svg";
import delete_icon from "../../Assets/Icons/delete.svg";
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import DeleteModal from '../../Utilities/deleteModal';
import i18n from '../../i18n';
import moment from 'moment';
import '../forms.scss';
import PermissionsContext from '../../Contexts/permissionsContext';
import { deleteTrainerLevels, getAllTrainerLevels } from '../../Apis/trainerLevels';
import { dateLanguage } from '../../Utilities/dateLanguage';
import NotDataFound from '../../Components/NotDataFound/notDataFound';
import search from "../../Assets/Icons/search.svg";
import Pagination from '../../Components/Pagination/Pagination';


export default function TrainerLevels() {
    let { Permissions } = useContext(PermissionsContext);
    const [TrainerLevels, setTrainerLevels] = useState([]);
    const [deletedId, setDeletedId] = useState('');
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '' });
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '' });
    const [pageCount, setPageCount] = useState('')

    function getTrainerLevels(obj) {
        let filter = { ...obj }
        let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}`;
        getAllTrainerLevels(params).then(res => {
            console.log(res.data.data);
            setTrainerLevels(res.data.data);
            let total = res?.data?.pagination?.total;
            setPageCount(Math.ceil(total / filter?.per_page));
        })
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    }, [i18n.language])

    useEffect(() => {
        changeTitle(t(`trainer-levels`), Permissions['trainer-experience-level']?.create && { url: 'levels/trainer/create', text: t('add-level'), canAdd: Permissions['trainer-experience-level']?.create });
        getTrainerLevels(filterObj)
        // eslint-disable-next-line
    }, []);

    async function onSubmitDelete() {
        return await deleteTrainerLevels(deletedId).then(res => {
            getTrainerLevels(filterObj)
            return "done";
        }).catch(err => {
            console.log(err.response.data.message)
            return err.response.data.message;
        })
    }
    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj }
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        setfilterObj(thisObj);
        getTrainerLevels(thisObj)
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        getTrainerLevels(filter);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        setfilterObj2(thisObj2);
    }
    return (
        <>
            {<section className='componentGlobalWrapper d-flex flex-column'>
                <div>
                    <div className='searchActionsBar w-100'>
                        <div className='searchActionsBar mt-0 mb-0'>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                        </div>

                        {/* show select */}
                        <div className='perPageContainer'>
                            <label htmlFor='selectShow' className='perPageLabel'>
                                {t('show')}
                            </label>
                            <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                            </select>
                        </div>
                        {/*** apply search btn  ***/}
                        <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
                    </div>
                    {<div className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr>
                                    <th>{t('name')}</th>
                                    <th>{t('created-at')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TrainerLevels.length > 0 ? TrainerLevels.map((lvl, index) => {
                                    let lang = i18n.language;
                                    console.log(lvl.id)
                                    return (
                                        <tr key={index}>
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    {lvl?.locales && lvl?.locales[lang]?.name}
                                                </div>
                                            </td>
                                            <td>
                                                {moment(lvl.created_at).format('DD-MMMM-YYYY')}
                                            </td>
                                            <td>
                                                <div>
                                                    {Permissions['trainer-experience-level']?.edit && <Tooltip title={t('edit')}>
                                                        <Link to={`${basename}/dashboard/levels/trainer/${lvl.id}/update`}>
                                                            <img className='iconActions' src={edit_icon} alt="edit" />
                                                        </Link>
                                                    </Tooltip>}
                                                    {Permissions['trainer-experience-level']?.delete &&
                                                        <Tooltip title={t("delete")}>
                                                            <img onClick={() => setDeletedId(lvl.id)} data-bs-toggle="modal" data-bs-target='#deleteModal' className='iconActions' src={delete_icon} alt="delete" />
                                                        </Tooltip>}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                    :
                                    <tr >
                                        <td colSpan={6} >
                                            <NotDataFound />
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                        <DeleteModal onSubmitDelete={onSubmitDelete} name='level'></DeleteModal>
                    </div>}
                </div>
                <div className='d-flex justify-content-end mt-auto'>
                    <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
                </div>

            </section>}
        </>
    )
}
