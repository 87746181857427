import React from 'react'
import { updateClubPresident } from '../../../Apis/clubs';
import Form from './Form';

export default function UpdatePresident(props) {
  // onsubmit
  const onSubmit = async (e, data) => {
    e.preventDefault();
    //to send request
    return await updateClubPresident(props.clubId, { ...data, "_method": "patch" }).then(res => {
      props.getNewData()
      return 'done';
    }).catch(err => {
      return err.response.data.errors;
    })
  };

  return (
    <div className="modal-dialog modal-dialog-centered">
      {props.data && <Form onSubmit={onSubmit} id='update' clubId={props.clubId} data={props.data} />}
    </div>
  )
}
