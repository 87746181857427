import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import "./pages.scss";
import aboutus from "../../Assets/Icons/abouticon.svg"
import privacy from "../../Assets/Icons/privacy-policy-icon.svg"
import terms from "../../Assets/Icons/termsandconditions.svg"
import coursecondition from "../../Assets/Icons/coursecondition.svg"
import { basename } from '../../Configuration/config';
import PermissionsContext from '../../Contexts/permissionsContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { t } from 'i18next';

export default function StaticPages() {
    let { changeTitle } = useContext(TitleContext);
    const { Permissions } = useContext(PermissionsContext);

    useEffect(() => {
        changeTitle(`pages`);
        // eslint-disable-next-line
    }, []);

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <>
            <section className='componentGlobalWrapper websitePages '>
                {/* // {Permissions?.appearance?.view && }  */}
                <div className='mb-3'>
                    <h5 className='pageSubtitle'>{t('pages-settings')}</h5>
                    <hr/>
                </div>

                <div className='content pt-5'>

                    <NavLink className="navlink" to={`${basename}/dashboard/settings/appearance/about-us`}>
                        <Card sx={{ width: 345 }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography className='d-flex justify-content-evenly align-items-center' gutterBottom variant="h5" component="div">
                                        <img src={aboutus} className='pageArrow' alt="arrow" />
                                        {t('about-us')}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </NavLink>
                    <NavLink className="navlink" to={`${basename}/dashboard/settings/appearance/privacy-policy`}>
                        <Card sx={{ width: 345 }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography className='d-flex justify-content-evenly align-items-center' gutterBottom variant="h5" component="div">
                                        <img src={privacy} className='pageArrow' alt="arrow" />
                                        {t('privacy-and-policy')}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </NavLink>
                    <NavLink className="navlink" to={`${basename}/dashboard/settings/appearance/terms-and-conditions`}>
                        <Card sx={{ width: 345 }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography className='d-flex justify-content-evenly align-items-center' gutterBottom variant="h5" component="div">
                                        <img src={terms} className='pageArrow' alt="arrow" />
                                        <>
                                            {t('terms-and-conditions')}
                                        </>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </NavLink>
                    <NavLink className="navlink" to={`${basename}/dashboard/settings/appearance/courses-conditions`}>
                        <Card sx={{ width: 345 }} >
                            <CardActionArea>
                                <CardContent>
                                    <Typography className='d-flex justify-content-evenly align-items-center' gutterBottom variant="h5" component="div">
                                        <img src={coursecondition} className='pageArrow' alt="arrow" />
                                        <>
                                            {t('courses-conditions')}
                                        </>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </NavLink>
                    {/* <NavLink className="navlink" to={`${basename}/dashboard/settings/appearance/faq`}>
                        <Card sx={{ width: 345 }} >
                            <CardActionArea>
                                <CardContent>
                                    <Typography className='d-flex justify-content-evenly align-items-center' gutterBottom variant="h5" component="div">
                                        <img src={faq} className='pageArrow' alt="arrow" />
                                        <>
                                            {t('faqs')}
                                        </>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </NavLink> */}

                </div>
            </section >
        </>
    )
}
