import moment from "moment";

export function dateLanguage(locale){
    if(locale === 'ar'){
        moment.updateLocale('ar', {
            months: [ 'يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر' ]
        });
    }else{
        moment.updateLocale('en', {
            months: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
        });
    }
}