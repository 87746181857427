import React, { useContext, useEffect, useRef, useState } from "react";
import { getSetting, updateSetting } from "../../../Apis/settings";
import TitleContext from "../../../Contexts/TitleContext";
import { supportedLanguages } from "../../../Utilities/SupportedLanguages";
import { t } from "i18next";
import PermissionsContext from "../../../Contexts/permissionsContext";
import { useNavigate } from "react-router";
import cond from '../../../Assets/Icons/cond.svg'
import { useTranslation } from "react-i18next";

export default function CoursesConditions() {
  const { Permissions } = useContext(PermissionsContext);
  const { changeTitle } = useContext(TitleContext);
  const [User, setUser] = useState("");
  const [Trainer, setTrainer] = useState("");
  const [errors, setErrors] = useState("");
  const [userloader, setUserloader] = useState(false);
  const [trainerloader, setTrianerloader] = useState(false);
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  useEffect(() => {
    changeTitle(`courses-conditions`);
    Data();
    // eslint-disable-next-line
  }, []);

  async function Data() {
    try {
      const user = await getSetting("user-course-conditions");
      const trainer = await getSetting("trainer-course-conditions");
      let userData = user.data.data?.locales;
      let trainerData = trainer.data.data?.locales;
      setUser(userData);
      setTrainer(trainerData);
    } catch (err) {
      console.log(err)
    }
  }

  const submitUserHandler = async (e) => {
    setUserloader(true)
    let locales = { ...User };
    console.log("SUBMITTED", locales);
    if (locales !== "" && locales?.ar?.description !== "" && locales.en?.description !== "" && locales?.ar !== [] && locales.en !== []) {
      try {
        await updateSetting("user-course-conditions", locales)
        setTrianerloader(false)
        navigate('/dashboard/settings/appearance')
        // console.log(res)
      } catch (err) {
        setTrianerloader(false)
        navigate('/dashboard/settings/appearance')
        console.log(err);
        setErrors(err)
      }
    } else {
      setErrors("Please Fill All Feilds!")
      setTrianerloader(false)
    }

  }
  const submitTrainerHandler = async (e) => {
    setTrianerloader(true)
    let locales = { ...Trainer };
    console.log("SUBMITTED", locales);
    if (locales !== "" && locales?.ar?.description !== "" && locales.en?.description !== "" && locales?.ar !== [] && locales.en !== []) {
      try {
        await updateSetting("trainer-course-conditions", locales)
        setTrianerloader(false)
        navigate('/dashboard/settings/appearance')
        // console.log(res)
      } catch (err) {
        setTrianerloader(false)
        navigate('/dashboard/settings/appearance')
        console.log(err);
        setErrors(err)
      }
    } else {
      setErrors("Please Fill All Feilds!")
      setTrianerloader(false)
    }
  }

  function handleTrainerChange(e, lang) {
    const { name, value } = e.target;
    setTrainer((prevState) => ({
      ...prevState,
      [name]: {
        description: value
      }
    }));
    console.log(Trainer)
  }
  function handleUserChange(e, lang) {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: {
        description: value
      }
    }));
    console.log(User)
  }

  return (
    <>
      <section className="componentGlobalWrapper" >
        <div className="mb-3">
          <h5 className="pageSubtitle">{t("page-content")}</h5>
          <hr />
          {errors && <div className="text-danger">{errors}</div>}

          <div className="w-100 pt-5" >
            <h6 className="pageSubtitle">{t("trainer")}</h6>
            <div className='d-flex flex-column'>
              {supportedLanguages?.map((language, index) => {
                return (
                  <div key={index} className="d-flex flex-column mb-4 ">
                    <label htmlFor="content" className="formLabel">{`${t(
                      "content-in"
                    )} (${t(`${language.name}`)})`}</label>
                    <div {...(language.slug === "ar" ? { dir: "rtl" } : {})}>

                      <textarea
                      disabled={!Permissions?.setting?.edit}
                        name={`${language.slug}`}
                        className="textArea w-50 "
                        value={
                          Trainer ? Trainer[language.slug]?.description : " "
                        }
                        onChange={(e) => handleTrainerChange(e, language.slug)}
                      />
                    </div>
                  </div>
                );
              })}

              <>
                { Permissions?.setting?.edit && !trainerloader ? (
                  <button className="confirmBtn w-25" onClick={() => submitTrainerHandler()}>
                    {t("save-changes")}
                  </button>
                ) : ( Permissions?.setting?.edit &&
                  <button className="confirmBtn w-25" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {t("saving")}...
                  </button>
                )}
              </>

            </div>

            <div className="my-5">

              <h6 className="pageSubtitle">{t("user")}</h6>
              <div className='d-flex flex-column'>
                {supportedLanguages?.map((language, index) => {

                  return (
                    <div key={index} className="d-flex flex-column mb-4 ">
                      <label htmlFor="content" className="formLabel">{`${t(
                        "content-in"
                      )} (${t(`${language.name}`)})`}</label>
                      <div {...(language.slug === "ar" ? { dir: "rtl" } : {})}>

                        <textarea
                        disabled={!Permissions?.setting?.edit}
                          name={`${language.slug}`}
                          className="textArea w-50 "
                          value={
                            User ? User[language.slug]?.description : " "
                          }
                          onChange={(e) => handleUserChange(e, language.slug)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {
                <>
                  {Permissions?.setting?.edit && !userloader ? (
                    <button className="confirmBtn w-25" onClick={() => submitUserHandler()}>
                      {t("save-changes")}
                    </button>
                  ) : ( Permissions?.setting?.edit &&
                    <button className="confirmBtn w-25" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {t("saving")}...
                    </button>
                  )}
                </>
                // )
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
