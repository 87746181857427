import React, { useContext, useEffect } from 'react'
import Form from './Form';
import TitleContext from '../../../Contexts/TitleContext';
import { t } from 'i18next';
import { updateClub } from '../../../Apis/clubs';
import { useParams } from 'react-router';
import i18n from '../../../i18n';

export default function EditClubs() {
    const { changeTitle } = useContext(TitleContext);
    const { id } = useParams();

    useEffect(() => {
        changeTitle(t('update-club'));
        // eslint-disable-next-line
    }, [i18n.language]);

    const onSubmit = async (e, data) => {
        e.preventDefault();
        let x = { ...data, _method: 'patch' }
        return await updateClub(id, x).then(res => {
            return 'done';
        }).catch(err => {
            console.log(err.response)
            if (err.response.status === 403) {
                return { error: err.response.data.message, type: 'permissions' };
            } else if (err.response.status === 422) {
                return { error: err.response.data.errors, type: 'validations' };
            }
        })
    };

    return (
        <div>
            <Form onSubmit={onSubmit} />
        </div>
    )
}
