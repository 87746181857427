import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllAcademies(obj=''){
    return await https.get(`${api_url}admin/academies?${obj}`);
}

//get academies by id
export async function getAcademy(id){
    return await https.get(`${api_url}admin/academies/${id}`);
}
//get academiesOverview by id
export async function getAcademyOverview(id){
    return await https.get(`${api_url}admin/academies/${id}/overview`);
}
//get academiesPresedent by id
export async function getAcademyPresedent(id){
    return await https.get(`${api_url}admin/academies/${id}/presedent`);
}

// delete academies
export async function deleteAcademy(id){
    return await https.delete(`${api_url}admin/academies/${id}`);
}

// accept academies
export async function acceptAcademy(id){
    return await https.post(`${api_url}admin/academies/${id}/accept`);
}

// reject academies
export async function rejectAcademy(id,data){
    return await https.post(`${api_url}admin/academies/${id}/reject`,data);
}
// block academies
export async function blockAcademy(id){
    return await https.post(`${api_url}admin/academies/${id}/block`);
}
