import { https } from './https';
import { api_url } from './../Configuration/config';

//get loggedin admin profile info
export async function getAdminProfileInfo(){
    return await https.get(`${api_url}admin/auth/profile`);
}

//edit loggedin admin profile info
export async function editAdminProfileInfo(data){
    return await https.patch(`${api_url}admin/auth/update` , data);
}

//edit loggedin admin profile info
export async function getOverview(){
    return await https.get(`${api_url}admin/overview`);
}

