import React, { useEffect, useRef, useState } from 'react'
import { createClubPlans, updateClubPlan } from '../../Apis/clubs';
import { t } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import plan from "../../Assets/Icons/plan.svg"
import plan1 from "../../Assets/Icons/plan1.svg"
import plan2 from "../../Assets/Icons/plan2.svg"
import SingleClubPlan from './SingleClubPlan';
// import { useNavigate } from 'react-router';

export default function ClubPlan({ id, clubId, allPlans, Refresh }) {

  const [monthly, setMonthly] = useState()
  const [biannual, setBiannual] = useState()
  const [annual, setAnnual] = useState()
  const [plans, setPlans] = useState({ club_id: clubId, plans: [monthly, biannual, annual] })
  const [errors, setErrors] = useState()
  const closeCreateModalRef = useRef()
  // const navigate = useNavigate()

  useEffect(() => {
    const matchingItem = allPlans?.find(item => (item?.type?.id) === id );
    console.log(matchingItem)
    if (matchingItem) {
      if (matchingItem.type.id === 1) {
        setMonthly({ price: matchingItem?.price, locales: { ar: { description: matchingItem?.description }, en: { description: matchingItem?.description } } })
      } else if (matchingItem.type.id === 2) {
        setBiannual({ price: matchingItem?.price, locales: { ar: { description: matchingItem?.description }, en: { description: matchingItem?.description } } })
      } else if (matchingItem.type.id === 3) {
        setAnnual({ price: matchingItem?.price, locales: { ar: { description: matchingItem?.description }, en: { description: matchingItem?.description } } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
        setMonthly(allPlans?.find(item => item.type?.id === 1))
        setBiannual(allPlans?.find(item => item.type?.id === 2))
        setAnnual(allPlans?.find(item => item.type?.id === 3))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPlans])

  useEffect(() => {
    console.log("monthly", monthly)
    console.log("bi", biannual)
    console.log("annual", annual)
  }, [monthly, biannual, annual])

  const changeHandler = (e, type) => {
    const { value, name } = e.target
    if (type === "monthly") {
      if (name === "price")
        setMonthly({
          ...monthly,
          id: 1,
          price: value
        })
      else {
        setMonthly({
          ...monthly,
          locales: {
            ...monthly.locales,
            [name]: { description: value }
          }
        })
      }
    } else if (type === "biannual") {
      if (name === "price")
        setBiannual({
          ...biannual,
          id: 2,
          price: value
        })
      else {
        setBiannual({
          ...biannual,
          locales: {
            ...biannual.locales,
            [name]: { description: value }
          }
        })
      }
      console.log(biannual)
    } else if (type === "annual") {
      if (name === "price")
        setAnnual({
          ...annual,
          id: 3,
          price: value
        })
      else {
        setAnnual({
          ...annual,
          locales: {
            ...annual.locales,
            [name]: { description: value }
          }
        })
      }
      console.log(annual)
    }
    setErrors("")
  }


  const onSubmit = async (e) => {
    e.preventDefault();
    if (id === 1 || id === 2 || id === 3) {
      const data = {
        club_id: clubId
        , plans: id === 1 ? { 1: monthly }
          : id === 2 ? { 2: biannual }
            : { 3: annual }
      }
      try {
        await updateClubPlan(data)
        resetForm()
        Refresh()
      } catch (err) {
        console.log(err)
        setErrors(err.response.data)
      }
    }
    else {
      try {
        await updateClubPlan({
          club_id: clubId,
          plans: {1: monthly, 2: biannual, 3: annual}
        })
        resetForm()
        Refresh()
      } catch (err) {
        console.log(err.response.data.errors)
        setErrors(err.response.data.errors)
      }
    }
  }
  const resetForm = () => {
    if (id === 1 || id === 2 || id === 3) {
      closeCreateModalRef.current.click()
    } else {
      closeCreateModalRef.current.click()
      setMonthly()
      setBiannual()
      setAnnual()
      setPlans("")
    }
  }
  return (

    <div className="modal-dialog modal-dialog-centered  " >
      <div className={`modal-content ${id && "responsive"}`} >
        <div className="modal-header m-0 w-100">
          <h5 className='pageSubtitle mb-0 mx-3'>{(id === 1 || id === 2 || id === 3) ? t('edit-plan') : t('add-plans')}</h5>
          <div ref={closeCreateModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetForm()} >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        {errors && <p className="bg-danger p-3 fs-4 text-light text-center w-100">{"Please fill the all fields, All plans are requierd"}</p>}
        <div className="modal-body">
          <form >
            <div className=' m-2 d-flex flex-wrap '>

              {
                id === 1
                  ? <SingleClubPlan monthly={monthly} biannual={biannual} annual={annual} name="monthly" plan={plan} changeHandler={changeHandler} />
                  : id === 2
                    ? <SingleClubPlan monthly={monthly} biannual={biannual} annual={annual} name="biannual" plan={plan1} changeHandler={changeHandler} />
                    : id === 3
                      ? <SingleClubPlan monthly={monthly} biannual={biannual} annual={annual} name="annual" plan={plan2} changeHandler={changeHandler} />
                      : <>
                        <SingleClubPlan monthly={monthly} biannual={biannual} annual={annual} name="monthly" plan={plan} changeHandler={changeHandler} />
                        <SingleClubPlan monthly={monthly} biannual={biannual} annual={annual} name="biannual" plan={plan1} changeHandler={changeHandler} />
                        <SingleClubPlan monthly={monthly} biannual={biannual} annual={annual} name="annual" plan={plan2} changeHandler={changeHandler} />
                      </>
              }
            </div>

          </form>
        </div>
        <div className="modal-footer justify-content-center">
          <button type="button" className={`btn ${id === 2 ? "btn-primary" : id === 3 ? "btn-warning" : "btn-dark"}`} onClick={(e) => onSubmit(e)}>Save changes</button>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => resetForm()}>Close</button>
        </div>
      </div>

    </div>
  )
}
