import { t } from "i18next";
import React, { useState, useEffect } from "react";
import "../../Pages/tables.scss";
import { useLocation } from "react-router";
import search from "../../Assets/Icons/search.svg";
import noImg from "../../Assets/Images/noImg.png";
import i18n from "../../i18n";
import "../../Pages/forms.scss";
import { getVideos } from "../../Apis/clubs";
import Pagination from "../Pagination/Pagination";
import { dateLanguage } from "../../Utilities/dateLanguage";
import NotDataFound from "../NotDataFound/notDataFound";

export default function Videos({ type }) {
  const { pathname } = useLocation();
  const [videos, setVideos] = useState();
  const [filterObj, setfilterObj] = useState({
    per_page: 10,
    page: 1,
    search: "",
  });

  const [filterObj2, setfilterObj2] = useState({
    per_page: 10,
    search: "",
    city: "",
  });

  const [pageCount, setPageCount] = useState("");

  function getClubVideos(obj) {
    let filter = { ...obj };
    let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}`;
    console.log(type)
    getVideos(type.user_id, params).then((res) => {
      console.log(res.data.data);
      setVideos(res.data.data);
      let total = res?.data?.pagination?.total;
      setPageCount(Math.ceil(total / filter.per_page));
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    dateLanguage(i18n.language);
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    getClubVideos();
    // eslint-disable-next-line
  }, []);

  function changeFilterObj(e) {
    let thisObj = { ...filterObj2 };
    thisObj[e.target.name] = e.target.value.trim();
    setfilterObj2(thisObj);
  }

  function getFiltered() {
    let thisObj = { ...filterObj };
    thisObj.page = 1;
    thisObj.per_page = filterObj2.per_page;
    thisObj.search = filterObj2.search;

    setfilterObj(thisObj);
    getClubVideos(thisObj);
  }

  const handlepageChange = async (data) => {
    let filter = { ...filterObj };
    filter.page = data.selected + 1;
    setfilterObj(filter);
    getClubVideos(filter);

    let thisObj2 = { ...filterObj2 };
    thisObj2.per_page = filter.per_page;
    thisObj2.search = filter.search;
    setfilterObj2(thisObj2);
  };


  return (
    <>
      {
        <section className="componentGlobalWrapper pt-1 d-flex flex-column m-4" style={{ minHeight: "auto" }}>
          <div>
            <div
              className="tabs-container nav nav-tabs d-flex align-items-center"
              id="myTab"
              role="tablist"
            >

            </div>

            <div className="d-flex just5ify-content-between align-items-center">
              <div className="searchActionsBar w-100">
                <div className="searchActionsBar mt-0 mb-0">
                  {/*** search input ***/}
                  <div className="searchInputWrapper">
                    <div>
                      <input
                        type="text"
                        className="barSearchInput"
                        name="search"
                        onChange={changeFilterObj}
                      />
                    </div>
                    <img
                      src={search}
                      alt="search-icon"
                      className="barSearchIcon"
                    />
                  </div>
                </div>
                {/* show select */}
                <div className="perPageContainer">
                  <label htmlFor="selectShow" className="perPageLabel">
                    {t("show")}
                  </label>
                  <select
                    name="per_page"
                    onChange={changeFilterObj}
                    id={`selectShow`}
                    className="form-select selectShow"
                    value={filterObj2?.per_page}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>


                {/*** apply search btn  ***/}
                <button className="applySearchBtn" onClick={getFiltered}>
                  {t("search")}
                </button>
              </div>
            </div>

            <div className="tableWrapper">
              <table className="w-100 table table-striped border-0">
                <thead>
                  <tr>
                    <th>{t("cover")}</th>
                    <th>{t("views")}</th>
                    <th>{t("likes")}</th>
                    <th>{t("comments")}</th>
                    <th>{t("taged-users")}</th>
                    {/* <th>{t("actions")}</th> */}
                  </tr>
                </thead>
                <tbody>
                  {console.log(videos)}
                  {videos?.videos?.length > 0 ? (
                    videos?.videos?.map((item, index) => {
                      console.log(item);
                      return (
                        <tr key={index}>
                          <td className="sliderImg">
                            <img
                              className="w-75"
                              src={item?.cover ? item?.cover : noImg}
                              alt="slider"
                            />
                          </td>
                          <td className="desc">
                            <div className="descriptionTd">
                              {item?.views_count}
                            </div>
                          </td>
                          <td className="desc">
                            <div className="descriptionTd">
                              {item?.likes_count}
                            </div>
                          </td>
                          <td>
                            <div className="descriptionTd">
                              {item?.comments_count}
                            </div>
                          </td>
                          <td>
                            {item?.tagged_users.length > 0
                              ? item?.tagged_users?.map((user) => (
                                <>
                                  <li key={user.username}> {user.username} </li>
                                </>
                              ))
                              : t("no-taged-users")}
                          </td>

                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <NotDataFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-auto">
            <Pagination
              pagesNumber={pageCount}
              page={filterObj.page - 1}
              pageChangeHandler={handlepageChange}
            />
          </div>
        </section>
      }
    </>
  );
}
