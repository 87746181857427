import ReactApexChart from "react-apexcharts";
const RadialChart = (props) => {

  const data = { labels: [...props.labels] }
  const config = { type: "polarArea", data: data, };
  const series = [...props.series];
  const options = {
    chart: {
      width: 500,
      type: 'polarArea'
    },
    labels: [...props.labels],
    fill: {
      opacity: 1
    },
    stroke: {
      width: 1,
      colors: undefined
    },
    yaxis: {
      show: false
    },
    legend: {
      position: 'bottom'
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0,
        },
        spokes: {
          strokeWidth: 0
        },
      }
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: 'light',
        shadeIntensity: 0.6
      }
    }
  }


  return (

    <div className="area ">
      {typeof window !== "undefined" && (
        <ReactApexChart
          style={{ display: "flex", justifyContent: "center", "--fa-animation-duration": "3s", "--fa-beat-scale": "1.01" }}
          className='fa-beat'
          options={options}
          series={series}
          config={config}
          type="polarArea"
          width="500"
          height="400"
        />
      )}

    </div>



  );
};
export default RadialChart;

