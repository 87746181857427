
import './App.css';
import React from 'react';
import { Route , Routes } from 'react-router';
import ProtectedRoutes from './protectedRoutes';
import DashboardLayout from './Layouts/dashboardLayout/dashboardLayout';
import AuthLayout from './Layouts/authLayout/authLayout';
import { basename } from './Configuration/config';
import { TitleContextProvider } from './Contexts/TitleContext';
import { useTranslation } from 'react-i18next';
import { PermissionsContextProvider } from './Contexts/permissionsContext';

function App() {
  const { i18n } = useTranslation();
  console.log(i18n.language);
  return (
    <React.Fragment>
      <PermissionsContextProvider>
        <TitleContextProvider>
          <div className={`${i18n.language === 'en' ? 'englishWrapper' : 'arabicWrapper'}`}>
            <Routes>
              {/* ----- Admin Login ------*/}
              <Route path={`${basename}/*`} element={<AuthLayout />} />  
              {/* protected routes */}
                <Route element={<ProtectedRoutes/>}>
                  <Route path={`${basename}/dashboard/*`} element={<DashboardLayout />} />
                </Route>
            </Routes>
          </div>
        </TitleContextProvider>
      </PermissionsContextProvider>
    </React.Fragment>
  );
}

export default App;
