import { t } from 'i18next';
import React, { useState, useEffect, useContext /* , useRef */ } from 'react';
import '../tables.scss';
import { useLocation } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../Assets/Icons/edit.svg";
import delete_icon from "../../Assets/Icons/delete.svg";
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import DeleteModal from '../../Utilities/deleteModal';
import i18n from '../../i18n';
import moment from 'moment';
import '../forms.scss';
import PermissionsContext from '../../Contexts/permissionsContext';
import { deleteCountries, getAllCountries } from '../../Apis/countries';
import { dateLanguage } from '../../Utilities/dateLanguage';
import NotDataFound from '../../Components/NotDataFound/notDataFound';

export default function Countries() {
    const { Permissions } = useContext(PermissionsContext);
    const [Countries, setCountries] = useState([]);
    const [deletedId, setDeletedId] = useState('');
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);

    function getCountries() {
        getAllCountries().then(res => {
            setCountries(res.data.data);
            console.log(res.data.data);
        }).catch(err => {
            console.log(err.response);
        })
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    }, [i18n.language])

    useEffect(() => {
        changeTitle(t(`countries`), Permissions?.country?.create && { url: 'settings/countries/create', text: t('add-country'), canAdd: Permissions?.country?.create });

        getCountries()
        // eslint-disable-next-line
    }, []);

    async function onSubmitDelete() {
        return await deleteCountries(deletedId).then(res => {
            getCountries();
            return "done";
        }).catch(err => {
            console.log(err.response.data.message)
            return err.response.data.message;
        })
    }

    return (
        <>
            {<section className='componentGlobalWrapper d-flex flex-column'>
                <div>
                    {<div className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr>
                                    <th>{t('name')}</th>
                                    <th>{t('created-at')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Countries.length > 0 ? Countries.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='desc'>
                                                {item?.name}
                                            </td>
                                            <td>
                                                {moment(item.created_at).format('DD-MMMM-YYYY')}
                                            </td>
                                            <td>
                                                <div>
                                                    {Permissions?.country?.edit &&
                                                        <Tooltip title='edit'>
                                                            <Link to={`${basename}/dashboard/settings/countries/${item.id}/update`}>
                                                                <img className='iconActions' src={edit_icon} alt="edit" />
                                                            </Link>
                                                        </Tooltip>}
                                                    {Permissions?.country?.delete &&
                                                        <Tooltip title="delete this price">
                                                            <img data-bs-toggle="modal" onClick={() => setDeletedId(item.id)} data-bs-target='#deleteModal' className='iconActions' src={delete_icon} alt="delete" />
                                                        </Tooltip>}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                    :
                                    <tr >
                                        <td colSpan={6} >
                                            <NotDataFound />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <DeleteModal onSubmitDelete={onSubmitDelete} name='item'></DeleteModal>
                    </div>}
                </div>
            </section>}
        </>
    )
}
