import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getDeleteRequests(obj) {
    return await https.get(`${api_url}admin/delete-account-requests?${obj}`);
}
export async function acceptDeleteRequest(id) {
    return await https.post(`${api_url}admin/users/${id}`);
}
export async function toggleRead(id, type) {
    return await https.post(`${api_url}admin/delete-account-requests/${id}/${type}`);
}
