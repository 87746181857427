import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../Assets/Icons/edit.svg";
import delete_icon from "../../Assets/Icons/delete.svg";
import { deleteAdmin, getAdmins } from '../../Apis/admins';
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import DeleteModal from '../../Utilities/deleteModal';
import '../tables.scss'
import PermissionsContext from '../../Contexts/permissionsContext';
import { useTranslation } from 'react-i18next';
import { dateLanguage } from '../../Utilities/dateLanguage';
import i18n from '../../i18n';
import NotDataFound from '../../Components/NotDataFound/notDataFound';

export default function AdminsList() {
    const { t } = useTranslation()
    const { Permissions } = useContext(PermissionsContext);
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [allAdmins, setAllAdmins] = useState([]);
    const [adminId, setAdminId] = useState('');
    const [deletedName, setDeletedName] = useState('section');
    console.log("PERMISSIONS ::::::::::::::::::", Permissions)

    useEffect(() => {
        window.scrollTo(0, 0);

        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    }, [i18n.language])


    // get all admins
    useEffect(() => {
        changeTitle(t(`admins`), { url: 'admins/create', text: t('add-admin'), canAdd: Permissions?.admin?.create });
        getAllAdmins();
        // eslint-disable-next-line
    }, []);

    function getAllAdmins() {
        let myallAdmins = [...allAdmins];
        getAdmins().then(res => {
            myallAdmins = res.data.data;
            console.log(myallAdmins);
            setAllAdmins(myallAdmins);
        }).catch(err =>
            console.log(err)
        )
    }

    // delete admin
    const onSubmitDelete = async () => {
        return await deleteAdmin(adminId).then(res => {
            getAllAdmins();
            return 'done';
        }).catch(err => {
            return err.response.data.message;;
        })
    }

    function clickDelete(item) {
        setAdminId(item.id)
        setDeletedName(item?.username)
    }
    return (
        <>
            {
                <section className='componentGlobalWrapper'>
                    <div style={{ "borderRadius": "8px", "border": "1px solid #DFE0EB" }} className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr>
                                    <th>{t('username')}</th>
                                    <th>{t('name')}</th>
                                    <th>{t('email')}</th>
                                    <th>{t('role')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allAdmins.length > 0 ? allAdmins.map((admin, index) => (
                                    <tr key={index}>
                                        <td>{admin.username}</td>
                                        <td style={{ "width": "22%" }}>{admin.name}</td>
                                        <td style={{ "width": "18%" }}>{admin.email}</td>
                                        <td style={{ "width": "14%" }}>
                                            <div className={`adminRoleLabel p-2`}>
                                                {t(admin?.role) ? t(admin.role.name) : t('no-role')}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {Permissions?.admin?.edit &&
                                                    <Tooltip title={t('edit')}>
                                                        <Link to={`${basename}/dashboard/admins/${admin.id}/update`}>
                                                            <img src={edit_icon} alt="edit" />
                                                        </Link>
                                                    </Tooltip>
                                                }
                                                {Permissions?.admin?.delete &&
                                                    <Tooltip title={t('delete')}>
                                                        <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => clickDelete(admin)} />
                                                    </Tooltip>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )) :
                                    <tr >
                                        <td colSpan={5} >
                                            <NotDataFound />
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                        <DeleteModal onSubmitDelete={onSubmitDelete} name={deletedName}></DeleteModal>
                    </div>
                </section>
            }
        </>
    )
}
