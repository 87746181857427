let obj = {
    locales: {
        en: {
            name: ""
        },
        ar: {
            name: ""
        }
    },
}
export default obj;