import React from 'react'
import './card.scss'
import { t } from 'i18next'
import SubscribersModal from './SubscribersModal';
import NotDataFound from '../NotDataFound/notDataFound';
import { Chip } from '@mui/material';
import expired from '../../Assets/Icons/expired.svg'
import cleander from '../../Assets/Icons/cleander.svg'
import { Link } from 'react-router-dom';
import { basename } from '../../Configuration/config';


function CourseCard({ course }) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    return (
        course ?
            <article className="postcard light blue myModal">
                <div className="postcard__img_link bg-light position-relative">
                    <img className="postcard__img" src={course?.cover} alt="" />
                </div>
                <div className="postcard__text t-dark w-100">
                    <h1 className="postcard__title blue d-flex justify-content-between align-items-center">
                        <Link to={`${basename}/dashboard/courses/${course.id}/details`}><h1 className='text-dark ' >{course.title}</h1></Link>
                        {course?.status && <Chip className={`${course?.status?.slug === "pending" ? "bg-warning" : course?.status?.slug === "accepted" ? "bg-primary" : course?.status?.slug === "live" ? "bg-success" : "bg-danger"}`}
                            icon={<img alt='' width={"30px"} className='m-2' src={expired} />} label={course?.status.name} />}
                    </h1>
                    <div className="postcard__subtitle small d-flex justify-content-between align-items-center w-100">
                        <b>{course.individual_price}</b>
                        <b className="bg-gray text-dark subscriber p-2 border rounded" style={{ cursor: "pointer" }}>
                            <div onClick={handleClickOpen}><i className="mr-2"></i><b>{t("subscribers")} :  </b> {course?.subscribers_count}</div>
                        </b>
                    </div>
                    <div className="postcard__bar"></div>
                    <ul className="postcard__tagbox mt-1 text-light justify-content-evenly text-center">
                        <li className="tag__item flex-grow-1 p-1"><i className="mr-2"></i> <b>{t("available-seats")} :  </b>{course?.available_seats}</li>
                        <li className="tag__item flex-grow-1 p-1"><i className=" mr-2"></i><b>{t("session-count")} : </b> {course?.sessions_count}</li>
                        <li className="tag__item flex-grow-1 p-1"><i className="mr-2"></i> <b>{t("acadimies-offer")} :  </b> {course?.group_discount}</li>
                        <li className="tag__item flex-grow-1 p-1"><i className="mr-2"></i> <b>{t("views")} : </b> {course?.views_count}</li>

                    </ul>
                    <div className="postcard__preview-txt">{course.description}</div>
                    {course?.sessions &&
                        <>
                            <h6 className='mb-0 mt-1 ms-1'>{t("sessions")}:</h6>
                            <div className='d-flex justify-content-evenly flex-wrap'>
                                {course?.sessions &&
                                    course?.sessions?.map((ses) => (
                                        <div key={ses?.id} className='border rounded m-1 p-2 text-center '>
                                            <Chip className='border-0' icon={<img alt='' width={"35px"} className='' src={cleander} />} variant="outlined" label="" />
                                            <p className='d-flex flex-column justify-content-center align-items-start m-0'>
                                                <span className="tag__item"><b className="mr-2">{t("date")} : </b>{` ${ses.date}`}</span>
                                                <span className="tag__item"><b className="mr-2">{t("time")} : </b>{` (${ses.time})`}</span>
                                            </p>
                                        </div>
                                    ))

                                }
                            </div>
                        </>
                    }
                </div>
                <SubscribersModal open={open} data={course.subscribers} handleClose={handleClose} />
            </article>
            :
            <div className='w-100'>
                <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
            </div>

    )
}

export default CourseCard