import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllTrainerLevels(obj){
    return await https.get(`${api_url}admin/trainer-experience-levels?${obj}`);
}

//get TrainerLevels by id
export async function getTrainerLevels(id){
    return await https.get(`${api_url}admin/trainer-experience-levels/${id}`);
}

// create TrainerLevels
export async function createTrainerLevels(data){
    return await https.post(`${api_url}admin/trainer-experience-levels`,data);
}

// update TrainerLevels
export async function updateTrainerLevels(id,data){
    return await https.patch(`${api_url}admin/trainer-experience-levels/${id}`,data);
}

// delete TrainerLevels
export async function deleteTrainerLevels(id){
    return await https.delete(`${api_url}admin/trainer-experience-levels/${id}`);
}
