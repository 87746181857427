import React ,{ useState , useEffect , useContext} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import { supportedLanguages } from '../../../Utilities/SupportedLanguages';
import { t } from 'i18next';
import obj from './Model';
import { setData } from './FormHandler';
import PermissionsContext from '../../../Contexts/permissionsContext';
import '../../forms.scss';
import { getAcademyLevels } from '../../../Apis/academyLevels';
import { preventEnter } from '../../../Utilities/preventEnter';
import { basename } from '../../../Configuration/config';

export default function Form(props) {
    let {Permissions} = useContext(PermissionsContext);
    const {id} = useParams();
    const { pathname } = useLocation();
    let navigate = useNavigate()
    const [model, setModel] = useState(obj);
    const [loading, setLoading] = useState(false); 
    const [errors, setErrors]= useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    useEffect(() => {
        if(id){
            getAcademyLevels(id).then((res)=>{
                setModel(res.data.data);
                console.log(res.data.data);
            });
        }else{
            setModel({
                locales: {},
            })
        }
        // eslint-disable-next-line
    }, [id]);

    /**************************** form onChange function *********************************/ 
    const setFormData = async(e ,property ,locale=false ,language=null) => {
        setData(model, setModel, e, property , locale , language );
        let err = {...errors};
        err[property] = '';
        setErrors(err)
    }

    async function confirm(e){
        e.preventDefault();
        setLoading(true);
        let submitProps = await props.onSubmit(e, model);
        if(submitProps === "done"){
            setLoading(false);
            navigate(`${basename}/dashboard/levels/academy`);
            if(!id){
                setModel({});
            }                
        }else{
            console.log(submitProps);
            setLoading(false);
            setErrors(submitProps);
        }
    }


    return (
        <section className='componentGlobalWrapper'>
            <form id='adminForm' onSubmit={(e) => confirm(e, model)} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-start mb-5'>
                    <div  className="w-100">
                    <div className='d-flex flex-column'>
                        {supportedLanguages.map((language)=>{
                            return(
                                <div key={language.slug} className='mb-4' style={{"width" : "50%"}}>
                                <div className='d-flex flex-column'>
                                    <label htmlFor={`name_${language.slug}`} className='formLabel'>{t('name')} ({t(`${language.name}`)})</label>
                                    <input name="name" id={`name_${language.slug}`} type='text' placeholder={t('name')} value={model?.locales ? model?.locales[language.slug]?.name : " " } onChange={(e) => setFormData(e, 'name' , true , language.slug)} minLength={3} maxLength={20} required />
                                </div>
                                <div className='text-danger validateToDelete'>   
                                    {errors[`locales.${language.slug}.name`]}
                                </div>
                            </div>)})}
                                </div>
                    </div>
                </div>

                {!loading ? 
                    <button className='confirmBtn' type='submit'>{id ? t(`save-changes`) : t(`create`)}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        {t('saving')}...
                    </button>
                }
            </form>
        </section>
    )
}
