import React, { useRef ,useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';

export default function RejectModal({submitReject ,reason ,setReason ,errors , setErrors}) {
    const [loading ,setLoading]= useState(false);
    const rejectModalRef = useRef();

    /* reject */
    async function submit(e){
        e.preventDefault();
        setLoading(true)
        let submitProps = await submitReject(reason);
        if(submitProps === 'done'){
            rejectModalRef.current.click();
            setLoading(false);
            setReason('');
        }else{
            setLoading(false);
            setErrors(submitProps)
        }
    }

    function reset(){
        setReason('')
        console.log('reset')
    }
    
    return (
            <div className="modal fade" id="rejectModal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <h5 className='pageSubtitle mb-0'>{t('reject')} {t(`${name}`)}</h5> */}
                            <div ref={rejectModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={reset} >
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>
                        <div className="modal-body">
                            <form id='adminForm' onSubmit={submit} >
                                <div className='d-flex justify-content-start'>
                                    <div className="w-100">
                                        <div className='mb-4' >
                                            <div className='d-flex flex-column'>
                                                <label htmlFor={`desc`} className='formLabel'>{t('message')} </label>
                                                <textarea onChange={(e)=>setReason(e.target.value)} value={reason} name="message" id={`desc`} type='text' placeholder={t(`rejection's reason...`)} minLength={3} required />
                                            </div>
                                            <div className='text-danger validateToDelete'>
                                                {errors?.message}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!loading ?
                                    <button className='confirmBtn' type='submit'>{t('submit')}</button>
                                    :
                                    <button className='confirmBtn' disabled>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        {t('saving')}...
                                    </button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
}


