import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllCities(obj=''){
    return await https.get(`${api_url}admin/cities?${obj}`);
}

//get Cities by id
export async function getCity(id){
    return await https.get(`${api_url}admin/cities/${id}`);
}

// create Cities
export async function createCities(data){
    return await https.post(`${api_url}admin/cities`,data);
}

// update Cities
export async function updateCities(id,data){
    return await https.patch(`${api_url}admin/cities/${id}`,data);
}

// delete Cities
export async function deleteCities(id){
    return await https.delete(`${api_url}admin/cities/${id}`);
}
