import React from 'react'
import Form from './Form';
import { createRole } from '../../../Apis/roles';

export default function AddRole(props) {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await createRole(data).then(res => {
            props.getAll();
            return 'done';          
        }).catch(err =>{
            console.log(err)
            return err.response.data.errors;
        })
    };

    return(
        <div className='actionCardSideWrapper'>
            <Form onSubmit={onSubmit}/>
        </div>
    )
}
