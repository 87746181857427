import React from 'react'
import { useTranslation } from 'react-i18next'
function SingleClubPlan({ name, plan, changeHandler, monthly, biannual, annual }) {
    const { t } = useTranslation()
    return (
        <div className=' p-2 d-flex playerContainer justify-content-between m-2 flex-grow-1 '>
            <div className='d-flex' style={{ maxWidth: "100%", width: "100%", overflow: 'hidden' }}>
                <div className='flex-grow-1 '>
                    <header className='d-flex justify-content-center align-items-center w-100 border-bottom'>
                        <div className='playerImg mb-2' style={{ backgroundImage: `url(${plan})`}}></div>
                        <p className={`fullName text-center fs-4 text-dark mb-2`}>{t(name)}</p>
                    </header>
                    
                    <div className='d-flex align-items-center justify-content-start fw-semibold m-2 my-3 '>
                        <label className={`editedUserEmail text-center font formLabel `} htmlFor='Price'> {t("price")}: </label>
                        <input
                            min={1}
                            name='price'
                            type='number'
                            id='Price'
                            className='m-0 mx-2 text-center font'
                            value={(name === "monthly" ? monthly?.price : name === "biannual" ? biannual?.price : annual?.price)}
                            onChange={(e) => changeHandler(e, (name === "monthly" ? "monthly" : name === "biannual" ? "biannual" : "annual"))}
                        />
                        <p className='mx-2'> {t("sar")}</p>
                    </div>
                    <div className='d-flex align-items-start justify-content-start fw-semibold m-2 flex-column '>
                        <label className={`editedUserEmail text-center font formLabel `} htmlFor='ArDescription'>  {t("ar-desc")}: </label>
                        <textarea
                        maxLength={500}
                        cols="30"
                        rows="10"
                        name="ar"
                        className='w-100 my-2 font'
                        id="ArDescription"
                        value={(name === "monthly" ? monthly?.locales?.ar?.description : name === "biannual" ? biannual?.locales?.ar?.description : annual?.locales?.ar?.description)}
                        onChange={(e) => changeHandler(e, (name === "monthly" ? "monthly" : name === "biannual" ? "biannual" : "annual"))}
                        ></textarea>
                        <label className={`editedUserEmail text-center font my-2 formLabel`} htmlFor='EnDescription'>  {t("en-desc")}: </label>
                        <textarea
                        maxLength={500}
                            cols="30"
                            rows="10"
                            name="en"
                            className='w-100 my-2 font'
                            id="EnDescription"
                            value={(name === "monthly" ? monthly?.locales?.en?.description : name === "biannual" ? biannual?.locales?.en?.description : annual?.locales?.en?.description)}
                            onChange={(e) => changeHandler(e, (name === "monthly" ? "monthly" : name === "biannual" ? "biannual" : "annual"))}
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleClubPlan