import '../forms.scss';
import './Reports.scss';
import '../tables.scss';
import "rsuite/dist/rsuite.min.css";
import Tooltip from '@mui/material/Tooltip';
import hide from "../../Assets/Icons/hide.svg";
import read from "../../Assets/Icons/read.svg";
import play from "../../Assets/Icons/play.svg";
import { useReactToPrint } from 'react-to-print';
import { useSearchParams } from 'react-router-dom';
import unread from "../../Assets/Icons/unread.svg";
import search from "../../Assets/Icons/search.svg";
import VideoModal from '../../Utilities/videoModal';
import deleteicon from "../../Assets/Icons/del.svg";
import DeleteModal from '../../Utilities/deleteModal';
import { basename } from '../../Configuration/config';
import TitleContext from '../../Contexts/TitleContext';
import { getAllSpamsSections } from '../../Apis/spams';
import MessageModal from '../../Utilities/messageModal';
import { useLocation, useNavigate } from 'react-router-dom';
import delete_icon from "../../Assets/Icons/deleteReport.svg";
import Pagination from '../../Components/Pagination/Pagination';
import PermissionsContext from '../../Contexts/permissionsContext';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { deleteComment, deleteReport, deleteVideo, getReports, toggleRead } from '../../Apis/reports';
import { useTranslation } from 'react-i18next';


export default function Reports() {
    const navigate = useNavigate()
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const [section, setSection] = useState(searchParams.get("type") ?? "");
    const [allReports, setAllReports] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const { changeTitle } = useContext(TitleContext);
    const [deleted, setDeleted] = useState("");
    const [deletedName, setDeletedName] = useState('msg');
    const [Sections, setSections] = useState([]);
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '', status: null, type: section });
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '', status: null, type: section });
    const { Permissions } = useContext(PermissionsContext);
    const { t } = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0);
        changeTitle(`reports`)
        getAllReports(filterObj)
        // eslint-disable-next-line
    }, [pathname]);

    function getAllReports(obj) {
        let filter = { ...obj }
        let params = `type=${filter.type}&per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&status=${filter.status}`;
        console.log(params)
        getReports(params).then((res) => {
            setAllReports(res.data.data);
            let total = res.data.pagination.total;
            setPageCount(Math.ceil(total / filter.per_page));
            changeTitle(t("reports"));
        }).catch((err) => {
            console.log(err)
        })

    }

    useEffect(() => {
        getAllSpamsSections().then((res) => setSections(res.data.data))
        getAllReports(filterObj);
        // eslint-disable-next-line
    }, []);


    /*********************   SEARCH   ******************** */
    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj }
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        thisObj.status = filterObj2.status;
        thisObj.type = filterObj2.type;
        setfilterObj(thisObj);
        getAllReports(thisObj)
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        getAllReports(filter);
        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        thisObj2.status = filter.status;
        thisObj2.type = filter.type;
        setfilterObj2(thisObj2);
    }


    function filterByStatus(value) {
        setSection(value)
        let obj = { ...filterObj };
        console.log(obj)
        obj.type = value + "s";
        obj.page = 1;
        setfilterObj(obj);
        getAllReports(obj);
        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = obj.per_page;
        thisObj2.search = obj.search;
        thisObj2.type = obj.type;
        setfilterObj2(thisObj2);
        navigate(`${basename}/dashboard/reports?type=${value}s`);
    }

    /* ***************************  Actions   ******************************** */

    function clickDelete(item) {
        setDeleted(item.id)
        console.log(item)
        setDeletedName("report")
    }

    function clickDeleteContent(item) {
        setDeleted(item.model.id)
        console.log(item)
        setDeletedName(item.type)
    }

    function markContactMsgRead(id, status) {
        if (status === null) {
            toggleRead(id, "read").then(() => getAllReports(filterObj))
        } else {
            toggleRead(id, "unread").then(() => getAllReports(filterObj))
        }
    }

    async function DeleteReport() {
        return await deleteReport(deleted).then(() => {
            getAllReports(filterObj)
            return "done";
        }).catch(err => {
            console.log(err.response)
            return err.response.data.message;
        })
    }

    async function DeleteVideo() {
        return await deleteVideo(deleted).then(() => {
            getAllReports(filterObj)
            return "done";
        }).catch(err => {
            console.log(err)
            return err?.response?.data?.message;
        })
    }

    async function DeleteComment() {
        return await deleteComment(deleted).then(() => {
            getAllReports(filterObj)
            return "done";
        }).catch(err => {
            console.log(err)
            return err?.response?.data?.message;
        })
    }
    /* ******************** export pdf **************************** */
    const componentRef = useRef();
    let cloneRef = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {
            cloneRef = componentRef.current.cloneNode(true);
            cloneRef.firstChild.firstChild.lastChild.classList.add('d-none');
            for (let i = 0; i < cloneRef.lastChild.childNodes.length; i++) {
                cloneRef.lastChild.childNodes[i].lastChild.classList.add('d-none');
            }
        },
        content: () => cloneRef,
    });

    return (
        <section className='componentGlobalWrapper d-flex flex-column'>
            <div>
                <div className='searchActionsBar w-100 d-flex justify-content-start align-items-centrer flex-wrap mt-1'>
                    {
                        Sections?.map((item, index) => {
                            return (
                                <React.Fragment key={index} >
                                    {index ? <span className='separator'></span> : ''}
                                    <div
                                        id={`${item.slug}-tab`}
                                        onClick={() => filterByStatus(item.slug)}
                                        className={`tablinks ${section === item.slug && `active`}`}
                                    >
                                        {item?.name}
                                        <span className='tabcounter'>
                                            {section === item.slug}
                                        </span>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                    <div className='w-100  d-flex justify-content-between flex-wrap'>
                        <div className='searchActionsBar m-0'>
                            <div className='mt-0 mb-0'>

                                {/*** search input ***/}
                                <div className='searchInputWrapper'>
                                    <div>
                                        <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                    </div>
                                    <img src={search} alt='search-icon' className='barSearchIcon' />
                                </div>
                            </div>

                            {/* show select */}
                            <div className='perPageContainer'>
                                <label htmlFor='selectShow' className='perPageLabel'>
                                    {t("show")}
                                </label>
                                <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                                    <option value='5'>5</option>
                                    <option value='10'>10</option>
                                    <option value='15'>15</option>
                                    <option value='20'>20</option>
                                </select>
                            </div>
                            {/* show select */}
                            <div className='perPageContainer'>
                                <label htmlFor='selectStatus' className='perPageLabel'>
                                    {t("status")}
                                </label>
                                <select name='status' onChange={changeFilterObj} id={`selectStatus`} className='form-select ' value={filterObj2?.status ?? undefined}>
                                    <option value={null} className='mx-2'>{t("all")}</option>
                                    <option value={1} className='mx-2'>{t("read")}</option>
                                    <option value={0} className='mx-2'>{t("unread")}</option>
                                </select>
                            </div>
                            {/*** apply search btn  ***/}
                            <button className='applySearchBtn' onClick={getFiltered}>{t("search")}</button>
                        </div>
                        {/*** export as pdf ***/}
                        <div className="dropdown">
                            <button className="dropdown-toggle exportDropdown confirmBtn" type="button" id="exportButton" data-bs-toggle="dropdown" aria-expanded="false">
                                {t("export")}
                            </button>
                            <ul className="dropdown-menu custom-bulk-dropdown" aria-labelledby="exportButton">
                                <li className='' onClick={handlePrint}>
                                    <span className=' px-2 py-1'>pdf</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                {
                    // Permissions['contact-us']?.view &&
                    <div className='tableWrapper'>
                        <table ref={componentRef} className="w-100 table table-striped" >
                            <thead>
                                <tr>
                                    <th>{t("username")}</th>
                                    <th>{t("reported-content")}</th>
                                    <th>{t("reported-user")}</th>
                                    <th>{t("actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allReports && allReports.map((report, index) => (
                                    <React.Fragment key={index}>
                                        <tr className='stripedRow'>

                                            <td>
                                                <div className='col-6 ps-0'>
                                                    {report.user.username}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='col-6 ps-0'>
                                                    {report?.model?.comment || report.model.message ||
                                                        <>
                                                            {report.type === 'video' &&
                                                                <Tooltip title='play the reported video'>
                                                                    <div className='contactActions' data-bs-toggle="modal" data-bs-target={`#videoModel`}  >
                                                                        <img className='iconActions' src={play} alt="play" />
                                                                        <VideoModal video={report?.model.url} />
                                                                    </div>
                                                                </Tooltip>}
                                                        </>
                                                        || `the content is ${report.type}`}
                                                </div>
                                            </td>

                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    {report.model.reported.username}
                                                </div>
                                            </td>


                                            <td className='hideAtPrint'>
                                                <div className='d-flex'>
                                                    {
                                                        Permissions?.report?.delete &&
                                                        <Tooltip title={t('delete-report')}>
                                                            <div className='contactActions'>
                                                                <img className='iconActions' src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => clickDelete(report)} />
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                    {
                                                        Permissions?.report?.action &&
                                                        <div className='contactActions' data-bs-toggle="modal" data-bs-target={`#readMsgModel`}  >
                                                            {report?.read_at !== null ?
                                                                <Tooltip title={t('read-msg')}>
                                                                    <img className='iconActions' onClick={() => markContactMsgRead(report.id, report.read_at)} src={read} alt="edit" />
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title={t('unread-msg')}>
                                                                    <img className='iconActions' onClick={() => markContactMsgRead(report.id, report.read_at)} src={unread} alt="edit" />
                                                                </Tooltip>
                                                            }
                                                            <MessageModal description={report.note ?? ""} />
                                                        </div>
                                                    }



                                                    {Permissions?.report?.action &&
                                                        (report.type === 'video' || report.type === 'comment') &&
                                                        <Tooltip title={t(`delete-${report.type}`)} >
                                                            <div className='contactActions'>
                                                                <img
                                                                    className='iconActions'
                                                                    src={deleteicon} alt="delete"
                                                                    data-bs-toggle="modal" data-bs-target='#deleteModal'
                                                                    onClick={() => clickDeleteContent(report)} />
                                                            </div>
                                                        </Tooltip>
                                                    }

                                                    {
                                                        Permissions?.report?.action &&
                                                        report.type === 'message' &&
                                                        <Tooltip title={t('hide-message')}>
                                                            <div className='contactActions' >
                                                                <img className='iconActions' src={hide} alt="hide" />
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
            <div className='d-flex justify-content-end mt-auto'>
                <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
            </div>

            <DeleteModal onSubmitDelete={deletedName === 'video' ? DeleteVideo : deletedName === 'comment' ? DeleteComment : DeleteReport} name={deletedName} />

        </section>
    )
}
