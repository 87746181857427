import { t } from 'i18next';
import React, { useState, useEffect, useContext } from 'react'
import { supportedLanguages } from './../../../Utilities/SupportedLanguages';
import TitleContext from '../../../Contexts/TitleContext';
import { getSetting, updateSetting } from '../../../Apis/settings';
import { useNavigate } from 'react-router';
import PermissionsContext from '../../../Contexts/permissionsContext';
import privacy from '../../../Assets/Icons/Privacypolicy.svg'
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {
    const { Permissions } = useContext(PermissionsContext);
    const [loader, setLoader] = useState(false);
    const { changeTitle } = useContext(TitleContext);
    const [Locales, setLocales] = useState("");
    const [errors, setErrors] = useState("")
    const navigate = useNavigate()
    const {i18n} = useTranslation()
    useEffect(() => {
        changeTitle(t('privacy-and-policy'), '');
        privacyPolicyData()
        // eslint-disable-next-line
    }, [])

    async function privacyPolicyData() {
        try {
            const res = await getSetting("privacy-policy")
            let data = res.data.data?.locales;
            console.log(res);
            setLocales(data);
        } catch (err) {
            console.log(err.response);
        };
    }

    async function submitHandler(e) {
        e.preventDefault();
        setLoader(true)
        console.log(Locales)
        if (Locales !== "" && Locales?.ar !== [] && Locales?.en !== [] && Locales?.ar?.description !== "" && Locales?.en?.description !== "")  {
            try {
                const res = await updateSetting("privacy-policy", Locales)
                setLoader(false)
                console.log(res)
                navigate('/dashboard/settings/appearance')
            } catch (err) {
                setLoader(false)
                console.log(err);
            }
        } else {
            setErrors("Please Fill All Fields")
            setLoader(false)
        }
    }

    function handleChange(e, lang) {
        const { name, value } = e.target;
        setLocales((prevState) => ({
            ...prevState,
            [name]: {
                description: value
            }
        }));
        console.log(Locales)
    }


    return (
        <div className='componentGlobalWrapper' style={{ backgroundImage: `url(${privacy})`, backgroundRepeat: "no-repeat", backgroundPosition: i18n.language === "ar" ? "left" :"right"}}>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{t('page-content')}</h5>
                <hr />
                {errors && <span className='text-danger'>{errors}</span>}
                <form className='pagesForm w-100 pt-5' onSubmit={submitHandler} >
                <div className='d-flex flex-column'>
                    {supportedLanguages?.map((language, index) => {
                        return <div key={index} className='d-flex flex-column mb-4'>
                            <label htmlFor='content' className='formLabel'>{`${t('content-in')} (${t(`${language.name}`)})`}</label>
                            <div {...(language.slug === 'ar' ? { 'dir': 'rtl' } : {})}>
                                <textarea disabled={!Permissions?.setting?.edit} name={`${language.slug}`} className='textArea w-50 ' value={Locales[language.slug]?.description} onChange={(e) => handleChange(e, language.slug)} />
                            </div>
                        </div>
                    })}
                    </div>
                    {    // Permissions?.appearance?.edit && 
                        <>
                            {Permissions?.setting?.edit && !loader ?
                                <div>
                                    <button className='confirmBtn' type='submit' >
                                        {t('save-changes')}
                                    </button>
                                </div>
                                :(Permissions?.setting?.edit &&
                                <button className='confirmBtn' disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {t('saving')}...
                                </button>)
                            }
                        </>}
                </form>
            </div>
        </div>
    )
}

