import React, { useContext, useEffect } from 'react';
import './dashboard.scss';
import AllRoutes from './../../Routes/Routes';
import Header from '../../Components/Header/Header';
import Aside from '../../Components/Aside/Aside';
//import PermissionsContext from '../../Contexts/permissionsContext';
// import { getAdminProfileInfo } from '../../Apis/profile';
import { useTranslation } from 'react-i18next';

export default function DashboardLayout() {
    //let { changePermissions } = useContext(PermissionsContext);
    const { i18n } = useTranslation();

    // useEffect(() => {
    //     getAdminProfileInfo().then((res) => {
    //         let permission = res.data.data.role.permissions;
    //         console.log(permission)
    //         changePermissions(permission); 
    //     })
    //     // eslint-disable-next-line
    // }, [])

    return (
        <div className='d-flex mx-0 px-0'>
            <div className={`asidePart offcanvas d-lg-none ${i18n.language === 'en' ? 'offcanvas-start' : 'offcanvas-end'}`} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling">
                <Aside mobWidth={55} />
            </div>
            <div className='asidePart d-none d-lg-block'>
                <Aside />
            </div>
            <div className='remainPart'>
                <div>
                    <Header />
                </div>
                <div className='renderdComponents'>
                    <AllRoutes />
                </div>
            </div>
        </div>
    )
}
