import React, { useEffect, useRef, useState } from 'react'
import formModel from './formModel';
import { getClubPlayer } from '../../../Apis/clubs';
import { t } from 'i18next';
import { getAllPlayerPositions } from '../../../Apis/playerPositions';
import i18n from '../../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { preventEnter } from '../../../Utilities/preventEnter';
import Media from '../../../Components/MediaComponent/Media';

export default function Form(props) {
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const id = props?.id;
    const [errors, setErrors] = useState({});
    const [positions, setpositions] = useState([]);
    const [image, setImage] = useState('');
    const closeCreateModalRef = useRef();



    useEffect(() => {
        setErrors({})
        if (id !== 'create') {
            getClubPlayer(id).then((res) => {
                let x = res.data.data;
                console.log(x);
                setImage(x?.media ?? null);
                setModel({
                    full_name: x.full_name,
                    club: props.clubId,
                    player_position: x.player_position,
                    media: x?.media?.id ?? null
                });
            })
        } else {
            setModel({
                full_name: '',
                club: props.clubId,
                player_position: '',
                media: ''
            })
            setImage('');
        }
        
        getAllPlayerPositions().then((res) => {
            setpositions(res.data.data)
        })
        // eslint-disable-next-line
    }, [props]);

    // onchange
    const setFormData = (e, property, locale = false, language = null) => {
        let newData = Object.assign({}, model)
        if (!locale) {
            newData[property] = e.target.value;
        }
        setModel(newData);
        let err = { ...errors };
        err = {};
        setErrors(err)
    }

    // onsubmit
    const confirm = async (e, model) => {
        e.preventDefault();
        setLoading(true);
        console.log(model)
        let submitProps = await props.onSubmit(e, model);
        console.log(submitProps);
        if (submitProps === "done") {
            setLoading(false);
            setModel({
                full_name: '',
                club: props.clubId,
                player_position: '',
                media: ''
            });
            setImage('');
            closeCreateModalRef.current.click();
        } else {
            setLoading(false);
            setErrors(submitProps);
        }
    }

    function resetPlayerForm() {
        setModel({
            full_name: '',
            club: props.clubId,
            player_position: '',
            media:''
        });
        setImage('');
    }

   

    return (<div className="modal-content">
        <div className="modal-header m-0">
            <h5 className='pageSubtitle mb-0'>{id==='create' ? t('create-new-player') : t('update-player')}</h5>
            <div ref={closeCreateModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={resetPlayerForm} >
                <FontAwesomeIcon icon={faXmark} />
            </div>
        </div>
        <div className="modal-body">
            <form id='adminForm' onSubmit={(e) => confirm(e, model)} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-start'>
                    <div className="w-100">
                        {/* FULL NAME */}
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor={`name`} className='formLabel'>{t('name')}</label>
                            <input name="full_name" id={`name`} type='text' placeholder={t('name')} value={model?.full_name} onChange={(e) => setFormData(e, 'full_name')} minLength={3} maxLength={20} required />
                            <div className='text-danger validateToDelete'>
                                {errors && errors[`full_name`]}
                            </div>
                        </div>
                        {/* POSITION */}
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='player_position' className='formLabel'>{t('position')}</label>
                            <div className='selectParent'>
                                <select name='player_position' value={model?.player_position} onChange={(e) => setFormData(e, 'player_position')} required>
                                    <option value='' disabled>{t('select-player-position')}</option>
                                    {positions && positions.map((item, index) => {
                                        let lang = i18n.language;
                                        return (
                                            <option value={item.id} key={index} > {item?.locales[lang]?.name} </option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='text-danger validateToDelete'>
                                {errors && errors[`player_position`]}
                            </div>
                        </div>
                        <Media errors={errors} setErrors={setErrors} stateImg={image} setStateImg={setImage} model={model} setModel={setModel}/> 
                    </div>
                    
                </div>

                {!loading ?
                    <button className='confirmBtn' type='submit'>{id !== 'create' ? t(`save-changes`) : t(`add-player`)}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        {t('saving')}...
                    </button>
                }
            </form>
        </div>
    </div>
    )
}
