import { t } from 'i18next';
import React, { useState, useEffect, useContext /* , useRef */ } from 'react';
import '../tables.scss';
import { useLocation, useNavigate } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../Assets/Icons/edit.svg";
import delete_icon from "../../Assets/Icons/delete.svg";
import search from "../../Assets/Icons/search.svg";
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import {
    Link, /* , useSearchParams */
    useSearchParams
} from 'react-router-dom';
import DeleteModal from '../../Utilities/deleteModal';
import i18n from '../../i18n';
import moment from 'moment';
import '../forms.scss';
import PermissionsContext from '../../Contexts/permissionsContext';
import { deleteSpam, getAllSpams, getAllSpamsSections } from '../../Apis/spams';
import Pagination from '../../Components/Pagination/Pagination';
import { dateLanguage } from '../../Utilities/dateLanguage';
import NotDataFound from '../../Components/NotDataFound/notDataFound';

export default function Spams() {
    let { Permissions } = useContext(PermissionsContext);
    const [searchParams] = useSearchParams();
    console.log(searchParams.get("section"))
    const [section, setSection] = useState(searchParams.get("section") ?? "account");
    const [Spams, setSpams] = useState([]);
    const [Sections, setSections] = useState([]);
    const [total, setTotal] = useState([]);
    const [deletedId, setDeletedId] = useState('');
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '', section: section });
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '' });
    const [pageCount, setPageCount] = useState('');
    const navigate = useNavigate();

    function getSpams(obj) {
        let filter = { ...obj }
        let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&spam_section=${filter.section}`;
        console.log(params)
        getAllSpams(params).then(res => {
            setSpams(res.data.data);
            console.log(res);
            let total = res.data.pagination.total;
            setTotal(total)
            setPageCount(Math.ceil(total / filter.per_page));
        })
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    }, [i18n.language])

    useEffect(() => {
        getAllSpamsSections().then((res) => {
            setSections(res.data.data);
            console.log(res.data.data);
        })
        changeTitle(t(`spams`), Permissions?.spam?.create && { url: 'spams/create', text: t('add-spam'), canAdd: Permissions?.spam?.create });
        getSpams(filterObj)
        // eslint-disable-next-line
    }, []);

    async function onSubmitDelete() {
        return await deleteSpam(deletedId).then(res => {
            getSpams(filterObj);
            return "done";
        }).catch(err => {
            console.log(err.response.data.message)
            return err.response.data.message;
        })
    }

    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj }
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        setfilterObj(thisObj);
        getSpams(thisObj)
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        getSpams(filter);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        setfilterObj2(thisObj2);
    }


    function filterByStatus(value) {
        setSection(value)
        let obj = { ...filterObj };
        console.log(obj)
        obj.section = value;
        obj.page = 1;
        setfilterObj(obj);
        getSpams(obj);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = obj.per_page;
        thisObj2.search = obj.search;
        setfilterObj2(thisObj2);
        navigate(`${basename}/dashboard/spams?section=${value}`);
    }

    return (
        <>
            {<section className='componentGlobalWrapper d-flex flex-column'>
                <div>
                    <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                        {
                            Sections?.map((item, index) => {
                                return (
                                    <React.Fragment key={index} >
                                        {index ? <span className='separator'></span> : ''}
                                        <div onClick={() => filterByStatus(item.slug)} className={`tablinks ${section == item.slug && `active`}`} id={`${item.slug}-tab`} data-bs-toggle="tab" data-bs-target={`#${item.slug}`}>{item?.name} <span className='tabcounter'>{section == item.slug && "(" + Spams.length + ")"}</span></div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                    <div className='searchActionsBar w-100'>
                        <div className='searchActionsBar mt-0 mb-0'>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                        </div>

                        {/* show select */}
                        <div className='perPageContainer'>
                            <label htmlFor='selectShow' className='perPageLabel'>
                                {t('show')}
                            </label>
                            <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                            </select>
                        </div>
                        {/*** apply search btn  ***/}
                        <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
                    </div>
                    {<div className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr>
                                    <th>{t('name')}</th>
                                    <th>{t('created-at')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Spams.length > 0 ? Spams.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    {item?.name}
                                                </div>
                                            </td>
                                            <td>
                                                {moment(item.created_at).format('DD-MMMM-YYYY')}
                                            </td>
                                            <td>

                                                <div>
                                                    {Permissions?.spam?.edit && <Tooltip title={t('edit')}>
                                                        <Link to={`${basename}/dashboard/spams/${item.id}/update`}>
                                                            <img className='iconActions' src={edit_icon} alt="edit" />
                                                        </Link>
                                                    </Tooltip>}
                                                    {Permissions?.spam?.delete &&
                                                        <Tooltip title={t("delete")}>
                                                            <img data-bs-toggle="modal" onClick={() => setDeletedId(item.id)} data-bs-target='#deleteModal' className='iconActions' src={delete_icon} alt="delete" />
                                                        </Tooltip>}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                    :
                                    <tr >
                                        <td colSpan={3} >
                                            <NotDataFound />
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                        <DeleteModal onSubmitDelete={onSubmitDelete} name='spam'></DeleteModal>
                    </div>}
                </div>
                {<div className='d-flex justify-content-end mt-auto'>
                    <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
                </div>}
            </section>}
        </>
    )
}
