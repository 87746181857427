import { createContext, useEffect } from "react";
import { useState } from "react";
import { getAdminProfileInfo } from "../Apis/profile";

let PermissionsContext = createContext('');
export function PermissionsContextProvider(props) {
    let [Permissions, setPermissions] = useState({});
    function changePermissions(permissions) {
        setPermissions(permissions);
        //console.log(permissions);
    }
    useEffect(() => {
        getAdminProfileInfo().then((res) => {
            let permission = res.data.data.permissions;
            setPermissions(permission);
            console.log(res)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <PermissionsContext.Provider value={{ Permissions, changePermissions }}>
            {props.children}
        </PermissionsContext.Provider>
    )
}
export default PermissionsContext;