import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getCourse } from '../../Apis/courses'
import TitleContext from '../../Contexts/TitleContext'
import '../profile.scss'
import NotDataFound from '../../Components/NotDataFound/notDataFound'
import { Chip } from '@mui/material'
import expired from '../../Assets/Icons/expired.svg'
import cleander from '../../Assets/Icons/cleander.svg'

export default function TrainersDetails() {
    const { changeTitle } = useContext(TitleContext)
    const { id } = useParams()
    const [course, setcourse] = useState()

    //const { Permissions } = useContext(PermissionsContext);
    function getData() {
        getCourse(id).then((res) => {
            setcourse(res.data.data);
            console.log(res.data.data)
        })
    }

    useEffect(() => {
        getData();
        changeTitle(t('course-details'));
        // eslint-disable-next-line
    }, [])

    return (
        <React.Fragment>
            {course && <section className={`profile pt-0 mt-4`}>
                <article className="postcard light blue myModal">
                    <div className="postcard__img_link bg-light position-relative">
                        <img className="postcard__img position-relative" src={course?.cover} alt="" />
                    </div>
                    <div className="postcard__text t-dark w-100">
                        <h1 className="postcard__title blue d-flex align-items-center justify-content-between">
                            <div className='text-dark' >{course.title}</div>
                            {course?.status && <Chip className={` fw-bold px-3 ${course?.status?.slug === "pending" ? "bg-warning" : course?.status?.slug === "need-action" ? "bg-primary" : course?.status?.slug === "active" ? "bg-success" : "bg-danger"}`}
                                icon={<img alt='' width={"30px"} src={expired} />} label={course?.status.name} />}
                        </h1>
                        <div className="postcard__subtitle small d-flex justify-content-between align-items-center w-100">
                            <b>{course.individual_price} {t("sar")}</b>
                            <b className="bg-gray text-dark subscriber p-2 border rounded" style={{ cursor: "pointer" }}>
                                <div className="mr-2" >{course?.user?.username}</div>
                            </b>
                        </div>
                        <div className="postcard__bar"></div>
                        <ul className="postcard__tagbox mt-1 text-light justify-content-evenly text-center border rounded mb-2">
                            <li className="tag__item bg-transparent text-dark flex-grow-1 p-1"><i className="mr-2"></i> <b>{t("available-seats")} :  </b>{course?.available_seats}</li>
                            <li className="tag__item bg-transparent text-dark flex-grow-1 p-1"><i className=" mr-2"></i><b>{t("session-count")} : </b> {course?.sessions_count}</li>
                            <li className="tag__item bg-transparent text-dark flex-grow-1 p-1"><i className="mr-2"></i> <b>{t("acadimies-offer")} : </b> {course?.group_discount}</li>
                            <li className="tag__item bg-transparent text-dark flex-grow-1 p-1"><i className="mr-2"></i> <b>{t("subscribers")} :  </b> {course?.subscribers_count}</li>

                        </ul>
                        <div className="postcard__preview-txt">{course.description}</div>

                    </div>
                </article>

                {/*******Statics*********/}
                <div className={`row row-cols-2 row-cols-md-4 m-0 p-0 mb-2 reportsContainer`}>
                    <div className="col m-0 mt-3">
                        <div className={`adsReportContainer bg1`}>
                            <div className={`numbers mt-0 pt-0 mb-4`}>
                                <p className="mt-0 pt-0">{course?.likes_count}</p>
                            </div>
                            <div className='description'>
                                <p>{t('likes')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col m-0 mt-3">
                        <div className={`adsReportContainer bg2`}>
                            <div className={`numbers mt-0 pt-0 mb-4`}>
                                <p className="mt-0 pt-0">{course?.comments_count}</p>
                            </div>
                            <div className='description'>
                                <p>{t('comments')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col m-0 mt-3">
                        <div className={`adsReportContainer bg3`}>
                            <div className={`numbers mt-0 pt-0 mb-4`}>
                                <p className="mt-0 pt-0">{course?.shares_count}</p>
                            </div>
                            <div className='description'>
                                <p>{t("shares")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col m-0 mt-3">
                        <div className={`adsReportContainer bg4`}>
                            <div className={`numbers mt-0 pt-0 mb-4`}>
                                <p className="mt-0 pt-0">{course?.views_count}</p>
                            </div>
                            <div className='description'>
                                <p>{t('views')}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mb-5'>
                    <div className='filterAtProfile'>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">

                            <div className={`tablinks  active`} id={`video-tab`} data-bs-toggle="tab" data-bs-target={`#video`} aria-controls="video" >
                                {t("video")}
                            </div>
                            <div className={`tablinks`} id={`sessions-tab`} data-bs-toggle="tab" data-bs-target={`#sessions`} aria-controls="sessions" >
                                {t("sessions")}
                            </div>

                            <div className={`tablinks`} id={`subscribers-tab`} data-bs-toggle="tab" data-bs-target={`#subscribers`} aria-controls="subscribers" >
                                {t("subscribers")}
                            </div>
                        </div>
                    </div>

                    <div className='tab-content' id="pills-tabContent">
                        <div className={`tab-pane fade m-0 p-0 active  show`} id="video" role="tabpanel" aria-labelledby="video">
                            <div className='d-flex justify-content-center'>
                                <video controls height="500px" className='border rounded'>
                                    <source src={course.video} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                        <div className={`tab-pane fade m-0 p-0   show`} id="sessions" role="tabpanel" aria-labelledby="sessions">
                            {course?.sessions &&
                                <div className='d-flex justify-content-start flex-wrap mx-4 '>
                                    {course?.sessions &&
                                        course?.sessions?.map((ses) => (
                                            <div key={ses?.id} className='border rounded m-1 p-2 text-center '>
                                                <Chip className='border-0' icon={<img alt='' width={"35px"} className='' src={cleander} />} variant="outlined" label="" />
                                                <p className='d-flex flex-column justify-content-center align-items-start m-2'>
                                                    <span className="tag__item"><b className="m-2">{t("date")} : </b>{` ${ses.date}`}</span>
                                                    <span className="tag__item"><b className="m-2">{t("time")} : </b>{` ${ses.time} ${ses.time > 12 ? t('pm') : t('am')}`}</span>
                                                </p>
                                            </div>
                                        ))}
                                </div>}
                        </div>

                        <div className={`tab-pane fade m-0 p-0 show`} id="subscribers" role="tabpanel" aria-labelledby="subscribers">
                            {/* {course?.length ?
                                <div className='m-0 p-0 d-flex flex-wrap'>
                                    {players?.map((item, index) => {
                                        return (
                                            <div className='m-2 flex-grow-1' key={index}>
                                                <div className='border p-2 d-flex  '>
                                                    <div className='playerImg border flex-1' style={{ backgroundImage: `url(${item?.media?.webp ?? noImage})` }}>
                                                    </div>
                                                    <div className=''>
                                                        <p className={`fullName`}>{item?.first_name} {item?.last_name}</p>
                                                        <p className={`editedUserEmail`}>{item?.player_position.name}</p>
                                                        <p className={`editedUserEmail`}>{item?.email}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })} 
                                </div>

                                : <div className='w-100'>
                                    <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
                                </div>
                            } */}
                        </div>
                    </div>


                </div>
            </section>}



        </React.Fragment>
    )
}
