import { https } from './https';
import { api_url } from './../Configuration/config';

//get Page by slug
export async function getSetting(slug){
    return await https.get(`${api_url}admin/settings/${slug}`);
}

//update static Page
export async function updateSetting(slug, locales){
    return await https.patch(`${api_url}admin/settings/${slug}`, {locales});
}
