import React, { useContext, useEffect } from 'react'
import Form from './Form';
import TitleContext from '../../../Contexts/TitleContext';
import { t } from 'i18next';
import { createSpam } from '../../../Apis/spams';
import i18n from '../../../i18n';

export default function AddSpam() {
    const { changeTitle } = useContext(TitleContext);

    useEffect(() => {
        changeTitle(t('add-spam'));
        // eslint-disable-next-line
    }, [i18n.language]);

    const onSubmit = async (e, data) => {
        e.preventDefault();
        console.log("there wqe are ", data)
        //to send request
        
        return await createSpam(data).then(res => {
            return 'done';
        }).catch(err => {
            if (err.response.status === 403) {
                return { error: err.response.data.message, type: 'permissions' };
            } else if (err.response.status === 422) {
                return { error: err.response.data.errors, type: 'validations' };
            }
        })
    };

    return (
        <div>
            <Form onSubmit={onSubmit} />
        </div>
    )
}
