import { t } from 'i18next';
import React, { useState, useEffect, useContext /* , useRef */ } from 'react';
import '../tables.scss';
import { useLocation, useNavigate } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import search from "../../Assets/Icons/search.svg";
import noImg from "../../Assets/Images/noImg.png";
import accept from "../../Assets/Icons/accept.svg";
import reject from "../../Assets/Icons/reject.svg";
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import { Link, useSearchParams } from 'react-router-dom';
import i18n from '../../i18n';
import moment from 'moment';
import '../forms.scss';
import PermissionsContext from '../../Contexts/permissionsContext';
import {
  acceptCourse, getAllCourses, rejectCourse, // blockAcademy, rejectAcademy 
} from '../../Apis/courses';
import Pagination from '../../Components/Pagination/Pagination';
import { getStatuses } from '../../Apis/statics';
import RejectModal from '../../Utilities/rejectModal';
import { dateLanguage } from '../../Utilities/dateLanguage';
import NotDataFound from '../../Components/NotDataFound/notDataFound';
import detail from '../../Assets/Icons/detail.svg'

export default function Courses() {
  let { Permissions } = useContext(PermissionsContext);
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(searchParams.get("status") ?? 'active');
  const [statuses, setStatuses] = useState([]);
  const [Courses, setCourses] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const { pathname } = useLocation();
  const { changeTitle } = useContext(TitleContext);
  const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '', status: status });
  const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '', status: status });
  const [pageCount, setPageCount] = useState('');
  const [total, setTotal] = useState()
  const navigate = useNavigate()
  console.log(total);

  async function getCourses(obj) {
    let filter = { ...obj }
    let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&status=${filter.status}`;
    console.log(params)
    try {
      const res = await getAllCourses(params)
      const Allres = await getAllCourses()
      setTotal(Allres.data.data)
      console.log(res)
      setCourses(res.data.data);
      let total = res.data.pagination.total;
      setPageCount(Math.ceil(total / filter.per_page));
    } catch (err) { console.log(err) }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    dateLanguage(i18n.language)
    // eslint-disable-next-line
  }, [i18n.language])

  useEffect(() => {
    getStatuses('course').then((res) => {
      setStatuses(res.data.data)
    })

    changeTitle(t(`courses`));
    getCourses(filterObj)
    // eslint-disable-next-line
  }, []);

  function changeFilterObj(e) {
    let thisObj = { ...filterObj2 }
    thisObj[e.target.name] = e.target.value.trim();
    setfilterObj2(thisObj);
  }

  function getFiltered() {
    let thisObj = { ...filterObj }
    thisObj.page = 1;
    thisObj.per_page = filterObj2.per_page;
    thisObj.search = filterObj2.search;
    setfilterObj(thisObj);
    getCourses(thisObj)
  }

  const handlepageChange = async data => {
    let filter = { ...filterObj };
    filter.page = data.selected + 1;
    setfilterObj(filter);
    getCourses(filter);

    let thisObj2 = { ...filterObj2 };
    thisObj2.per_page = filter.per_page;
    thisObj2.search = filter.search;
    setfilterObj2(thisObj2);
  }

  function filterByStatus(value) {
    setStatus(value)
    let obj = { ...filterObj };
    obj.status = value;
    obj.page = 1;
    setfilterObj(obj);
    getCourses(obj);

    let thisObj2 = { ...filterObj2 };
    thisObj2.per_page = obj.per_page;
    thisObj2.search = obj.search;
    setfilterObj2(thisObj2);
    navigate(`${basename}/dashboard/courses?status=${value}`);
  }

  /* accept */
  function acceptHandler(id) {
    acceptCourse(id).then(() => {
      getCourses(filterObj);
    }).catch((err) => {
      console.log(err.response);
    })
  }

  /*reject*/
  const [reason, setReason] = useState('');

  async function submitReject() {
    return await rejectCourse(selectedId, { note: reason }).then(res => {
      getCourses(filterObj);
      return "done";
    }).catch(err => {
      console.log(err.response.data)
      return err.response.data.errors;
    })
  }





  return (
    <>
      {<section className='componentGlobalWrapper d-flex flex-column'>
        <div>
          <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
            {
              statuses?.map((item, index) => {
                return (
                  <React.Fragment key={index} >
                    {index ? <span className='separator'></span> : ''}
                    <div onClick={() => filterByStatus(item.slug)} className={`tablinks ${status === item.slug && `active`}`} id={`${item.slug}-tab`} data-bs-toggle="tab" data-bs-target={`#${item.slug}`}>{item?.name} <span className='tabcounter'>{status === item.slug && "(" + Courses.length + ")"}</span></div>
                  </React.Fragment>
                )
              })
            }
          </div>

          <div className='searchActionsBar w-100'>
            <div className='searchActionsBar mt-0 mb-0'>
              {/*** search input ***/}
              <div className='searchInputWrapper'>
                <div>
                  <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                </div>
                <img src={search} alt='search-icon' className='barSearchIcon' />
              </div>
            </div>

            {/* show select */}
            <div className='perPageContainer'>
              <label htmlFor='selectShow' className='perPageLabel'>
                {t('show')}
              </label>
              <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='15'>15</option>
                <option value='20'>20</option>
              </select>
            </div>

            {/*** apply search btn  ***/}
            <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
          </div>

          <div className='tableWrapper'>
            <table className="w-100 table table-striped border-0">
              <thead>
                <tr>
                  <th>{t('cover')}</th>
                  <th>{t('title')}</th>
                  <th>{t('price')}</th>
                  <th>{t('sessions')}</th>
                  <th>{t('seats')}</th>
                  <th>{t('trainer')}</th>
                  <th>{t('status')}</th>
                  <th>{t('created-at')}</th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {Courses.length > 0 ? Courses.map((item, index) => {
                  console.log(item)
                  return (
                    <tr key={index}>
                      <td className='sliderImg '>
                        <img src={item?.cover ? item?.cover : noImg} alt="slider" />
                      </td>
                      <td className='desc'>
                        <div className='descriptionTd'>
                          {item?.title}
                        </div>
                      </td>
                      <td>
                        {item?.individual_price}
                      </td>
                      <td>
                        {item?.sessions_count}
                      </td>
                      <td>
                        {item?.seats}
                      </td>
                      <td>
                        {item?.user?.username}
                      </td>
                      <td>
                        {item?.status?.name}
                      </td>
                      <td>
                        {moment(item.created_at).format('DD-MMMM-YYYY')}
                      </td>
                      <td>
                        <div>
                          <Tooltip title={t('course-details')}>
                            <Link to={`${basename}/dashboard/courses/${item.id}/details`}>
                              <img className='iconActions' src={detail} alt="profile" />
                            </Link>
                          </Tooltip>
                          {item?.status.slug === 'pending' &&
                            Permissions?.trainer?.edit && <>
                              <Tooltip title={t('accept-course')}>
                                <img onClick={() => acceptHandler(item?.id)} className='iconActions' src={accept} alt="accept" />
                              </Tooltip>
                              <Tooltip title={t('reject-course')}>
                                <img onClick={() => setSelectedId(item?.id)} data-bs-toggle="modal" data-bs-target='#rejectModal' className='iconActions' src={reject} alt="reject" />
                              </Tooltip></>}

                        </div>
                      </td>
                    </tr>
                  )})
                  :
                  <tr >
                    <td colSpan={9} >
                      <NotDataFound />
                    </td>
                  </tr>
                }
              </tbody>
            </table>
            <RejectModal submitReject={submitReject} reason={reason} setReason={setReason} ></RejectModal>

          </div>
        </div>
        <div className='d-flex justify-content-end mt-auto'>
          <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
        </div>
      </section>}
    </>
  )
}
