import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getCoursesProfits() {
    return await https.get(`${api_url}admin/financial-settings?slug=course-profit-margin`);
}
export async function getZoomPrice() {
    return await https.get(`${api_url}admin/financial-settings?slug=zoom-minute-cost`);
}
export async function addCoursesProfits(val) {
    return await https.patch(`${api_url}admin/financial-settings/course-profit-margin`, val);
}
export async function addZoomPrice(val) {
    return await https.patch(`${api_url}admin/financial-settings/zoom-minute-cost`, val);
}

