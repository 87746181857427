import React, { useContext } from 'react';
import './overview.scss';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import RadialChart from '../../Components/RadialChart/RadialChart';
import { useState } from 'react';
import { getOverview } from '../../Apis/profile';
import user from '../../Assets/Icons/user-count.svg'
import msg from '../../Assets/Icons/msg-count.svg'
import vdio from '../../Assets/Icons/vdio-count.svg'

export default function Overview() {
    const { pathname } = useLocation();
    let { changeTitle } = useContext(TitleContext);
    const { i18n } = useTranslation();
    const [overview, setOverview] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            const overview = await getOverview();
            const { data } = overview;
            setOverview(data.data);
        };
        fetchData();
        changeTitle('Overview');
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get all statistics
    useEffect(() => {
        changeTitle(t(`overview`));
        // eslint-disable-next-line
    }, [i18n.language])

    return (
        <React.Fragment>
            {(overview?.users_count || overview?.videos_count || overview?.contact_messages_count) &&
                <div className="row  mb-5 d-flex justify-content-center">
                    <RadialChart
                        labels={[t('app-users'), t('videos'), t('contact-msgs')]}
                        series={[overview?.users_count ?? 1, overview?.videos_count ?? 1, overview?.contact_messages_count ?? 1]}
                    />
                </div>}
            <section className={`home_content  `}>
                <div className="overviewWrap text-center flex-wrap mx-0">
                    <div className="cardaia ">
                        <div className={`counter_box active_ads d-flex flex-column`}>
                            <div className={`icon_background`}>
                                <img src={user} alt="" />
                            </div>
                            <div className={`dash_home_counter`}>
                                <p>{overview?.users_count}</p>
                                <small>{t("app-users")}</small>
                            </div>
                        </div>
                    </div>
                    <div className="cardaia ">
                        <div className={`counter_box show_rooms d-flex flex-column`}>
                            <div className={`icon_background`}>
                                <img src={vdio} alt="" />
                            </div>
                            <div className={`dash_home_counter`}>
                                <p>{overview?.videos_count}</p>
                                <small>{t("videos")}</small>
                            </div>
                        </div>
                    </div>
                    <div className="cardaia ">
                        <div className={`counter_box users d-flex flex-column`}>

                            <div className={`icon_background`}>
                                <img src={msg} alt="" />
                            </div>
                            <div className={`dash_home_counter`}>
                                <p>{overview?.contact_messages_count}</p>
                                <small>{t("contact-msgs")} </small>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
