import React, { useContext, useEffect, useState } from 'react'
import '../profile.scss'
import { t } from 'i18next'
import { useParams } from 'react-router'
import Videos from '../../Components/Videos/Videos'
import TitleContext from '../../Contexts/TitleContext'
import { blockFan, getFan, getFanOverview, unblockFan } from '../../Apis/fans'
import InfoCard from '../../Components/InfoCard/InfoCard'
import OverviewCard from '../../Components/OverviewCard/OverviewCard'
import BlockModal from '../../Utilities/block'

export default function TrainersDetails() {
    const { changeTitle } = useContext(TitleContext)
    const { id } = useParams()
    const [fan, setFan] = useState()
    const [overview, setOverview] = useState()
    const [loader, setLoader] = useState()
    //const { Permissions } = useContext(PermissionsContext);

    useEffect(() => {
        getData();
        getOverview()
        changeTitle(t('fan-details'));
        // eslint-disable-next-line
    }, [])

    async function getData() {
        await getFan(id).then((res) => {
            setFan(res.data.data);
        })
    }

    async function getOverview() {
        await getFanOverview(id).then((res) => {
            setOverview(res.data.data);
            console.log(res.data.data)
        })
    }

    
    /* unblock */
    function unblock() {
        setLoader(true)
        unblockFan(id).then(() => {
            getData();
            setLoader(false)
        }).catch((err) => {
            console.log(err.response);
            setLoader(false)
        })
    }

    async function onSubmitBlock() {
        setLoader(true)
        return await blockFan(id).then(res => {
            getData();
            setLoader(false)
            return "done";
        }).catch(err => {
            setLoader(false)
            return err.response.data.errors;
        })
    }


    return (
        <React.Fragment>
            {fan && <section className={`profile pt-0 mt-4`}>

                <InfoCard user={fan} blockBtn={true} loader={loader} unblock={unblock}/>
                <OverviewCard overview={overview} />

                <div className='mb-5'>
                    <div className='filterAtProfile'>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                            <div className={`tablinks active`} id={`videos-tab`} data-bs-toggle="tab" data-bs-target={`#videos`} aria-controls="videos" >
                                {t("videos")}
                            </div>
                        </div>
                    </div>

                    <div className='tab-content' id="pills-tabContent">
                        <div className={`tab-pane fade m-0 p-0 active  show`} id="videos" role="tabpanel" aria-labelledby="videos">
                            <Videos type={fan} />
                        </div>
                    </div>

                </div>

            </section>}
        
            <BlockModal onSubmitBlock={onSubmitBlock} name='fan' ></BlockModal>


        </React.Fragment>
    )
}
