import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllPlayerFoots(){
    return await https.get(`${api_url}admin/player-footnesses`);
}

//get PlayerFoots by id
export async function getPlayerFoots(id){
    return await https.get(`${api_url}admin/player-footnesses/${id}`);
}

// create PlayerFoots
export async function createPlayerFoots(data){
    return await https.post(`${api_url}admin/player-footnesses`,data);
}

// update PlayerFoots
export async function updatePlayerFoots(id,data){
    return await https.patch(`${api_url}admin/player-footnesses/${id}`,data);
}

// delete PlayerFoots
export async function deletePlayerFoots(id){
    return await https.delete(`${api_url}admin/player-footnesses/${id}`);
}
