import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { supportedLanguages } from "../../../Utilities/SupportedLanguages";
import { t } from "i18next";
import obj from "./Model";
import { setData } from "./FormHandler";
import "../../forms.scss";
import { getAllTags } from "../../../Apis/tags";
import { preventEnter } from "../../../Utilities/preventEnter";
import { basename } from "../../../Configuration/config";
import { getArticle } from "../../../Apis/articles";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import parse from 'html-react-parser';
import Media from "../../../Components/MediaComponent/Media";

export default function Form(props) {
  const { id } = useParams();
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const [model, setModel] = useState(obj);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [stateImg, setStateImg] = useState();
  const [oldImg, setOldImg] = useState({});
  const [type, setType] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    console.log(" The last should appears", model);
  }, [model]);

  useEffect(() => {
    getAllTags().then((res) => {
      let options = [];
      for (let i = 0; i < res.data.data.length; i++) {
        options.push({
          value: res.data.data[i]?.id,
          label: res.data.data[i]?.name,
        });
      }
      setTags(options);
    });
  }, []);

  useEffect(() => {
    if (id) {
      ArticleData()
    } else {
      setModel({
        locales: {},
        tags: [],
        media: "",
      });
    }

    // eslint-disable-next-line
  }, [id]);

  const ArticleData = async () => {
    const res = await getArticle(id)
    console.log(res.data.data);
    let x = res.data.data;
    setModel(res.data.data);
    setStateImg(x?.media);
    setOldImg(x?.media);
    const fileExtension = x?.media?.url?.split('.')?.pop()?.toLowerCase();
    if (fileExtension === 'mp4' || fileExtension === 'avi' || fileExtension === 'mov' || fileExtension === 'webm') {
      setType("video")
    }
    setModel({
      ...res.data.data,
      locales: {
        en: { description: (parse(x?.locales?.en?.description)), delete: true },
        ar: { description: (parse(x?.locales?.ar?.description)), delete: true },
      }
    });
    const contentDataStateAr = ContentState.createFromBlockArray(htmlToDraft(`${x.locales?.ar?.description}`));
    const editorDataStateAr = EditorState.createWithContent(contentDataStateAr);
    setEditorStateAr(editorDataStateAr);
    const contentDataStateEn = ContentState.createFromBlockArray(htmlToDraft(`${x.locales?.en?.description}`));
    const editorDataStateEn = EditorState.createWithContent(contentDataStateEn);
    setEditorStateEn(editorDataStateEn);
  }
  console.log(stateImg)

  /* HANDLE MULTI SELECT */
  function handleMultiSelect(e, name) {
    let arr = [];
    for (let i = 0; i < e.length; i++) {
      arr.push(e[i].value);
    }
    let thisModel = Object.assign({}, model);
    thisModel[name] = arr;
    setModel(thisModel);
    let err = { ...errors };
    err[`tags.0`] = "";
    err.error[`tags.0`][0] = "";
    console.log(err)
    setErrors(err);
  }



  /******************EDITORS********************/
  let arabicbodyRef = useRef();
  let englishbodyRef = useRef();

  let [editorStateAr, setEditorStateAr] = useState(() => EditorState.createEmpty());
  let [editorStateEn, setEditorStateEn] = useState(() => EditorState.createEmpty());

  const convertArContentToHTML = () => {
    if (!editorStateAr) return ""; // Add a null check for editorStateAr
    const currentContentAsHTML = draftToHtml(convertToRaw(editorStateAr.getCurrentContent()));
    setModel({
      ...model,
      locales: {
        ...model?.locales,
        ar: {
          ...model?.locales?.ar,
          delete: null,
          description: currentContentAsHTML
        }
      }
    });
  };
  const convertEnContentToHTML = () => {
    if (!editorStateEn) return ""; // Add a null check for editorStateAr
    const currentContentAsHTML = draftToHtml(convertToRaw(editorStateEn.getCurrentContent()));
    setModel({
      ...model,
      locales: {
        ...model?.locales,
        en: {
          ...model?.locales?.en,
          delete: null,
          description: currentContentAsHTML
        }
      }
    });
  };

  const handleArEditorChange = (state) => {
    setEditorStateAr(state);
    convertArContentToHTML();
  }
  const handleEnEditorChange = (state) => {
    setEditorStateEn(state);
    convertEnContentToHTML();
  }


  /**************************** form onChange function *********************************/
  const setFormData = async (e, property, locale = false, language = null) => {
    setData(model, setModel, e, property, locale, language);
    let err = { ...errors };
    err[property] = "";
    setErrors(err);
  };

  async function confirm(e) {
    e.preventDefault();
    setLoading(true);
    console.log("model1,", model)
    if (model?.locales?.ar?.delete) {
      delete model?.locales?.ar?.description;
      delete model?.locales?.ar?.delete;
    }
    if (model?.locales?.en?.delete) {
      delete model?.locales?.en?.description;
      delete model?.locales?.ar?.delete;
    }
    if (oldImg.id === stateImg.id) {
      delete model.media;
      console.log("media should be deleted,", model);
    }

    delete model?.locales?.ar?.delete;
    delete model?.locales?.en?.delete;


    let submitProps = await props.onSubmit(e, model);
    if (submitProps === "done") {
      setLoading(false);
      navigate(`${basename}/dashboard/articles?status=active`);
      if (!id) {
        setModel({});
      }
    } else {
      console.log("model,2", model)
      console.log(submitProps);
      setLoading(false);
      setErrors(submitProps);
    }
  }


  return (
    <section className="componentGlobalWrapper">
      <form id="adminForm" onSubmit={(e) => confirm(e, model)} onKeyDown={preventEnter}>
        <div className="d-flex justify-content-start mb-5">
          <div className="w-100 ">
            <div className="d-flex flex-column">
              {supportedLanguages.map((language) => {
                return (
                  <div key={language?.slug} className="mb-4 w-75" >
                    <div className="d-flex flex-column">
                      <label htmlFor={`name_${language.slug}`} className="formLabel" >
                        {t("title")} ({t(`${language?.name}`)})
                      </label>
                      <input name="name" id={`name_${language?.slug}`}
                        type="text" placeholder={t("title") + " " + t(`${language?.name}`)}
                        value={model?.locales ? model?.locales[language?.slug]?.name : ""}
                        onChange={(e) => setFormData(e, "name", true, language.slug)}
                        minLength={3} maxLength={150} required />
                    </div>
                    <div className="text-danger validateToDelete">
                      {errors[`locales.${language?.slug}.name`]}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mb-4 mt-2 w-75">
              <label className="form-label labels mb-0" >  {t("description")} ({t(`${"english"}`)})<span className='languages'> &#9733;</span></label>
              <div ref={englishbodyRef} className="w-100 textEditorWrapper ">
                <Editor
                  className='editor'
                  toolbarClassName="editor"
                  editorState={editorStateEn}
                  onEditorStateChange={handleEnEditorChange}
                  textAlignment="left"
                />
              </div>
            </div>
            <div className="mb-4 mt-2 w-75">
              <label className="form-label labels mb-0" >  {t("description")} ({t(`${"arabic"}`)})<span className='languages'> &#9733;</span></label>
              <div ref={arabicbodyRef} className="w-100 textEditorWrapper">
                <Editor
                  className='editor border border-0'
                  editorState={editorStateAr}
                  onEditorStateChange={handleArEditorChange}
                  textAlignment="right"
                  toolbarClassName="editor"
                />
              </div>
            </div>


            <div className="mb-4 w-50">
              <div className="d-flex flex-column">
                <label htmlFor={`tag`} className="formLabel">
                  {t("tags")}
                </label>
                <Select
                  options={tags}
                  className="tagInput"
                  value={tags?.filter((option) => (model?.tags?.includes(option.value)))}
                  classNamePrefix={`formSelect`}
                  name="section"
                  isMulti
                  isSearchable={true}
                  placeholder={`${t("please enter")} ${t("tags")}`}
                  onChange={(e) => handleMultiSelect(e, "tags")}
                />
              </div>
              <div className="text-danger validateToDelete">
                {errors?.error?.[`tags.0`]?.[0]}
              </div>
            </div>
            <Media errors={errors} setErrors={setErrors} stateImg={stateImg} setStateImg={setStateImg} model={model} setModel={setModel} mediaType={type} article={true} setMediaType={setType} />
          </div>
        </div>



        {!loading ? (
          <button className="confirmBtn" type="submit">
            {id ? t(`save-changes`) : t(`create`)}
          </button>
        ) : (
          <button className="confirmBtn" disabled>
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
            {t("saving")}...
          </button>
        )}
      </form>
    </section>
  );
}
