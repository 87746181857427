import React from 'react'
import Form from './Form';
import { createClubPlayer } from '../../../Apis/clubs';

export default function CreatePlayer(props) {
  const onSubmit = async (e, data) => {
    e.preventDefault();
    console.log(data);
    //to send request
    return await createClubPlayer(data).then(res => {
      console.log(res.data)
      props.getNewData(props.obj)
      return 'done';          
    }).catch(err =>{
      console.log(err.response) 
      return err.response.data.errors; })
}

  return (
      <div className="modal-dialog modal-dialog-centered">
          
                <Form onSubmit={onSubmit} id='create' clubId={props.clubId} />
              
        </div>
  )
}
