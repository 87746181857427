import { https } from './https';
import { api_url } from '../Configuration/config';

export async function getAllFederations(obj){
    return await https.get(`${api_url}admin/federations?${obj}`);
}

//get federations by id
export async function getFederation(id){
    return await https.get(`${api_url}admin/federations/${id}`);
}

//get getFederationOverview by id
export async function getFederationOverview(id){
    return await https.get(`${api_url}admin/federations/${id}/overview`);
}

//Federation Presedent by id
export async function getFederationPresident(id){
    return await https.get(`${api_url}admin/federations/${id}/president`);
}

export async function createFederationPresident(id,data){
    return await https.post(`${api_url}admin/federations/${id}/president`,data);
}

export async function updateFederationPresident(id,data){
    return await https.post(`${api_url}admin/federations/${id}/president`,data);
}
// toggle archive federations
export async function archiveFederation(id){
    return await https.post(`${api_url}admin/federations/${id}/archive`);
}

// toggle activate federations
export async function activateFederation(id){
    return await https.post(`${api_url}admin/federations/${id}/activate`);
}

// create Federation
export async function createFederation(data){
    return await https.post(`${api_url}admin/federations`,data);
}

// toggle update federations
export async function updateFederation(id,data){
    return await https.post(`${api_url}admin/federations/${id}`,data);
}






