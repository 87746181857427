import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllSpamsSections(obj=''){
    return await https.get(`${api_url}admin/spam-sections?${obj}`);
}

export async function getAllSpams(obj=''){
    return await https.get(`${api_url}admin/spams?${obj}`);
}

//get spams by id
export async function getSpam(id){
    return await https.get(`${api_url}admin/spams/${id}`);
}

// create spams
export async function createSpam(data){
    return await https.post(`${api_url}admin/spams`,data);
}

// update spams
export async function updateSpam(id,data){
    return await https.patch(`${api_url}admin/spams/${id}`,data);
}

// delete spams
export async function deleteSpam(id){
    return await https.delete(`${api_url}admin/spams/${id}`);
}
