import React from 'react'
import { t } from 'i18next'
function OverviewCard({ overview }) {
    console.log(overview)
    return (
        <div className={`row row-cols-2 row-cols-md-4 m-0 p-0 mb-2 reportsContainer justify-content-center`}>
            {(overview?.followers_count !== null && overview?.followers_count !== undefined) &&
                <div className="col m-0 mt-3">
                    <div className={`adsReportContainer bg1`}>
                        <div className={`numbers mt-0 pt-0 mb-4`}>
                            <p className="mt-0 pt-0">{overview?.followers_count}</p>
                        </div>
                        <div className='description'>
                            <p>{t('followers')}</p>
                        </div>
                    </div>
                </div>}
            {(overview?.followings_count !== null && overview?.followings_count !== undefined) &&
                <div className="col m-0 mt-3">
                    <div className={`adsReportContainer bg2`}>
                        <div className={`numbers mt-0 pt-0 mb-4`}>
                            <p className="mt-0 pt-0">{overview?.followings_count}</p>
                        </div>
                        <div className='description'>
                            <p>{t('followings')}</p>
                        </div>
                    </div>
                </div>}
            {(overview?.videos_count !== null && overview?.videos_count !== undefined) &&
                <div className="col m-0 mt-3">
                    <div className="adsReportContainer bg3">
                        <div className="numbers mt-0 pt-0 mb-4">
                            <p className="mt-0 pt-0">{overview?.videos_count}</p>
                        </div>
                        <div className="description">
                            <p>{t("videos")}</p>
                        </div>
                    </div>
                </div>}
            {(overview?.tagged_videos_count !== null && overview?.tagged_videos_count !== undefined) &&
                <div className="col m-0 mt-3">
                    <div className="adsReportContainer bg4">
                        <div className="numbers mt-0 pt-0 mb-4">
                            <p className="mt-0 pt-0">{overview?.tagged_videos_count}</p>
                        </div>
                        <div className="description">
                            <p>{t("taged-videos")}</p>
                        </div>
                    </div>
                </div>}
            {(overview?.compatitions_count !== null && overview?.compatitions_count !== undefined) &&
                <div className="col m-0 mt-3">
                    <div className={`adsReportContainer bg4`}>
                        <div className={`numbers mt-0 pt-0 mb-4`}>
                            <p className="mt-0 pt-0">{overview?.compatitions_count}</p>
                        </div>
                        <div className='description'>
                            <p>{t('competitions')}</p>
                        </div>
                    </div>
                </div>}
            {(overview?.courses_count !== null && overview?.courses_count !== undefined) &&
                <div className="col m-0 mt-3">
                    <div className={`adsReportContainer bg4`}>
                        <div className={`numbers mt-0 pt-0 mb-4`}>
                            <p className="mt-0 pt-0">{overview?.courses_count}</p>
                        </div>
                        <div className='description'>
                            <p>{t('courses')}</p>
                        </div>
                    </div>
                </div>}
            {(overview?.players_count !== null && overview?.players_count !== undefined) &&
                <div className="col m-0 mt-3">
                    <div className={`adsReportContainer bg4`}>
                        <div className={`numbers mt-0 pt-0 mb-4`}>
                            <p className="mt-0 pt-0">{overview?.players_count}</p>
                        </div>
                        <div className='description'>
                            <p>{t('players')}</p>
                        </div>
                    </div>
                </div>}
        </div >
    )
}

export default OverviewCard