import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { api_url } from '../../Configuration/config';
import { t } from 'i18next';

export default function ResetPassword() {
    let [showForgotPassField , setshowForgotPassField] = useState(true);
    let [showPincode , setshowPincode] = useState(false);
    let [showNewpass , setShowNewpass] = useState(false);
    let [successMsg , setSuccessMsg] = useState(false);

    const [email, setEmail] = useState("");
    const [pincode, setPincode] = useState("");
    const [token, setToken] = useState("");

    const [loading, setLoading] = useState(false);
    const [invalidMessage, setinvalidMessage] = useState('');
    const [resetAdminData, setresetAdminData] = useState({email : email, pin_code: pincode, password: '', password_confirmation: '' });
    const [errors, setErros] = useState({ password:[] });


    //forget password
    function forgetAdminPassword(e) {
        e.preventDefault();
        setLoading(true);
        if (email) {
            axios({
                url: `${api_url}admin/auth/forget_password/code`,
                data: { email: email },
                method: 'post',
                headers: {
                    lang: localStorage.getItem('i18nextLng')
                }
            })
            .then((res) => {
                console.log(res)
                setinvalidMessage('');
                setLoading(false);
                setshowPincode(true);
                setshowForgotPassField(false)                
            })
            .catch((err) => {
                console.log(err)
                setinvalidMessage(err.response.data.message);
                setErros(err.response.data.errors);
                setLoading(false);
            })
        }
    }

    //pincode submit
    function submitPinCode(e){
        e.preventDefault();
        setLoading(true);
        console.log(pincode);
        console.log(email);
        if (pincode) {
            axios({
                url: `${api_url}admin/auth/forget_password/check`,
                data: { pin_code: pincode, email: email},
                method: 'post',
                headers: {
                    lang: localStorage.getItem('i18nextLng')
                }
            })
            .then((res) => {
                setinvalidMessage('');
                setLoading(false);
                setShowNewpass(true);
                setshowPincode(false); 
                setToken(res.data.data.token);          
            })
            .catch((res) => {
                console.log(res);
                setinvalidMessage(res.response.data.errors.message ??res.response.data.message  );
                setLoading(false);
            })
        }

    }

    // reset password handler
    function resetAdminDataHandler(e) {
        let reset = { ...resetAdminData };
        reset[e.target.name] = e.target.value;
        setresetAdminData(reset);
    }


    // to submit password
    function passwordConfirmation(e){
        e.preventDefault();
        setLoading(true);
        console.log(resetAdminData)
        if (resetAdminData.password && resetAdminData.password_confirmation && token) {
            axios({
                url: `${api_url}admin/auth/forget_password/reset_password`,
                data: { token:token ,password:resetAdminData.password, password_confirmation:resetAdminData.password_confirmation} ,
                method: 'post',
                headers: {
                    lang: localStorage.getItem('i18nextLng')
                }
            })
            .then((res) => {
                setLoading(false);
                setShowNewpass(false);
                setSuccessMsg(true);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response.data);
                setErros(err.response.data.errors);
                /* console.log(err) */
                /* setErros({ ...errors, passwordErr: 'Password not matched' }); */
            })
        
        } 
    }


    return ( 
        <>
        
            {/** forgot password form **/}               
            { showForgotPassField && 
                <form className='w-100' onSubmit={forgetAdminPassword}>
                    <p>{t('enter-the-email-associated-with-your-account-to-recieve-the-code-to-reset-your-password')}.</p>
                    <div className='d-flex flex-column'>
                        <label htmlFor='email' className='formLabel'>{t('email')}</label>
                        <input onChange={(e) => setEmail(e.target.value.trim() ? e.target.value.trim() : '')} name="email" className='formInput' id='email' type='email' placeholder={t('email')} required/>
                        {errors.email && <div className='validateToDelete text-danger'>{errors.email}</div>}
                    </div>
                    <div className='d-grid mt-5'>
                            {!loading ? 
                                <button type='submit' className='defaultBtn'>{t('next')}</button>
                                :
                                <button className='defaultBtn' disabled>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    {t('loading')}...
                                </button>
                            }
                    </div>
                    <div className='d-flex justify-content-center'>
                        <NavLink to={`${process.env.REACT_APP_BASENAME}/`} className="backLogin">{t('go-back-to-login')}</NavLink>
                    </div>
                </form>
            }

            {/** Pin code form **/}
            { showPincode && 
                <form className='w-100' onSubmit={submitPinCode}>
                    <div className='d-flex flex-column'>
                        <label htmlFor='username' className='formLabel'>{t('enter-code')}</label>
                        <input onChange={(e) => setPincode(e.target.value.trim() ? e.target.value.trim() : '')} name="email" className='formInput' id='username' type='text' placeholder={t('code')}  required/>
                    </div>
                    <div className='validateToDelete text-danger'>{invalidMessage}</div>
                    <div className='d-grid mt-5'>
                        {!loading ? 
                            <button type='submit' className='defaultBtn'>{t('continue')}</button>
                            :
                            <button className='defaultBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                {t('loading')}...
                            </button>
                        }
                    </div>
                    <div className='d-flex justify-content-center'>
                        <NavLink to={`${process.env.REACT_APP_BASENAME}/`} className="backLogin">{t('go-back-to-login')}</NavLink>
                    </div>
                </form>
            }
            
            
            
            {/** confirm new pass form **/}
            { showNewpass && 
                <form className='w-100' onSubmit={passwordConfirmation}>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='newpassword' className='formLabel'>{t('new-password')}</label>
                        <input onChange={resetAdminDataHandler} name="password" className='formInput' id='newpassword' type='text' placeholder={t('new-password')} />
                    </div>
                    <div className='d-flex flex-column'>
                        <label htmlFor='confirmpassword' className='formLabel'>{t('confirm-password')}</label>
                        <input onChange={resetAdminDataHandler} name="password_confirmation" className='formInput' id='confirmpassword' type='text' placeholder={t('confirm-password')}/>
                    </div>
                    <div className='validateToDelete text-danger'>
                        {(errors && errors.password?.length) ? 
                        errors.password.map((err,i)=>(
                            <div key={i}>
                                {errors.password[i]}
                            </div>
                        )):''}
                    </div>
                    <div className='d-grid mt-5'>
                        {!loading ? 
                            <button type='submit' className='defaultBtn' >{t('reset-password')}</button>
                            :
                            <button className='defaultBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                {t('loading')}...
                            </button>
                        }
                    </div>

                    <div className='d-flex justify-content-center'>
                        <NavLink to={`${process.env.REACT_APP_BASENAME}/`} className="backLogin">{t('go-back-to-login')}</NavLink>
                    </div>
                </form>
            }
        
        
        
            {/** success message **/}               
            { successMsg && 
                <form className='w-100' onSubmit={forgetAdminPassword}>
                    <h5>{t('password-reset-successfully')}!</h5>
                    <p>{t('you-can-now-use-your-new-password-to-login-to-your-account')}.</p>
                    <div className='d-grid mt-5'>                                     
                        <button type='submit' className='defaultBtn'>
                            <NavLink to={`${process.env.REACT_APP_BASENAME}/`} >{t('go-back-to-login')}</NavLink>
                        </button>                                          
                    </div>
                </form>
            }
         
        </>
    )
}
