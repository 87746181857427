import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { blockJournalist, getJournalist, getJournalistOverview, unblockJournalist } from '../../Apis/journalists'
import TitleContext from '../../Contexts/TitleContext'
import noImg from "../../Assets/Images/noImg.png"
import '../profile.scss'
import NotDataFound from '../../Components/NotDataFound/notDataFound'
import Videos from '../../Components/Videos/Videos'
import OverviewCard from '../../Components/OverviewCard/OverviewCard'
import InfoCard from '../../Components/InfoCard/InfoCard'
import BlockModal from '../../Utilities/block'

export default function TrainersDetails() {
    const { changeTitle } = useContext(TitleContext)
    const { id } = useParams()
    const [journalist, setJournalist] = useState()
    const [overview, setOverview] = useState()
    const [loader, setLoader] = useState()

    //const { Permissions } = useContext(PermissionsContext);
    async function getData() {
        await getJournalist(id).then((res) => {
            setJournalist(res.data.data);
            console.log(res.data.data)
        })
    }
    async function getOverview() {
        await getJournalistOverview(id).then((res) => {
            setOverview(res.data.data);
            console.log(res.data.data)
        })
    }

    useEffect(() => {
        getData();
        getOverview()
        changeTitle(t('journalist-details'));
        // eslint-disable-next-line
    }, [])

    /* unblock */
    function unblock() {
        setLoader(true)
        unblockJournalist(id).then(() => {
            getData();
            setLoader(false)
        }).catch((err) => {
            console.log(err.response);
            setLoader(false)
        })
    }

    async function onSubmitBlock() {
        setLoader(true)
        return await blockJournalist(id).then(res => {
            getData();
            setLoader(false)
            return "done";
        }).catch(err => {
            setLoader(false)
            return err.response.data.errors;
        })
    }


    return (
        <React.Fragment>
            {journalist && <section className={`profile pt-0 mt-4`}>

                <InfoCard user={journalist} blockBtn={true} loader={loader} unblock={unblock} />
                <OverviewCard overview={overview} />


                <div className='mb-5'>

                    <div className='filterAtProfile'>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">

                            <div className={`tablinks active`} id={`videos-tab`} data-bs-toggle="tab" data-bs-target={`#videos`} aria-controls="videos" >
                                {t("videos")}
                            </div>
                        </div>
                    </div>

                    <div className='tab-content' id="pills-tabContent">
                        <div className={`tab-pane fade m-0 p-0 active  show`} id="videos" role="tabpanel" aria-labelledby="videos">
                            <Videos type={journalist} />
                        </div>
                    </div>

                </div>
                <BlockModal onSubmitBlock={onSubmitBlock} name='journalist' ></BlockModal>

            </section>}



        </React.Fragment>
    )
}
