import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllPlayerPositions(){
    return await https.get(`${api_url}admin/player-positions`);
}

//get PlayerPositions by id
export async function getPlayerPosition(id){
    return await https.get(`${api_url}admin/player-positions/${id}`);
}

// create PlayerPositions
export async function createPlayerPositions(data){
    return await https.post(`${api_url}admin/player-positions`,data);
}

// update PlayerPositions
export async function updatePlayerPositions(id,data){
    return await https.patch(`${api_url}admin/player-positions/${id}`,data);
}

// delete PlayerPositions
export async function deletePlayerPositions(id){
    return await https.delete(`${api_url}admin/player-positions/${id}`);
}
