import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Avatar } from '@mui/material';
import NotDataFound from '../NotDataFound/notDataFound';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SubscribersModal({ open, handleClose, data }) {


    return (
        <div>
            <Dialog
                fullWidth={"lg"}
                maxWidth={"sm"}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Subscribers
                            </Typography>
                        </Toolbar>
                    </AppBar>
                {data ? 
                    <List>
                        {data?.map((sub) => (
                            <div>
                                <ListItem button>
                                    <Avatar alt={sub.username} src={sub.media.url} />
                                    <ListItemText className='p-3' primary={sub.username} />
                                </ListItem>
                                <Divider />
                            </div>
                        ))
                        }
                    </List>
                    :
                    <div className='w-100'>
                        <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
                    </div>
                }
            </Dialog>
        </div>
    );
}
