import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllTags(obj=''){
    return await https.get(`${api_url}admin/tags?${obj}`);
}

//get Tags by id
export async function getTag(id){
    return await https.get(`${api_url}admin/tags/${id}`);
}

// create Tags
export async function createTags(data){
    return await https.post(`${api_url}admin/tags`,data);
}

// update Tags
export async function updateTags(id,data){
    return await https.patch(`${api_url}admin/tags/${id}`,data);
}

// delete Tags
export async function deleteTags(id){
    return await https.delete(`${api_url}admin/tags/${id}`);
}
