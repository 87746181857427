import axios from 'axios'
import React, { useState } from 'react'
import { api_url } from '../../Configuration/config'
import removeIcon from "../../Assets/Icons/removeIcon.svg"
import uploadImage from "../../Assets/Icons/uploadimage.svg"
import { Tooltip } from '@mui/material'
import { t } from 'i18next'

function Media({ errors, setErrors, setStateImg, stateImg, setModel, model, mediaType, setMediaType,article }) {
    const [progress, setProgress] = useState(0)
    const token = `Bearer ${localStorage.getItem("Sports_Admin_Token")}`
    async function upload(e) {
        const formData = new FormData()
        formData.append("media[]", e.target.files[0])
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
            }
        }
        let err = { ...errors }
        err['media'] = ''
        setErrors(err)
        axios.post(`${api_url}admin/media`, formData, config).then((res) => {
            let obj = res.data.data
            console.log(obj[0].url)
            let type = e.target.files[0].type.startsWith('video') && e.target.files[0].type
            setStateImg({ ...obj[0], type })
            article && setMediaType( type )
            let m = Object.assign({}, model)
            m['media'] = obj[0]?.id
            setModel(m)
            console.log(m)
            setProgress(0)
        }).catch((err) => {
            console.log(err)
            let x = err?.response?.data?.errors?.media
            let e = { ...errors }
            e['media'] = x
            setErrors(e)
            setProgress(0)
        })
    }

    function removeImgHandler() {
        let m = Object.assign({}, model)
        m['media'] = null
        setModel(m)
        setStateImg('')
    }
    return (
        <div className="row m-0 mb-2 d-flex align-items-center mt-2" style={{ "width": "180px", "height": "140px" }}>
            <label className='formLabel p-0 mb-0'>{t('image')} </label>
            {stateImg ?
                <div className='globalImageWrapper position-relative p-1 mt-2'>
                    <Tooltip title={t('remove-image')}>
                        <label htmlFor='viewImage' className={`globalRemoveImage`}>
                            <img src={removeIcon} alt="delete" onClick={() => removeImgHandler()} />
                        </label>
                    </Tooltip>
                    {(stateImg?.type || mediaType) ? <video controls autoPlay className="globalPreviewedImage" src={stateImg.url}></video>
                        : <div className="globalPreviewedImage" style={{ backgroundImage: `url(${stateImg.webp})` }}></div>
                    }
                </div>
                :
                <div className='px-0'>
                    <div className='imageWrapper p-0 mt-2' >
                        <label htmlFor='propertyImg' className='h-100 w-100 d-flex flex-column justify-content-center align-items-center'>
                            <img width='70px' height='50px' src={uploadImage} alt='upload' />
                            <input onChange={upload} name="image" accept="image/*" type="file" className={`d-none`} id="propertyImg" />
                            <span> {t("upload-image")} </span>
                        </label>
                    </div>
                </div>}
            {progress !== 0 && (
                <div className="progress mt-2 p-0"  >
                    <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                        aria-valuenow={progress} aria-valuemin="-2" aria-valuemax="100" style={{ width: progress + "%" }}>
                        {progress}%
                    </div>
                </div>
            )}
            {errors?.media && <div className='text-danger validateToDelete'>
                {errors?.media}
            </div>}
        </div>
    )
}

export default Media