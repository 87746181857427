import React, { useEffect, useRef, useState } from 'react'
import formModel from './formModel';
import { getFederationPresident } from '../../../Apis/federations';
import { t } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { preventEnter } from '../../../Utilities/preventEnter';
import Tooltip from '@mui/material/Tooltip';
import removeIcon from "../../../Assets/Icons/removeIcon.svg";
import uploadImage from "../../../Assets/Icons/uploadimage.svg";
import axios from 'axios';
import { api_url } from '../../../Configuration/config';
import Media from '../../../Components/MediaComponent/Media';

export default function Form(props) {
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const id = props?.id;
    const [errors, setErrors] = useState({});
    const [imagePresident, setImagePresident] = useState('');
    const closeCreateModalRef = useRef();
    const [oldImage, setOldImage] = useState()

    useEffect(() => {
        setErrors({})
        if (id !== 'create') {
            console.log(props.data)
            setImagePresident(props.data?.media ?? null);
            setOldImage(props.data?.media)
            setModel({
                full_name: props.data.full_name,
                media: props.data?.media?.id ?? null
            });

        } else {
            setModel({
                full_name: '',
                media: ''
            })
            setImagePresident('');
        }
        // eslint-disable-next-line
    }, [props]);

    // onchange
    const setFormData = (e, property, locale = false, language = null) => {
        let newData = Object.assign({}, model)
        if (!locale) {
            newData[property] = e.target.value;
        }
        setModel(newData);
        let err = { ...errors };
        err = {};
        setErrors(err)
    }

    // onsubmit
    const confirm = async (e, model) => {
        e.preventDefault();
        setLoading(true);
        if (oldImage === imagePresident) {
            delete model.media
        }
        console.log(model)

        let submitProps = await props.onSubmit(e, model);
        console.log(submitProps);
        if (submitProps === "done") {
            setLoading(false);
            setModel({
                full_name: '',
                media: ''
            });
            setImagePresident('');
            closeCreateModalRef.current.click();
        } else {
            setLoading(false);
            setErrors(submitProps);
        }
    }

    function resetPlayerForm() {
        setModel({
            full_name: '',
            media: ''
        });
        setImagePresident('');
    }



    return (<div className="modal-content">
        <div className="modal-header m-0">
            <h5 className='pageSubtitle mb-0'>{t('the-federation-president')}</h5>
            <div ref={closeCreateModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={resetPlayerForm} >
                <FontAwesomeIcon icon={faXmark} />
            </div>
        </div>
        <div className="modal-body">
            <form id='adminForm' onSubmit={(e) => confirm(e, model)} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-start'>
                    <div className="w-100">
                        {/* FULL NAME */}
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor={`name`} className='formLabel'>{t('name')}</label>
                            <input name="full_name" id={`name`} type='text' placeholder={t('name')} value={model?.full_name} onChange={(e) => setFormData(e, 'full_name')} minLength={3} maxLength={30} required />
                            <div className='text-danger validateToDelete'>
                                {errors[`full_name`]}
                            </div>
                        </div>

                        <Media errors={errors} setErrors={setErrors} model={model} setModel={setModel} stateImg={imagePresident} setStateImg={setImagePresident} />

                    </div>

                </div>

                {!loading ?
                    <button className='confirmBtn' type='submit'>{id !== 'create' ? t(`save-changes`) : t(`add-president`)}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        {t('saving')}...
                    </button>
                }
            </form>
        </div>
    </div>
    )
}
