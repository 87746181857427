import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getStickers(obj) {
    return await https.get(`${api_url}admin/stickers?${obj}`);
}

export async function getSticker(id) {
    return await https.get(`${api_url}admin/stickers/${id}`);
}

export async function AddSticker(data) {
    return await https.post(`${api_url}admin/stickers`, data, {
        headers: {
            'Content-Type': 'multipart/form-data', 
        },
    });
}

export async function deleteSticker(id) {
    return await https.delete(`${api_url}admin/stickers/${id}`);
}