import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAdmins() {
    return await https.get(`${api_url}admin/admins`);
}
export async function getAdmin(id) {
    return await https.get(`${api_url}admin/admins/${id}`);
}

export async function updateAdmin(id, data) {
    return await https.patch(`${api_url}admin/admins/${id}`, data);
}

export async function createAdmin(data) {
    return await https.post(`${api_url}admin/admins`, data);
}

export async function deleteAdmin(id) {
    return await https.delete(`${api_url}admin/admins/${id}`);
}