import React, { useContext, useState, useEffect } from 'react'
import './Header.scss';
import TitleContext from '../../Contexts/TitleContext';
import { useLocation, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { faCog, faSignOutAlt, faUser,/* faEnvelopeOpen, */  faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAdminProfileInfo } from '../../Apis/profile';
import { basename } from '../../Configuration/config';
// import arrow from "../../Assets/Icons/ExpandArrow.svg";
// import notification from "../../Assets/Icons/notification.svg";
// import moment from 'moment';
// import langIcon from "../../Assets/Images/language.png";
import langIcon2 from "../../Assets/Icons/language2.svg";

import { supportedLanguages } from '../../Utilities/SupportedLanguages';
import { useTranslation } from 'react-i18next';
import Echo from 'laravel-echo';
import Pusher from "pusher-js";
// import Tooltip from '@mui/material/Tooltip';
// import { getAllNotifications } from '../../Apis/notification';
// import PermissionsContext from '../../Contexts/permissionsContext';

export default function Header() {
    // let { Permissions } = useContext(PermissionsContext);
    const { t, i18n } = useTranslation();
    let { Title, btn } = useContext(TitleContext);
    const navigate = useNavigate();
    const [profile, setProfile] = useState({});
    const [lang, setLang] = useState(i18n.language);
    const { pathname } = useLocation();
    // const [notifications, setNotifications] = useState([]);
    // const [notificationAlert, setNotificationAlert] = useState();
    // console.log(supportedLanguages);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: '6d8f91472b72feaa5158',
        cluster: 'eu',
    });

    // var channel = window.Echo.channel('send-notifications');
    // channel.listen('.send-notifications', function(data) {
    //     getNotifications();
    //     setNotificationAlert(true);
    // });


    useEffect(() => {
        getAdminInfo();
        // getNotifications();

        // window.Echo = new Echo({
        //     broadcaster: 'pusher',
        //     key: '6d8f91472b72feaa5158',
        //     cluster: 'eu',
        //     forceTLS: true
        // });

        // var channel = window.Echo.channel('send-notifications');
        // channel.listen('.admin-notifications', function(data) {
        //     getNotifications();
        //     setNotificationAlert(true);
        // });

    }, [])

    function getAdminInfo() {
        getAdminProfileInfo().then((res) => {
            setProfile(res.data.data);
        });
    }
    function logoutHandler() {
        localStorage.removeItem("Tourism_Admin_Token");
        navigate(`/${basename}`);
    }
    function goToProfile() {
        navigate(`${basename}/dashboard/settings/account`);
    }
    function goToSettings() {
        navigate(`${basename}/dashboard/settings/security`);
    }
    function changeLanguages(lang) {
        setLang(lang)
        if (lang === 'ar') {
            i18n.changeLanguage('ar')
            localStorage.setItem('i18nextSportsLng', 'ar');
            window.location.reload();
        } else if (lang === 'en') {
            i18n.changeLanguage('en')
            localStorage.setItem('i18nextSportsLng', 'en')
            window.location.reload();
        }
    }

    /* notification */
    // function getNotifications(){
    //     getAllNotifications('per_page=8').then( res =>{
    //         setNotifications(res.data.data);
    //         /* setObj(res.data.data); */
    //     })
    // }
    // function clickNotificationBtn(){
    //     setNotificationAlert(false);
    // }
    // function readNotificationHandler(no){
    //     navigate(`${basename}/dashboard/contact-us`);
    // }

    return (
        <div className='headerWraper'>
            <div className='d-flex align-items-center'>
                <h3 className='mb-0'>{`${t(Title)}`}</h3>
                {btn && btn?.canAdd && <NavLink className="headerBtn" to={btn.url}>{btn.text}</NavLink>}
            </div>
            <div className='d-flex justify-content-between align-items-center mt-3 mt-md-0 mb-3 mb-md-0'>
                {/* {Permissions?.notifications?.show && <div className='dropdown'>
                    <Tooltip title={t('notifications')}>
                        <button className='position-relative me-4 notification' data-bs-toggle="dropdown" onClick={clickNotificationBtn} >
                            <img src={notification} alt="notification" />
                            {notificationAlert && <span className="position-absolute notificationDot">
                            </span>}
                        </button>
                    </Tooltip>
                    <ul className="dropdown-menu dropdown-menu-end notificationDropdown" aria-labelledby="dropdownMenuButton1">
                        <li className='notificationHeader'>{t('notifications')}</li>
                        {notifications && notifications.map((no, index) => (
                            <div key={index} className={`linkNotification `}
                                onClick={() => readNotificationHandler(no)}>
                                <li className='align-items-start d-flex'>
                                    <span className="dropdownItem d-flex justify-content-between pe-2">
                                        <span className="icon marginEnd">
                                            <FontAwesomeIcon icon={faEnvelopeOpen} />
                                        </span>
                                    </span>
                                    <span className="dropdownItem w-100">
                                        <div className='d-flex flex-column'>
                                            <span className='user'>{no.type === 'contact_us' ? `${t(`contact-us`)}`
                                                : `Property Request`}</span>
                                            <span className='issue'>{no.message}</span>
                                        </div>
                                        <span className='hour'>
                                            {moment(no.created_at).format('DD MMM')}
                                        </span>
                                    </span>
                                </li>
                            </div>
                        ))}
                    </ul>
                </div>} */}

                <span className='userName'>{profile && profile.name}</span>
                {profile && <div className='dropdown'>
                    <div className='userImg' data-bs-toggle="dropdown" style={{ "cursor": "pointer" }}>
                        {profile?.name?.charAt(0)}
                    </div>
                    <ul className="dropdown-menu py-0 settingsDropdown">
                        <li onClick={goToProfile} className="py-0"><span className="dropdown-item"><FontAwesomeIcon className='iconProfileMenu' icon={faUser} />{t('profile')}</span></li>
                        <li onClick={goToSettings} className="py-0"><span className="dropdown-item"><FontAwesomeIcon icon={faCog} className='iconProfileMenu' />{t('settings')}</span></li>
                        <li onClick={logoutHandler} className="py-0"><span className="dropdown-item"><FontAwesomeIcon icon={faSignOutAlt} className='iconProfileMenu' />{t('logout')}</span></li>
                    </ul>
                </div>}

                {profile &&
                    <div className='dropdown'>
                        <div className='ms-auto langDropDown' data-bs-toggle="dropdown" style={{ "cursor": "pointer" }}>
                            <img src={langIcon2} style={{ marginInlineEnd: "2px" }} alt="language icon" />
                            <span>{lang === 'en' ? 'EN' : "العربية"}</span>
                            {/* <img src={arrow} alt="arrow"/> */}
                        </div>
                        <ul className="dropdown-menu py-0">
                            {supportedLanguages?.map((language, index) => (
                                <li key={index} className="py-0 languageName" id='langDropDown' onClick={() => changeLanguages(language.slug)}><span className="dropdown-item">{t(language.name)}</span></li>
                            ))}

                        </ul>
                    </div>}

                {/* <div className='dropdown dashboardLanguage'  >
                    <button className='position-relative ms-auto' data-bs-toggle="dropdown">
                        <img src={langIcon} alt="language icon"/>
                        <span>EN</span>
                        <img src={arrow} alt="arrow"/>
                    </button>
                    <div className="dropdown-menu custom-lang-dropdown">
                        <ul className="nav nav-items languageTabs d-flex flex-column">
                            {supportedLanguages.map((language, index)=>(
                                <li key={index} className='nav-item' >
                                    <span className={`${index === 0 && `active`} dropdownItem px-2 py-1 w-100`} id={`${language.name}Form`} data-bs-toggle="pill" data-bs-target={`#pills-${language.name}`} type="button" role="tab" aria-controls={`pills-${language.name}`} {...(index === 0 ? {'aria-selected': "true"} :{})}>
                                        {language.name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div> */}
                {/* <Tooltip title={t('sideBar-Toggler')}> */}
                <button className="btn sideBarToggler d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">  <FontAwesomeIcon icon={faBars} /></button>
                {/* </Tooltip> */}
            </div>
        </div>
    )
}
