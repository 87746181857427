import React from 'react';
import ReactPaginate from 'react-paginate';
import arrowRight from '../../Assets/Icons/arrowRight.svg';
import arrowLeft from '../../Assets/Icons/arrowLeft.svg';
import './pagination.css'
export default function Pagination({pagesNumber,pageChangeHandler,page=0}){
    return(
        <ReactPaginate
            previousLabel = { 
                <img src={arrowRight} alt='previous'/>
            }
            nextLabel = {
                <img src={arrowLeft} alt='after'/>
            }
            breakLabel = {'...'}
            forcePage = {Number(page)}
            pageCount = {pagesNumber}
            marginPagesDisplayed = {3}
            pageRangeDisplayed = {5}
            containerClassName = {'pagination justify-content-center'}
            onPageChange = {pageChangeHandler}
        /> 
    )
}
