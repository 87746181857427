import React, { useState, useEffect,/* useContext*/ } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import { t } from 'i18next';
import obj from './Model';
import { setData } from './FormHandler';
// import PermissionsContext from '../../../Contexts/permissionsContext';
import '../../forms.scss';
import { preventEnter } from '../../../Utilities/preventEnter';
import { basename, map_key } from '../../../Configuration/config';
import { getClub } from '../../../Apis/clubs';
import { GoogleMap, Marker, Autocomplete, LoadScript } from '@react-google-maps/api';
import { getAllCities } from '../../../Apis/cities';
import moment from 'moment/moment';
import currentLocation from '../../../Assets/Icons/currentLocation.svg'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { getAllCountries } from '../../../Apis/countries';
import Media from '../../../Components/MediaComponent/Media';
export default function Form(props) {

    // let { Permissions } = useContext(PermissionsContext);
    const { id } = useParams();
    const { pathname } = useLocation();
    let navigate = useNavigate()
    const [model, setModel] = useState(obj);
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [stateImg, setStateImg] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        getAllCountries().then(res => {
            setCountries(res.data.data)

        })

    }, [])

    useEffect(() => {
        if(model?.country) {
            getAllCities(`country=${model?.country}`).then((res) => {
                setCities(res.data.data)
            })
        }
    }, [model.country])

    useEffect(() => {
        if (id) {
            getClubData()
        } else {
            setModel({
                business_name: "",
                email: "",
                username: "",
                birthday: '',
                latitude: '',
                longitude: "",
                city: "",
                country: "",
                phone: "",
                password_confirmation: "",
                password: "",
                media: ""
            })
        }
        // eslint-disable-next-line
    }, [id]);

    const getClubData = async () => {
        const res = await getClub(id)
        let x = res.data.data;
        setStateImg(x?.media);
        setCoords({ lat: Number(x.latitude), lng: Number(x.longitude) })
        setModel(x);
    }
    /*************************  map ******************************/
    const [libraries] = useState(['places']);
    let [coords, setCoords] = useState({ lat: 21.492500, lng: 39.177570 });
    const [currentPosition, setCurrentPosition] = useState(null);
    function onMarkDragged(e) {
        let newCoords = { ...coords };
        newCoords = {
            lng: Number(e.latLng.lng()),
            lat: Number(e.latLng.lat())
        }
        setCoords(newCoords);
        let thisModel = { ...model };
        thisModel['latitude'] = Number(e.latLng.lat());
        thisModel['longitude'] = Number(e.latLng.lng());
        setModel(thisModel);
    }

    let [autocomplete, setAutocomplete] = useState(null);
    const containerStyle = { width: '100%', height: '400px' };

    function onPlaceChanged() {
        if (autocomplete !== null) {
            let newCoords = { ...coords };
            if (autocomplete.getPlace().geometry) {
                newCoords = {
                    lng: Number(autocomplete.getPlace().geometry.location.lng()),
                    lat: Number(autocomplete.getPlace().geometry.location.lat())
                }
            }
            setCoords(newCoords);
            let thisModel = { ...model };
            thisModel['latitude'] = Number(autocomplete.getPlace().geometry.location.lat());
            thisModel['longitude'] = Number(autocomplete.getPlace().geometry.location.lng());
            setModel(thisModel);
        }
    }
    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentPosition({ lat: latitude, lng: longitude });
                    let newCoords = {
                        lng: Number(latitude),
                        lat: Number(longitude)
                    }
                    setCoords(newCoords);
                    let thisModel = { ...model };
                    thisModel['latitude'] = Number(latitude);
                    thisModel['longitude'] = Number(longitude);
                    setModel(thisModel);
                    console.log(position)
                },
                (error) => {
                    console.error(error);
                }

            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
        console.log(currentPosition)
    };
    function onLoad(autocomplete) {
        setAutocomplete(autocomplete);
    }


    /**phone handler** */
    const phoneHandler = (e) => {
        setModel({
            ...model,
            phone: e
        })
        setErrors({ ...errors, phone: "" })
    }
    /**************************** form onChange function *********************************/
    const setFormData = async (e, property, locale = false, language = null) => {
        setData(model, setModel, e, property, locale, language);
        let err = { ...errors };
        err[property] = '';
        setErrors(err)
    }

    async function confirm(e) {
        e.preventDefault();
        setLoading(true);
        if (model?.password?.trim() === '' && model?.password_confirmation?.trim() === '') {
            delete model.password;
            delete model.password_confirmation;
        }
        let submitProps = await props.onSubmit(e, model);
        if (submitProps === "done") {
            setLoading(false);
            navigate(`${basename}/dashboard/app-users/clubs?status=active`);
            if (!id) {
                setModel({});
            }
        } else {
            console.log(submitProps);
            setLoading(false);
            setErrors(submitProps.error);
        }
    }


    return (
        <section className='componentGlobalWrapper'>
            <form id='adminForm' onSubmit={(e) => confirm(e, model)} onKeyDown={preventEnter}>
                <div className='formWidth mb-5'>
                    <div className='d-flex mb-4 w-100'>
                        <div className='d-flex flex-column w-100 marginEnd'>
                            <label htmlFor={`business_name`} className='formLabel'>{t('name')}</label>
                            <input name="business_name" id={`business_name`} type='text' placeholder={t('club-name')} value={model?.business_name} onChange={(e) => setFormData(e, 'business_name')} minLength={3} maxLength={25} required />
                            <div className='text-danger validateToDelete'>
                                {errors[`business_name`]}
                            </div>
                        </div>
                        <div className='d-flex flex-column w-100 marginStart'>
                            <label htmlFor={`email`} className='formLabel'>{t('email')}</label>
                            <input name="name" id={`email`} type='text' placeholder={t('email')} value={model?.email} onChange={(e) => setFormData(e, 'email')} minLength={3} maxLength={100} required />
                            <div className='text-danger validateToDelete'>
                                {errors[`email`]}
                            </div>
                        </div>
                    </div>

                    <div className='d-flex mb-4 w-100'>
                        <div className='d-flex flex-column w-100 marginEnd'>
                            <label htmlFor={`username`} className='formLabel'>{t('username')}</label>
                            <input name="username" id={`username`} type='text' placeholder={t('club-name')} value={model?.username} onChange={(e) => setFormData(e, 'username')} minLength={3} maxLength={25} required />
                            <div className='text-danger validateToDelete'>
                                {errors[`username`]}
                            </div>
                        </div>
                        <div className='d-flex flex-column w-100 marginStart'>
                            <label htmlFor={`phone`} className='formLabel'>{t('phone')}</label>
                            <PhoneInput name="name" className="mt-2" required id={`phone`} country={'sa'} value={model?.phone} onChange={(e) => phoneHandler(e)} />
                            <div className='text-danger validateToDelete'>
                                {errors[`phone`]}
                            </div>
                        </div>
                    </div>

                    <div className='d-flex mb-5 w-100'>
                        <div className="d-flex flex-column marginEnd w-100">
                            <label htmlFor="date_range" className="formLabel"> {t('stablished-at')} </label>
                            <input id='birthday' name="birthday" max={moment(new Date()).format('yyyy-MM-DD')} type='date' value={model?.birthday} onChange={(e) => setFormData(e, 'birthday')} />
                            {errors?.birthday && <div className='text-danger validateToDelete'>
                                {errors?.birthday[0]}
                            </div>}
                        </div>
                        <div className='d-flex flex-column w-100 marginStart'>

                        </div>
                    </div>

                    <h5 className='pageSubtitle mb-4'>{t('security-info')}</h5>
                    <div className='d-flex mb-5 w-100'>
                        {/* password */}
                        <div className='d-flex flex-column w-100 marginEnd'>
                            <label htmlFor='password' className='formLabel'>{t('password')}</label>
                            <input onChange={(e) => setFormData(e, 'password')} maxLength='25' id='password' name="password" placeholder={t("password")} value={model?.password} />
                            {errors?.password && <div className='text-danger validateToDelete'>
                                {errors?.password[0]}
                            </div>}
                        </div>

                        {/* password_confirmation */}
                        <div className='d-flex flex-column  w-100 marginStart'>
                            <label htmlFor='password_confirmation' className='formLabel'>{t('password-confirmation')}</label>
                            <input onChange={(e) => setFormData(e, 'password_confirmation')} maxLength='25' id='password_confirmation' name="password_confirmation" placeholder={t('password-confirmation')} value={model?.password_confirmation} />
                            {errors?.password_confirmation && <div className='text-danger validateToDelete'>
                                {errors?.password_confirmation[0]}
                            </div>}
                        </div>
                    </div>

                    <h5 className='pageSubtitle mb-4'>{t('location-info')}</h5>
                    {/* location in map  */}
                    <div className='d-flex mb-4 w-100'>
                        {/* countries */}
                        <div className='d-flex flex-column justify-content-start w-100 marginEnd'>
                            <label htmlFor='country' className='formLabel mb-0'>{t('country')}</label>
                            <div className='selectParent mt-2'  >
                                {console.log("Country:::::", model?.country)}
                                <select value={model?.country} id='country' name='country' placeholder={t('country')} onChange={(e) => setFormData(e, 'country')}>
                                    <option value='' className='defaultOption' disabled>{t("country")}</option>
                                    {countries && countries.map((country, i) => {
                                        return <option key={i} value={country.slug}>{country.name}</option>
                                    })}
                                </select>
                            </div>
                            {errors?.country && <div className='text-danger validateToDelete'>
                                {errors?.country[0]}
                            </div>}
                        </div>
                        {/* city */}
                        <div className='d-flex flex-column justify-content-end w-100 marginEnd'>
                            <label htmlFor='city' className='formLabel mb-0'>{t('city')}</label>
                            <div className='selectParent mt-2'  >
                                <select value={model?.city} name='city' placeholder={t('city')} onChange={(e) => setFormData(e, 'city')}>
                                    <option value='' className='defaultOption' disabled>city</option>
                                    {cities && cities.map((city, i) => {
                                        return <option key={i} value={city.slug}>{city.name}</option>
                                    })}
                                </select>
                            </div>
                            {errors?.city && <div className='text-danger validateToDelete'>
                                {errors?.city[0]}
                            </div>}
                        </div>

                        <div className='d-flex flex-column justify-content-end w-100 marginStart'>

                        </div>

                    </div>
                    <Media errors={errors} setErrors={setErrors} setStateImg={setStateImg} stateImg={stateImg} model={model} setModel={setModel} />

                </div>
                <div className="mb-5">
                    <label className="form-label labels my-2">{t('set-location-on-map')}</label>

                    <LoadScript id="script-loader" googleMapsApiKey={map_key} libraries={libraries}>
                        <GoogleMap mapContainerStyle={containerStyle} center={currentPosition || coords} zoom={18} draggable={true}  >
                            <Marker position={currentPosition ?? coords} draggable={true} onDragEnd={onMarkDragged} />
                            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                <input type="text" placeholder="Your Location" className='inputMapStyle' />
                            </Autocomplete>
                            <div className='p-2 pt-3 border-0 position-absolute'
                                style={{ background: "#fff", top: "30%", right: "0px", padding: "20px", cursor: "pointer", scale: ".7", borderRadius: "3px", boxShadow: "rgba(99, 99, 99, 0.3) 0px 1px 10px 0px" }}>
                                <img className='fa-shake' src={currentLocation} onClick={handleGetCurrentLocation} alt='Current Location'
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Get Current Location"
                                    style={{ "--fa-animation-duration": "2.5s", width: "40px" }} />
                            </div>
                        </GoogleMap>
                    </LoadScript>
                    {errors?.latitude && <div className='text-danger validateToDelete'>
                        {errors?.latitude[0]}
                    </div>}
                    {errors?.longitude && <div className='text-danger validateToDelete'>
                        {errors?.longitude[0]}
                    </div>}
                </div>
                {!loading ?
                    <button className='confirmBtn' type='submit'>{id ? t(`save-changes`) : t(`create`)}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        {t('saving')}...
                    </button>
                }
            </form>
        </section>
    )
}
