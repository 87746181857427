import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllFans(obj){
    return await https.get(`${api_url}admin/fans?${obj}`);
}

//get fans by id
export async function getFan(id){
    return await https.get(`${api_url}admin/fans/${id}`);
}

//get getFanOverview by id
export async function getFanOverview(id){
    return await https.get(`${api_url}admin/fans/${id}/overview`);
}

// accept fans
export async function unblockFan(id){
    return await https.post(`${api_url}admin/fans/${id}/unblock`);
}

// block fans
export async function blockFan(id){
    return await https.post(`${api_url}admin/fans/${id}/block`);
}

