import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import { supportedLanguages } from '../../../Utilities/SupportedLanguages';
import { t } from 'i18next';
import obj from './Model';
import { setData } from './FormHandler';
import PermissionsContext from '../../../Contexts/permissionsContext';
import '../../forms.scss';
import { getSpam, getAllSpamsSections } from '../../../Apis/spams';
import { preventEnter } from '../../../Utilities/preventEnter';
import { basename } from '../../../Configuration/config';

export default function Form(props) {
    let { Permissions } = useContext(PermissionsContext);
    const { id } = useParams();
    const { pathname } = useLocation();
    let navigate = useNavigate()
    const [model, setModel] = useState(obj);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [sections, setSections] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    useEffect(() => {
        getAllSpamsSections().then((res) => {
            setSections(res.data.data);
            console.log(res.data.data);
        })
        if (id) {
            getSpam(id).then((res) => {
                let x = res.data.data
                setModel({ locales: x.locales, spam_section: x.spam_section });
                console.log(res.data.data)
            });
        } else {
            setModel({
                locales: {},
                spam_section: ""
            })
        }
        // eslint-disable-next-line
    }, [id]);

    /**************************** form onChange function *********************************/
    const setFormData = async (e, property, locale = false, language = null) => {
        setData(model, setModel, e, property, locale, language);
        let err = { ...errors };
        err[property] = '';
        setErrors(err)
    }

    async function confirm(e) {
        const formData = new FormData()
        formData.append('locales[en][name]', model.locales.en.description);
        formData.append('locales[ar][name]', model.locales.ar.description);
        formData.append('spam_section', model.spam_section);
        console.log(formData.keys)
        e.preventDefault();
        setLoading(true);
        console.log(model)
        let submitProps = await props.onSubmit(e, model);
        if (submitProps === "done") {
            setLoading(false);
            navigate(`${basename}/dashboard/spams`);
            if (!id) {
                setModel({});
            }
        } else {
            console.log(submitProps);
            setLoading(false);
            setErrors(submitProps);
        }
    }

    return (
        <section className='componentGlobalWrapper'>
            <form id='adminForm' onSubmit={(e) => confirm(e, model)} /* onKeyDown={preventEnter} */>
                <div className='d-flex justify-content-start mb-5'>
                    <div className="w-50">
                        <div className='d-flex flex-column'>
                            {supportedLanguages.map((language) => {
                                return (
                                    <div key={language.slug} className='mb-4'>
                                        <div className='d-flex flex-column'>
                                            <label htmlFor={`name_${language.slug}`} className='formLabel'>{t('name')} ({t(`${language.name}`)})</label>
                                            <input name="name" id={`name_${language.slug}`} type='text' placeholder={t('name')} value={model?.locales ? model?.locales[language.slug]?.name : " "} onChange={(e) => setFormData(e, 'name', true, language.slug)} minLength={3} maxLength={20} required />
                                        </div>
                                        <div className='text-danger validateToDelete'>
                                            {errors[`locales.${language.slug}.name`]}
                                        </div>
                                    </div>)
                            })}
                        </div>
                        <div>
                            <label htmlFor='spam_section' className='formLabel mb-0'>{t('section')}</label>
                            <div className='selectParent mt-2'  >
                                <select value={model?.spam_section} name='spam_section' placeholder={t('section')} onChange={(e) => setFormData(e, 'spam_section')}>
                                    <option value='' className='defaultOption' disabled>section</option>
                                    {sections && sections.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                            {errors?.spam_section && <div className='text-danger validateToDelete'>
                                {errors?.spam_section[0]}
                            </div>}
                        </div>
                    </div>
                </div>

                {!loading ?
                    <button className='confirmBtn' type='submit'>{id ? t(`save-changes`) : t(`create`)}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        {t('saving')}...
                    </button>
                }
            </form>
        </section>
    )
}
