import { t } from "i18next";
import React, { useState, useEffect, useContext } from "react";
import "../tables.scss";
import { useLocation } from "react-router";
import search from "../../Assets/Icons/search.svg";
import i18n from "../../i18n";
import "../forms.scss";
import Pagination from "../../Components/Pagination/Pagination";
import { dateLanguage } from "../../Utilities/dateLanguage";
import NotDataFound from "../../Components/NotDataFound/notDataFound";
import { getDeleteRequests, acceptDeleteRequest, toggleRead } from "../../Apis/delete.js";
import TitleContext from "../../Contexts/TitleContext";
import PermissionsContext from "../../Contexts/permissionsContext";
import { Tooltip } from "@mui/material";
import accept from "../../Assets/Icons/accept.svg";
import { Link } from "react-router-dom";
import { basename } from "../../Configuration/config";
import profile from "../../Assets/Icons/profile.svg";
import read from "../../Assets/Icons/read.svg";
import unread from "../../Assets/Icons/unread.svg";
import MessageModal from "../../Utilities/messageModal";

export default function DeleteRequests() {
  const { pathname } = useLocation();
  const [req, setReq] = useState();
  const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: "", });
  const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });
  const [pageCount, setPageCount] = useState("");
  const { changeTitle } = useContext(TitleContext);
  const { Permissions } = useContext(PermissionsContext)
  const [description, setDescription] = useState('')



  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    dateLanguage(i18n.language);
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    DeleteRequestsData(filterObj);
    changeTitle(t(`delete-requests`));
    // eslint-disable-next-line
  }, []);

  function DeleteRequestsData(obj) {
    let filter = { ...obj };
    let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}`;
    getDeleteRequests(params).then((res) => {
      console.log(res.data.data);
      setReq(res.data.data);
      let total = res?.data?.pagination?.total;
      setPageCount(Math.ceil(total / filter.per_page));
    });
  }

  function changeFilterObj(e) {
    let thisObj = { ...filterObj2 };
    thisObj[e.target.name] = e.target.value.trim();
    setfilterObj2(thisObj);
  }

  function getFiltered() {
    let thisObj = { ...filterObj };
    thisObj.page = 1;
    thisObj.per_page = filterObj2.per_page;
    thisObj.search = filterObj2.search;

    setfilterObj(thisObj);
    DeleteRequestsData(thisObj);
  }

  const handlepageChange = async (data) => {
    let filter = { ...filterObj };
    filter.page = data.selected + 1;
    setfilterObj(filter);
    DeleteRequestsData(filter);

    let thisObj2 = { ...filterObj2 };
    thisObj2.per_page = filter.per_page;
    thisObj2.search = filter.search;
    setfilterObj2(thisObj2);
  };

  /* accept */
  function acceptHandler(id) {
    acceptDeleteRequest(id).then(() => {
      DeleteRequestsData(filterObj);
    }).catch((err) => {
      console.log(err.response);
    })
  }

  function markRead(id, status) {
    if (status === 0) {
      toggleRead(id, "read").then(() => {
        DeleteRequestsData(filterObj)
      })
    } else {
      toggleRead(id, "unread").then(() => {
        DeleteRequestsData(filterObj)
      })

    }
  }

  return (
    <>
      {<section className='componentGlobalWrapper d-flex flex-column'>
        <div>
          <div className='searchActionsBar w-100'>
            <div className='searchActionsBar mt-0 mb-0'>
              <div className='searchInputWrapper'>
                <div>
                  <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                </div>
                <img src={search} alt='search-icon' className='barSearchIcon' />
              </div>
            </div>
            <div className='perPageContainer'>
              <label htmlFor='selectShow' className='perPageLabel'>
                {t('show')}
              </label>
              <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='15'>15</option>
                <option value='20'>20</option>
              </select>
            </div>
            <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
          </div>

          <div className='tableWrapper'>
            <table className="w-100 table table-striped border-0">
              <thead>
                <tr>
                  {/* <th>{t("image")}</th> */}
                  <th>{t("username")}</th>
                  <th>{t("type")}</th>
                  <th>{t("following")}</th>
                  <th>{t("followers")}</th>
                  {/* <th>{t("reason")}</th> */}
                  <th>{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {req ? (
                  req?.map((item, index) => (
                    <tr key={index}>
                      {/* <td className="sliderImg">
                          <img
                            className="w-75"
                            src={item?.cover ? item?.cover : noImg}
                            alt="slider"
                          />
                        </td> */}
                      <td className="desc">
                        <div className="descriptionTd">
                          {item?.full_name}
                        </div>
                      </td>
                      <td className="desc">
                        <div className="descriptionTd">
                          {item?.user_type}
                        </div>
                      </td>
                      <td className="desc">
                        <div className="descriptionTd">
                          {item?.followings_count}
                        </div>
                      </td>
                      <td className="desc">
                        <div className="descriptionTd">
                          {item?.followers_count}
                        </div>
                      </td>
                      {/* <td className="desc">
                          <div className="descriptionTd">
                            {item?.reason}
                          </div>
                        </td> */}

                      <td className="d-flex align-items-center">
                        <Tooltip title={t('profile')}>
                          <Link to={`${basename}/dashboard/app-users/${item?.user_type === "fan" ||
                            item?.user_type === "federation" ||
                            item?.user_type === "club" ||
                            item?.user_type === "player" ||
                            item?.user_type === "trainer" ||
                            item?.user_type === "influencer" ||
                            item?.user_type === "journalist" ? item?.user_type + "s"
                            : item?.user_type === "business" ? item?.user_type + "es" : "academies"
                            }/${item?.user_id}/details`}>
                            <img className='iconActions' src={profile} alt="profile" />
                          </Link>
                        </Tooltip>
                        <Tooltip title='read the reason'>
                          <div className='contactActions m-0' data-bs-toggle="modal" data-bs-target={`#readMsgModel`} onClick={() => { markRead(item.id, item.status); setDescription(item.reason) }} >
                            {item?.status === 0 ?
                              <img className='iconActions' src={read} alt="edit" />
                              :
                              <img className='iconActions' src={unread} alt="edit" />
                            }
                          </div>
                        </Tooltip>
                        {Permissions?.user['accept-reject'] &&
                          item?.id &&
                          <Tooltip title={t('accept-request')}>
                            <img  style={{cursor:'pointer'}}onClick={() => acceptHandler(item?.id)} className='iconActions' src={accept} alt="accept" />
                          </Tooltip>
                        }
                      </td>

                    </tr>
                  )
                  )
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <NotDataFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-auto">
          <Pagination
            pagesNumber={pageCount}
            page={filterObj.page - 1}
            pageChangeHandler={handlepageChange}
          />
        </div>
        <MessageModal description={description} />

      </section>
      }
    </>
  );
}
