import { t } from 'i18next'
import '../../Pages/profile.scss'
import { Tooltip } from '@mui/material'
import edit from "../../Assets/Icons/edit.svg"
import noImg from "../../Assets/Images/noImg.png"
import React, { useContext } from 'react'
import noImage from "../../Assets/Images/noImage.jpg"
import PermissionsContext from '../../Contexts/permissionsContext'

function InfoCard({ user, hasPresedent, presedent, unblock, loader, club, blockBtn }) {

    const { Permissions } = useContext(PermissionsContext)

    return (
        <div className={`d-flex flex-column infoContainer`}>
            <div className={`d-flex justify-content-center justify-content-lg-between flex-wrap align-items-center`}>
                <div className={`d-flex align-items-center justify-content-center flex-wrap`}>
                    <div className={`showroom_prof`} style={{ backgroundImage: `url(${user?.media ? user.media?.webp : noImg})` }}></div>
                    <div className={`showroom_name`}>
                        {user?.business_name ? <p className={`fullName text-center text-lg-start`}>{user?.business_name}</p> : <p className={`fullName`}>{user?.first_name + " " + user?.last_name}</p>}
                        <div className='d-flex flex-wrap justify-content-center align-items-center'>
                            <div>
                                <p className={`editedUserEmail`}><span className='itemInfo'>{t('username')} </span> : {user?.username}</p>
                                {user?.city && <p className={`editedUserEmail`}><span className='itemInfo'>{t('city')} </span> : {user?.city}</p>}
                                {user?.country && <p className={`editedUserEmail`}><span className='itemInfo'>{t('country')} </span> : {user?.country}</p>}
                                {user?.email && <p className={`editedUserEmail`}><span className='itemInfo'>{t('email')} </span> : {user?.email}</p>}
                                {user?.phone && <p className={`editedUserEmail`}><span className='itemInfo'>{t('phone')} </span> : {user?.phone}</p>}
                                {(hasPresedent === true || user.academy_level) ?
                                    <>
                                        {user?.stablished_at && <p className={`editedUserEmail`}><span className='itemInfo'>{t('stablished-at')} </span> : {user?.stablished_at}</p>}
                                    </>
                                    :
                                    <>
                                        {user?.birthday && <p className={`editedUserEmail`}><span className='itemInfo'>{t('birthday')} </span> : {user?.birthday}</p>}
                                    </>
                                }
                                {user?.player_position && <p className={`editedUserEmail`}><span className='itemInfo'>{t('position')} </span> : {user?.player_position?.name}</p>}
                                {user?.player_footness && <p className={`editedUserEmail`}><span className='itemInfo'>{t('player-foots')} </span> : {user?.player_footness?.name}</p>}
                                {user?.academy_level && <p className={`editedUserMobile`}><span className='itemInfo'>{t('acadimy-level')} </span> : {user?.academy_level?.name}</p>}
                                {user?.trainer_experience_level && <p className={`editedUserEmail`}><span className='itemInfo'>{t('experience-level')} </span> : {user?.trainer_experience_level?.name}</p>}
                                {user?.achievements && <p className={`editedUserEmail`}><span className='itemInfo'>{t('achievements-record')} </span> : {user?.achievements}</p>}
                            </div>
                            {user?.bio && <p className={`editedUserEmail px-2 m-1`} style={{ maxWidth: "450px" }}><span className='itemInfo'>{t('bio')} </span> : {user?.bio}</p>}

                        </div>
                    </div>
                </div>

                {
                    hasPresedent &&
                    <div className='d-flex flex-column align-items-center '>
                        {club ?
                            Permissions?.club?.edit &&
                                user?.club_president ?
                                <div className='straightPostcard blue text-dark p-3'>
                                    <div className='d-flex align-items-center p-3 '>
                                        <div className='playerImg border' style={{ backgroundImage: `url(${user?.club_president?.media?.webp ?? noImage})`, zIndex: 10 }}></div>
                                        <div>
                                            <p className={`fullName m-0`}>{t('the-club-president')}</p>
                                            <p className={`editedUserEmail`}  > {user?.club_president?.full_name}</p>
                                        </div>
                                        <Tooltip title={t('edit-president-info')} >
                                            <img className='iconActions ms-2' src={edit} alt="edit" data-bs-toggle="modal" data-bs-target='#presidentModal' />
                                        </Tooltip>
                                    </div>
                                </div>
                                :
                                <div>
                                    {Permissions?.club?.edit &&
                                        <div className='activateUserBtn bg-primary' data-bs-toggle="modal" data-bs-target='#presidentModal' >
                                            {t(`add-club-president`)}
                                        </div>}
                                </div>
                            :
                            <>{console.log(club)}
                                {Permissions?.federation?.edit &&
                                    presedent ?
                                    <div className='straightPostcard blue text-dark p-3'>
                                        <div className='d-flex align-items-center p-3 userPresidentWrapper'>
                                            <div className='playerImg border' style={{ backgroundImage: `url(${presedent?.media?.webp ?? noImage})` }}>
                                            </div>
                                            <div>
                                            <p className={`fullName m-0`}>{t('the-federation-president')}</p>
                                            <p className={`editedUserEmail`} > {presedent?.full_name}</p>
                                            </div>
                                            <Tooltip title={t('edit-president-info')}>
                                                <img className='iconActions' src={edit} alt="edit" data-bs-toggle="modal" data-bs-target='#presidentModal' />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    :
                                    Permissions?.federation?.edit && presedent === null ? " " :
                                        <div>
                                            <div className='activateUserBtn bg-primary' data-bs-toggle="modal" data-bs-target='#presidentModal' >
                                                {t(`add-federation-president`)}
                                            </div>
                                        </div>
                                }
                            </>
                        }
                        {Permissions?.user['block-unblock'] &&
                            blockBtn &&
                            <div className='mb-5 '>
                                {user.status === 'active' &&
                                    <div className='blockUserBtn bg-danger' data-bs-toggle="modal" data-bs-target='#blockModal'>
                                        {t('block')}
                                    </div>
                                }
                                {user.status === 'archived' || user.status === 'blocked'  /* && Permissions?.user?.activate */ &&
                                    <div>
                                        {!loader ?
                                            <div className='activateUserBtn bg-primary' onClick={unblock}> {t('unblock')} </div>
                                            :
                                            <div className='activateUserBtn bg-primary'> <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" ></span> {t('saving')} </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default InfoCard