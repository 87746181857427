import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import TitleContext from '../../Contexts/TitleContext';
import "./SubSide.scss"
import { basename, api_host } from '../../Configuration/config';

export default function Subside({ items, btnText, btnUrl }) {
    let { Title } = useContext(TitleContext);
    return (
        <>
            <section className='subside_wrapper'>
                <h5 className='mb-2'>{`${Title}`}</h5>
                <hr className='w-50'/>
                <div className='subsideItemsWrapper'>
                    {items && items?.map((item, index) => (
                        <div key={index} className="oneItemWrapper">
                            <NavLink className="arrayItems fw-bold" to={`${basename}/dashboard/${btnUrl}/update/${item.id}`}>{(item && item.name)}</NavLink>
                        </div>
                    ))
                }
                </div>
                <hr className='w-50'/>
                <NavLink className="addLabel btn btn-dark text-light" to={`${basename}/dashboard/${btnUrl}/add`}>{btnText}</NavLink>
            </section>
        </>
    )
}
