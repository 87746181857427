import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllBusinesses(obj){
    return await https.get(`${api_url}admin/businesses?${obj}`);
}

//get businesses by id
export async function getBusiness(id){
    return await https.get(`${api_url}admin/businesses/${id}`);
}

//get getBusinessOverView by id
export async function getBusinessOverView(id){
    return await https.get(`${api_url}admin/businesses/${id}/overview`);
}

// accept businesses
export async function unblockBusiness(id){
    return await https.post(`${api_url}admin/businesses/${id}/unblock`);
}

// block businesses
export async function blockBusiness(id){
    return await https.post(`${api_url}admin/businesses/${id}/block`);
}

// accept businesses
export async function acceptBusiness(id){
    return await https.post(`${api_url}admin/businesses/${id}/accept`);
}

// reject businesses
export async function rejectBusiness(id,data){
    return await https.post(`${api_url}admin/businesses/${id}/reject`,data);
}
