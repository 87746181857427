import { t } from 'i18next';
import React, { useState, useEffect, useContext /* , useRef */ } from 'react';
import '../tables.scss';
import { useLocation, useNavigate } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import block from "../../Assets/Icons/block.svg";
import unblock from "../../Assets/Icons/unblock.svg";
import profile from "../../Assets/Icons/profile.svg";
import search from "../../Assets/Icons/search.svg";
import accept from "../../Assets/Icons/accept.svg";
import reject from "../../Assets/Icons/reject.svg";
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import { Link, useSearchParams } from 'react-router-dom';
import i18n from '../../i18n';
import moment from 'moment';
import '../forms.scss';
import PermissionsContext from '../../Contexts/permissionsContext';
import Pagination from '../../Components/Pagination/Pagination';
import { getStatuses } from '../../Apis/statics';
import RejectModal from '../../Utilities/rejectModal';
import BlockModal from '../../Utilities/block';
import { dateLanguage } from '../../Utilities/dateLanguage';
import NotDataFound from '../../Components/NotDataFound/notDataFound';
import { getAllUsers } from '../../Apis/allusers';
import { getAllCities } from '../../Apis/cities';
import { acceptBusiness, blockBusiness, rejectBusiness } from '../../Apis/business';
import { acceptPlayer, blockPlayer, rejectPlayer } from '../../Apis/players';
import { acceptInfluencer, blockInfluencer, rejectInfluencer } from '../../Apis/influencers';
import { acceptTrainer, blockTrainer, rejectTrainer } from '../../Apis/trainers';
import { acceptJournalist, blockJournalist, rejectJournalist } from '../../Apis/journalists';
import { acceptAcademy, blockAcademy, rejectAcademy } from '../../Apis/academies';
export default function AppUsers() {
    let { Permissions } = useContext(PermissionsContext);
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState(searchParams.get("status") ?? '1');
    const [statuses, setStatuses] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [cities, setcities] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '', status: status, city: '' });
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '', city: '' });
    const [pageCount, setPageCount] = useState('');
    const [total, setTotal] = useState()
    const [errors, setErrors] = useState()
    const navigate = useNavigate()

    async function AllUsersData(obj) {
        let filter = { ...obj }
        let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&status=${filter.status}&city=${filter.city}`;
        console.log(params)
        try {
            const res = await getAllUsers(params)
            const Allres = await getAllUsers()
            setTotal(Allres.data.data)
            console.log(res)
            setAllUsers(res.data.data);
            let total = res.data.pagination.total;
            setPageCount(Math.ceil(total / filter.per_page));
        } catch (err) { console.log(err) }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    }, [pathname, i18n.language]);


    useEffect(() => {
        changeTitle(t(`app-users`));
        AllUsersData(filterObj)
        getStatuses('player').then((res) => {
            setStatuses(res.data.data)
        })
        getAllCities().then((res) => {
            setcities(res.data.data);
        })
        // eslint-disable-next-line
    }, []);

    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj }
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        thisObj.city = filterObj2.city;
        setfilterObj(thisObj);
        AllUsersData(thisObj)
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        AllUsersData(filter);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        thisObj2.city = filter.city;
        setfilterObj2(thisObj2);
    }

    function filterByStatus(value) {
        setStatus(value)
        let obj = { ...filterObj };
        obj.status = value;
        obj.page = 1;
        setfilterObj(obj);
        AllUsersData(obj);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = obj.per_page;
        thisObj2.search = obj.search;
        thisObj2.city = obj.city;
        setfilterObj2(thisObj2);
        navigate(`${basename}/dashboard/app-users/users?status=${value}`);
    }

    const [reason, setReason] = useState('');

    async function submitReject() {
        try {
            switch (selectedId.type) {
                case "academy":
                    await rejectAcademy(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                case "business":
                    await rejectBusiness(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                case "player":
                    await rejectPlayer(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                case "journalist":
                    await rejectJournalist(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                case "trainer":
                    await rejectTrainer(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                case "influencer":
                    await rejectInfluencer(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                default: console.log("User Type Error")
            }
        } catch (err) {
            return err.response.data.errors;
        }
    }



    /* accept */
    async function acceptHandler(type, id) {
        try {
            switch (type) {
                case "academy":
                    await acceptAcademy(id)
                    AllUsersData(filterObj);
                    break; case "business":
                    await acceptBusiness(id)
                    AllUsersData(filterObj);
                    break; case "player":
                    await acceptPlayer(id)
                    AllUsersData(filterObj);
                    break; case "journalist":
                    await acceptJournalist(id)
                    AllUsersData(filterObj);
                    break; case "trainer":
                    await acceptTrainer(id)
                    AllUsersData(filterObj);
                    break; case "influencer":
                    await acceptInfluencer(id)
                    AllUsersData(filterObj);
                    break;
                default: console.log("User Type Error")

            }
        } catch (err) {
            return err.response.data.errors;
        }
    }

    function onSubmitBlock() {
        try {
            switch (selectedId.type) {
                case "academy":
                    blockAcademy(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                case "business":
                    blockBusiness(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                case "player":
                    blockPlayer(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                case "journalist":
                    blockJournalist(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                case "trainer":
                    blockTrainer(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                case "influencer":
                    blockInfluencer(selectedId.id, { note: reason })
                    AllUsersData(filterObj);
                    return "done"
                default: console.log("User Type Error")

            }
        } catch (err) {
            return err.response.data.errors;
        }
    }


    return (
        <>
            {<section className='componentGlobalWrapper d-flex flex-column'>
                <>
                    <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                        {statuses?.map((item, index) => (
                            <React.Fragment key={index} >
                                {index ? <span className='separator'></span> : ''}
                                <div onClick={() => filterByStatus(item.id)} className={`tablinks ${status === item.slug && `active`}`} id={`${item.slug}-tab`} data-bs-toggle="tab" data-bs-target={`#${item.slug}`}>{item?.name} <span className='tabcounter'>{status === item.slug && "(" + 0 + ")"}</span></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className='searchActionsBar w-100'>
                        <div className='searchActionsBar mt-0 mb-0'>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                        </div>



                        {/* cities */}
                        <div className="cityFilter">
                            <select defaultValue='' name="city" onChange={changeFilterObj} className="form-select citySelect">
                                <option value="" >{t('all-cities')}</option>
                                {cities && cities.map((city, i) => {
                                    return <option key={i} value={city.id}>{city.name}</option>
                                })}
                            </select>
                        </div>

                        {/* show select */}
                        <div className='perPageContainer'>
                            <label htmlFor='selectShow' className='perPageLabel'>
                                {t('show')}
                            </label>
                            <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                            </select>
                        </div>

                        {/*** apply search btn  ***/}
                        <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
                    </div>

                    <div className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr>
                                    <th>{t('username')}</th>
                                    <th>{t('email')}</th>
                                    <th>{t('type')}</th>
                                    <th>{t('created-at')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allUsers.length > 0 ? allUsers.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    {item?.username}
                                                </div>
                                            </td>
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    {item?.email}
                                                </div>
                                            </td>
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    {item?.type}
                                                </div>
                                            </td>
                                            <td>
                                                {moment(item.created_at).format('DD-MMMM-YYYY')}
                                            </td>
                                            <td>
                                                <div>
                                                    <Tooltip title={t('profile')}>
                                                        <Link to={`${basename}/dashboard/app-users/${item?.type === "fan" ||
                                                            item?.type === "federation" ||
                                                            item?.type === "club" ||
                                                            item?.type === "player" ||
                                                            item?.type === "trainer" ||
                                                            item?.type === "influencer" ||
                                                            item?.type === "journalist" ? item?.type + "s"
                                                            : item?.type === "business" ? item?.type + "es" : "academies"
                                                            }/${item?.id}/details`}>
                                                            <img className='iconActions' src={profile} alt="profile" />
                                                        </Link>
                                                    </Tooltip>
                                                    {(item?.status?.slug === 'pending' || item?.status?.slug === 'need-action') &&
                                                        Permissions?.user['accept-reject'] && <>
                                                            <Tooltip title={t(`accept-${item.type}`)}>
                                                                <img onClick={() => acceptHandler(item?.type, item?.id)} className='iconActions' src={accept} alt="accept" />
                                                            </Tooltip>

                                                            <Tooltip title={t(`reject-${item.type}`)}>
                                                                <img onClick={() => setSelectedId({ id: item?.id, type: item?.type })} data-bs-toggle="modal" data-bs-target='#rejectModal' className='iconActions' src={reject} alt="reject" />
                                                            </Tooltip>
                                                        </>}
                                                    {Permissions?.user['block-unblock'] && <>
                                                        {(item?.status?.slug === 'active') &&
                                                            <Tooltip title={t(`block-${item.type}`)}>
                                                                <img onClick={() => setSelectedId({ id: item?.id, type: item?.type })} data-bs-toggle="modal" data-bs-target='#blockModal' className='iconActions' src={block} alt="block" />
                                                            </Tooltip>}
                                                        {(item?.status?.slug === 'blocked') &&
                                                            <Tooltip title={t(`unblock-${item.type}`)}>
                                                                <img onClick={() => acceptHandler(item?.type, item?.id)} className='iconActions' src={unblock} alt="unblock" />
                                                            </Tooltip>}
                                                    </>
                                                    }
                                                    {Permissions?.user['accept-reject'] &&
                                                        (item?.status?.slug === 'rejected') &&
                                                        <Tooltip title={t(`accept-${item.type}`)}>
                                                            <img onClick={() => acceptHandler(item?.type, item?.id)} className='iconActions' src={accept} alt="accept" />
                                                        </Tooltip>}

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                )
                                    :
                                    <tr >
                                        <td colSpan={8} >
                                            <NotDataFound />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <BlockModal onSubmitBlock={onSubmitBlock} name={selectedId.type} ></BlockModal>
                        <RejectModal submitReject={submitReject} reason={reason} setReason={setReason} setErrors={setErrors} ></RejectModal>
                    </div>
                </>
                <div className='d-flex justify-content-end mt-auto'>
                    <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
                </div>
            </section>}
        </>
    )
}
