import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { acceptAcademy, blockAcademy, getAcademy, getAcademyOverview, getAcademyPresedent } from '../../Apis/academies';
import TitleContext from '../../Contexts/TitleContext';
import noImage from "../../Assets/Images/noImage.jpg";
import '../profile.scss';
import BlockModal from '../../Utilities/block';
import { getAllPlayers } from '../../Apis/players';
import Pagination from '../../Components/Pagination/Pagination';
import NotDataFound from '../../Components/NotDataFound/notDataFound';
import Videos from '../../Components/Videos/Videos';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { map_key } from '../../Configuration/config';
import file from '../../Assets/Icons/file.svg'
import OverviewCard from '../../Components/OverviewCard/OverviewCard';
import InfoCard from '../../Components/InfoCard/InfoCard';

export default function AcademyDetails() {
    const { changeTitle } = useContext(TitleContext);
    const { id } = useParams();
    const [academy, setAcademy] = useState();
    const [players, setPlayers] = useState();
    const [loader, setLoader] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [filterObj, setfilterObj] = useState({ per_page: 12, page: 1, academy_id: id });
    const [overview, setOverview] = useState([])
    const [presedent, setPresedent] = useState()

    async function getData() {
        await getAcademy(id).then((res) => {
            setAcademy(res.data.data);
            setCoords({ lat: Number(res.data.data.latitude), lng: Number(res.data.data.longitude) })
            console.log(res.data.data)
        })
    }

    async function getOverview() {
        await getAcademyOverview(id).then((res) => {
            setOverview(res.data.data);
            console.log(res.data.data)
        })
    }

    function getPlayersAtAcademy(filter) {
        let params = `per_page=${filter.per_page}&page=${filter.page}&academy_id=${filter.academy_id}`;
        console.log(params)
        getAllPlayers(params).then((res) => {
            setPlayers(res.data.data)
            console.log(res.data.data);
            let total = res.data.pagination.total;
            setPageCount(Math.ceil(total / filter.per_page));
        })
    }

    useEffect(() => {
        getData()
        getOverview()
        getPresident()
        getPlayersAtAcademy(filterObj)
        changeTitle(t('academy-details'));
        // eslint-disable-next-line
    }, [])

    /*********accept********/
    function unblock() {
        setLoader(true);
        acceptAcademy(id).then(() => {
            setLoader(false);
            getData();
        }).catch((err) => {
            console.log(err.response);
        })
    }

    async function onSubmitBlock() {
        return await blockAcademy(id).then(res => {
            getData();
            return "done";
        }).catch(err => {
            return err.response.data.errors;
        })
    }

    function handlepageChange(data) {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        getPlayersAtAcademy(filter)
        setfilterObj(filter);
    }

    /***********Location***** */
    const [libraries] = useState(['places']);
    const containerStyle = { width: '100%', height: '400px' };
    let [coords, setCoords] = useState({ lat: 21.492500, lng: 39.177570 });

    function getPresident() {
        getAcademyPresedent(id).then((res) => {
            setPresedent(res.data.data)
        }).catch((err)=> console.log(err))
    }
    return (
        <React.Fragment>
            {academy && <section className={`profile pt-0 mt-4`}>
                <InfoCard user={academy} blockBtn={true} unblock={unblock} loader={loader} hasPresedent={true} presedent={presedent ?? null} />
                <OverviewCard overview={overview} />
                <div className='mb-5'>
                    <div className='filterAtProfile'>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                            <div className={`tablinks active`} id={`players-tab`} data-bs-toggle="tab" data-bs-target={`#players`} >
                                {`${t("players")}`}
                            </div>
                            <div className={`tablinks`} id={`videos-tab`} data-bs-toggle="tab" data-bs-target={`#videos`} >
                                {t("videos")}
                            </div>
                            <div className={`tablinks`} id={`certificates-tab`} data-bs-toggle="tab" data-bs-target={`#certificates`} >
                                {t("certificates")}
                            </div>
                            <div className={`tablinks`} id={`location-tab`} data-bs-toggle="tab" data-bs-target={`#location`} >
                                {t("location")}
                            </div>
                        </div>
                    </div>
                    <div className='tab-content' id="pills-tabContent">
                        <div className={`tab-pane fade m-0 p-0 show`} id="videos" role="tabpanel" aria-labelledby="videos">
                            <Videos type={academy} />
                        </div>

                        <div className={`tab-pane fade m-4  show`} id="location" role="tabpanel" aria-labelledby="location">
                            <LoadScript id="script-loader" googleMapsApiKey={map_key} libraries={libraries}>
                                <GoogleMap mapContainerStyle={containerStyle} center={coords} zoom={18} draggable={true}>
                                    <Marker position={coords} />
                                </GoogleMap>
                            </LoadScript>
                        </div>

                        <div className={`tab-pane fade m-4  show`} id="certificates" role="tabpanel" aria-labelledby="certificates">
                            <div className='d-flex justify-content-evenly '>
                                {academy?.certificates.length ?
                                    academy?.certificates?.map((cert) => {
                                        return (
                                            <a
                                                key={cert.id} target='_blank' href={cert.url}
                                                rel="noreferrer" style={{ cursor: "pointer" }}
                                                className='d-flex align-items-center border rounded p-2 text-dark'
                                            >
                                                <img width={"25px"} style={{ cursor: "pointer" }} src={file} alt="" />
                                                <span className='p-2' >{cert.name}</span>
                                            </a>
                                        )
                                    })
                                    : <div className='w-100'>
                                        <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className={`tab-pane fade m-0 p-0 active show`} id="players" role="tabpanel">
                            {players?.length > 0 ?
                                <>
                                    <div className='m-3 p-0 d-flex flex-wrap'>
                                        {players?.map((item, index) => {
                                            return (
                                                <div className='m-1 flex-grow-1  p-2 d-flex rounded justify-content-between straightPostcard   blue '  key={index}>
                                                    <div className='d-flex' style={{ maxWidth: "75%", width: "75%", overflow: 'hidden' }}>
                                                        <div className='playerImg border ' style={{ backgroundImage: `url(${item?.media?.webp ?? noImage})` }}> </div>
                                                        <div className='text-dark'>
                                                            <p className={`fullName`}>{item?.first_name} {item?.last_name}</p>
                                                            <p className={`editedUserEmail `}>{item?.player_position?.name}</p>
                                                            <p className={`editedUserEmail`}>{item?.email}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {pageCount > 1 ?? <div className='d-flex justify-content-end mt-4'>
                                        <Pagination pagesNumber={pageCount} page={0} pageChangeHandler={handlepageChange} />
                                    </div>}
                                </>
                                : <div className='w-100'>
                                    <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>}

            <BlockModal onSubmitBlock={onSubmitBlock} name='academy' ></BlockModal>
        </React.Fragment>
    )
}
