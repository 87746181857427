import React from 'react'
import GIF from './empty.svg'
import { useTranslation } from 'react-i18next'
export default function NotDataFound() {
    const { t } = useTranslation()
    return (
        <div className='d-flex justify-content-center align-items-center flex-column mb-2 w-100'>
            <img src={GIF} alt={t("No Data Found")} className='w-25' />
            <h5>{t("No Data Found")}</h5>
        </div>
    )
}
