import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllInfluencers(obj){
    return await https.get(`${api_url}admin/influencers?${obj}`);
}

//get influencers by id
export async function getInfluencer(id){
    return await https.get(`${api_url}admin/influencers/${id}`);
}

// accept influencers
export async function unblockInfluencer(id){
    return await https.post(`${api_url}admin/influencers/${id}/unblock`);
}

// block influencers
export async function blockInfluencer(id){
    return await https.post(`${api_url}admin/influencers/${id}/block`);
}

// accept influencers
export async function acceptInfluencer(id){
    return await https.post(`${api_url}admin/influencers/${id}/accept`);
}

// reject influencers
export async function rejectInfluencer(id,data){
    return await https.post(`${api_url}admin/influencers/${id}/reject`,data);
}
//get clubsOverview by id
export async function getInfluencerOverView(id){
    return await https.get(`${api_url}admin/influencers/${id}/overview`);
}