import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { blockTrainer, getTrainer, getTrainerOverview, unblockTrainer, userCourses } from '../../Apis/trainers'
import TitleContext from '../../Contexts/TitleContext'
import noImg from "../../Assets/Images/noImg.png"
import '../profile.scss'
import NotDataFound from '../../Components/NotDataFound/notDataFound'
import Videos from '../../Components/Videos/Videos'
import CourseCard from '../../Components/CourseCard/CourseCard'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import OverviewCard from '../../Components/OverviewCard/OverviewCard'
import InfoCard from '../../Components/InfoCard/InfoCard'
import BlockModal from '../../Utilities/block'
import { Unarchive } from '@mui/icons-material'

export default function TrainersDetails() {
    const { changeTitle } = useContext(TitleContext)
    const { id } = useParams()
    const [trainer, setTrainer] = useState()
    const [overview, setOverview] = useState()
    const [courses, setCourses] = useState()
    const [loader, setLoader] = useState()

    console.log(courses)

    //const { Permissions } = useContext(PermissionsContext);
    async function getData() {
        const res1 = await getTrainer(id)
        setTrainer(res1.data.data);
    }
    async function getOverview() {
        await getTrainerOverview(id).then((res) => {
            setOverview(res.data.data);
            console.log(res.data.data)
        })
    }

    useEffect(() => {
        getData();
        getOverview()
        changeTitle(t('trainer-details'));
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (trainer) {
            userCourses(trainer.user_id).then((res) => {
                res.data.data.length === 0 ?
                    setCourses("")
                    :
                    setCourses(res.data.data)
            })
        }
    }, [trainer])


    /* unblock */
    function unblock(id) {
        setLoader(true)
        unblockTrainer(id).then(() => {
            getData()
            setLoader(false)
        }).catch((err) => {
            console.log(err.response);
            setLoader(false)
        })
    }

    async function onSubmitBlock() {
        setLoader(true)
        return await blockTrainer(id).then(res => {
            getData()
            setLoader(false)
            return "done";
        }).catch(err => {
            setLoader(false)
            return err.response.data.errors;
        })
    }


    return (
        <React.Fragment>
            {trainer && <section className={`profile pt-0 mt-4`}>

                <InfoCard user={trainer} blockBtn={true} unblock={unblock} loader={loader} />
                <OverviewCard overview={overview} />


                <div className='mb-5'>

                    <div className='filterAtProfile'>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                            <div className={`tablinks active`} id={`courses-tab`} data-bs-toggle="tab" data-bs-target={`#courses`} aria-controls="courses"  >
                                {t("courses")}
                            </div>
                            <div className={`tablinks`} id={`videos-tab`} data-bs-toggle="tab" data-bs-target={`#videos`} aria-controls="videos" >
                                {t("videos")}
                            </div>
                            <div className={`tablinks`} id={`videos-tab`} data-bs-toggle="tab" data-bs-target={`#certificates`} aria-controls="certificates" >
                                {t("certificates")}
                            </div>
                        </div>
                    </div>
                    {/******courses********/}
                    {console.log(courses)}
                    <div className='tab-content' id="pills-tabContent">
                        <div className={`tab-pane fade m-0 p-0 active show`} id="courses" role="tabpanel" aria-labelledby="courses">
                            <div className='container '>
                                {courses ?
                                    courses?.map((course) => (
                                        <CourseCard key={course.id} course={course} />
                                    ))
                                    :
                                    <div className='w-100'>
                                        <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
                                    </div>
                                }
                            </div>
                        </div>
                        {/******Videos********/}
                        <div className={`tab-pane fade m-0 p-0 show`} id="videos" role="tabpanel" aria-labelledby="videos">
                            <Videos type={trainer} />
                        </div>
                        {/******Location********/}
                        <div className={`tab-pane fade m-0 p-0  my-2 show`} id="certificates" role="tabpanel" aria-labelledby="certificates">
                            {trainer?.certificates ?
                                <div className='d-flex justify-content-evenly align-items-center'>
                                    <Card sx={{ maxWidth: "25vw" }}>
                                        <CardActionArea className='text-center'>
                                            <CardMedia component="img" width="20rem" image={trainer?.certificates?.experience_certification?.url} alt='' />
                                            <CardContent> <Typography gutterBottom variant="h6" component="div"> Experience Certificate</Typography> </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    <Card sx={{ maxWidth: "25vw" }}>
                                        <CardActionArea className='text-center'>
                                            <CardMedia component="img" width="20rem" image={trainer?.certificates?.training_certification?.url} alt='' />
                                            <CardContent> <Typography gutterBottom variant="h6" component="div"> Training Certificate</Typography> </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                                : <div className='w-100'>
                                    <div style={{ padding: '20px', color: '#00263C', fontSize: '19px' }}><NotDataFound /></div>
                                </div>
                            }
                        </div>

                    </div>

                </div>
                <BlockModal onSubmitBlock={onSubmitBlock} name={t('trainer')} ></BlockModal>

            </section>}



        </React.Fragment>
    )
}
