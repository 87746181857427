import React from 'react'
import './loader.css';
import loading from "../../Assets/Images/loading_new.gif";

export default function Loader() {
  return (
    <div className="overlay">
        <div className="overlay__inner">
            <div className="overlay__content">
                <img className="overlay__image" src={loading} alt="loader" />
            </div>
        </div>
    </div> 
  )
}
