import { t } from "i18next";
import React, { useState, useEffect, useContext } from "react";
import "../tables.scss";
import { useLocation } from "react-router";
import search from "../../Assets/Icons/search.svg";
import noImg from "../../Assets/Images/noImg.png";
import i18n from "../../i18n";
import "../forms.scss";
import Pagination from "../../Components/Pagination/Pagination";
import { dateLanguage } from "../../Utilities/dateLanguage";
import NotDataFound from "../../Components/NotDataFound/notDataFound";
import { getPromotedVideos } from "../../Apis/promotes";
import TitleContext from "../../Contexts/TitleContext";
import moment from "moment";

export default function Videos() {
  const { pathname } = useLocation();
  const [videos, setVideos] = useState();
  const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: "", });
  const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });
  const [pageCount, setPageCount] = useState("");
  const { changeTitle } = useContext(TitleContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    dateLanguage(i18n.language);
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    PromotedVideosData(filterObj);
    changeTitle(t(`promoted-videos`));
    // eslint-disable-next-line
  }, []);

  function PromotedVideosData(obj) {
    let filter = { ...obj };
    let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}`;
    getPromotedVideos(params).then((res) => {
      console.log(res.data.data);
      setVideos(res.data.data.promotes);
      let total = res?.data?.pagination?.total;
      setPageCount(Math.ceil(total / filter.per_page));
    });
  }

  function changeFilterObj(e) {
    let thisObj = { ...filterObj2 };
    thisObj[e.target.name] = e.target.value.trim();
    setfilterObj2(thisObj);
  }

  function getFiltered() {
    let thisObj = { ...filterObj };
    thisObj.page = 1;
    thisObj.per_page = filterObj2.per_page;
    thisObj.search = filterObj2.search;

    setfilterObj(thisObj);
    PromotedVideosData(thisObj);
  }

  const handlepageChange = async (data) => {
    let filter = { ...filterObj };
    filter.page = data.selected + 1;
    setfilterObj(filter);
    PromotedVideosData(filter);

    let thisObj2 = { ...filterObj2 };
    thisObj2.per_page = filter.per_page;
    thisObj2.search = filter.search;
    setfilterObj2(thisObj2);
  };


  return (
    <>
      {<section className='componentGlobalWrapper d-flex flex-column'>
        <div>
          <div className='searchActionsBar w-100'>
            <div className='searchActionsBar mt-0 mb-0'>
              <div className='searchInputWrapper'>
                <div>
                  <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                </div>
                <img src={search} alt='search-icon' className='barSearchIcon' />
              </div>
            </div>
            <div className='perPageContainer'>
              <label htmlFor='selectShow' className='perPageLabel'>
                {t('show')}
              </label>
              <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='15'>15</option>
                <option value='20'>20</option>
              </select>
            </div>
            <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
          </div>

          <div className='tableWrapper'>
            <table className="w-100 table table-striped border-0">
              <thead>
                <tr>
                  <th>{t("cover")}</th>
                  <th>{t("user")}</th>
                  <th>{t("views")}</th>
                  <th>{t("likes")}</th>
                  <th>{t("comments")}</th>
                  <th>{t("shares")}</th>
                  <th>{t("starts_at")}</th>
                  <th>{t("ends_at")}</th>
                  <th>{t("tagged-users")}</th>
                  <th>{t("terget cities")}</th>
                  <th>{t("target user types")}</th>
                </tr>
              </thead>
              <tbody>
                {console.log(videos)}
                {videos?.length > 0 ? (
                  videos?.map((item, index) => {
                    console.log(item);
                    return (
                      <tr key={index}>
                        <td className="sliderImg">
                          <img
                            className="w-75"
                            src={item?.model?.cover ? item?.model?.cover : noImg}
                            alt="slider"
                          />
                        </td>
                        <td className="desc">
                          <div className="descriptionTd">
                            {item?.model?.user?.username}
                          </div>
                        </td>
                        <td className="desc">
                          <div className="descriptionTd">
                            {item?.model?.views_count}
                          </div>
                        </td>
                        <td className="desc">
                          <div className="descriptionTd">
                            {item?.model?.likes_count}
                          </div>
                        </td>
                        <td>
                          <div className="descriptionTd">
                            {item?.model?.comments_count}
                          </div>
                        </td>
                        <td>
                          <div className="descriptionTd">
                            {item?.model?.shares_count}
                          </div>
                        </td>
                        <td>
                          <div className="descriptionTd">
                            {moment(item?.starts_at).format('YYYY-MM-DD')}
                          </div>
                        </td>
                        <td>
                          <div className="descriptionTd">
                            {moment(item?.ends_at).format('YYYY-MM-DD')}
                          </div>
                        </td>
                        <td>
                          {item?.model?.tagged_users.length > 0
                            ? item?.model?.tagged_users?.map((user) => (
                              <>
                                <li key={user.username}> {user.username} </li>
                              </>
                            ))
                            : t("no-taged-users")}
                        </td>
                        <td>
                            {console.log(item?.target)}
                          {item?.cities?.length > 0
                            ? item?.cities?.map((target) => (
                              <>
                                <li key={target.id}> {target.name} </li>
                              </>
                            ))
                            : t("-")}
                        </td>
                        <td>
                          {item?.user_types?.length > 0
                            ? item?.user_types?.map((target) => (
                              <>
                                <li key={target.id}> {target.name} </li>
                              </>
                            ))
                            : t("-")}
                        </td>

                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <NotDataFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-auto">
          <Pagination
            pagesNumber={pageCount}
            page={filterObj.page - 1}
            pageChangeHandler={handlepageChange}
          />
        </div>
      </section>
      }
    </>
  );
}
