import { useState , useEffect , React} from "react";
import { Outlet } from "react-router-dom";
import { getAdminProfileInfo } from "./Apis/profile.js";
import Loader from './Components/Loader/loader';
/* import { useContext } from "react";
import PermissionsContext from "./Contexts/permissionsContext.js"; */
import AuthLayout from "./Layouts/authLayout/authLayout.jsx";


export default function ProtectedRoutes() {

    let [authorized , setauthorized] = useState('');
    /* let { changePermissions } = useContext(PermissionsContext); */

    useEffect(() =>{
        getAdminProfileInfo().then((res) => { 
            /*  let permission = res.data.data.admin.permissions;
            changePermissions(permission); */
            setauthorized('authorized');
        }).catch((err)=>{
            console.log(err?.response?.status)
            setauthorized('not-authorized')
        }) 
        
    } , []);

    if(authorized === 'authorized'){
        return <Outlet /> 
    }else if(authorized === 'not-authorized'){
        return <AuthLayout/>;
    }else{
        return <>
            <Loader />
        </>
    }
}