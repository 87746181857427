import React, { useContext, useEffect, useRef, useState } from "react";
import { getSetting, updateSetting } from "../../../Apis/settings";
import TitleContext from "../../../Contexts/TitleContext";
import { supportedLanguages } from "../../../Utilities/SupportedLanguages";
import { t } from "i18next";
import PermissionsContext from "../../../Contexts/permissionsContext";
import { useNavigate } from "react-router";
import aboutImg from '../../../Assets/Icons/Aboutpage.svg'
import { useTranslation } from "react-i18next";
export default function Aboutus() {
  const { Permissions } = useContext(PermissionsContext);
  const { changeTitle } = useContext(TitleContext);
  const [Locales, setLocales] = useState("");
  const [errors, setErrors] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  useEffect(() => {
    changeTitle(`about us`);
    aboutUsData();
    // eslint-disable-next-line
  }, []);

  async function aboutUsData() {
    try {
      const res = await getSetting("about-us");
      let data = res.data.data?.locales;
      setLocales(data);
    } catch (err) {
      console.log(err)
    }
  }

  // onsubmit page content
  const submitHandler = async (e) => {
    setLoader(true)
    console.log("SUBMITTED", Locales);
    if (Locales !== "" && Locales?.ar?.description !== "" && Locales.en?.description !== "" && Locales?.ar !== [] && Locales.en !== []) {

      try {
        await updateSetting("about-us", Locales)
        setLoader(false)
        navigate('/dashboard/settings/appearance')
      } catch (err) {
        setLoader(false)
        navigate('/dashboard/settings/appearance')
        console.log(err);
        setErrors(err)
      }
    } else {
      setErrors("Please Fill All Feilds!")
      setLoader(false)
    }
  }

  function handleChange(e, lang) {
    const { name, value } = e.target;
    setLocales((prevState) => ({
      ...prevState,
      [name]: {
        description: value
      }
    }));
    console.log(Locales)
  }

  return (
    <>
      <section className="componentGlobalWrapper" style={{ backgroundImage: `url(${aboutImg})`, backgroundRepeat: "no-repeat", backgroundPosition: i18n.language === "ar" ? "left" : "right" }}>
        <div className="mb-3">
          <h5 className="pageSubtitle">{t("page-content")}</h5>
          <hr />
          {errors && <div className="text-danger">{errors}</div>}

          <div className="w-100 pt-5" >
            <div className='d-flex flex-column'>
              {supportedLanguages?.map((language, index) => {

                return (
                  <div key={index} className="d-flex flex-column mb-4 ">
                    <label htmlFor="content" className="formLabel">{`${t(
                      "content-in"
                    )} (${t(`${language.name}`)})`}</label>
                    <div {...(language.slug === "ar" ? { dir: "rtl" } : {})}>

                      <textarea
                        disabled={!Permissions?.setting?.edit}
                        name={`${language.slug}`}
                        className="textArea w-50 "
                        value={
                          Locales ? Locales[language.slug]?.description : " "
                        }
                        onChange={(e) => handleChange(e, language.slug)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            { <>
                {Permissions?.setting?.edit && !loader ? (
                  <div>
                    <button className="confirmBtn" onClick={() => submitHandler()}>
                      {t("save-changes")}
                    </button>
                  </div>
                ) : (Permissions?.setting?.edit &&
                  <button className="confirmBtn" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {t("saving")}...
                  </button>
                )}
              </>
              // )
            }
          </div>
        </div>
      </section>
    </>
  );
}
