import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getContactMsgs(params) {
    return await https.get(`${api_url}admin/contact?${params}`);
}

export async function toggleRead(id, type) {
    return await https.post(`${api_url}admin/contact/${id}/${type}`);
}

export async function msgDelete(id) {
    return await https.delete(`${api_url}admin/contact/${id}/delete`);
}