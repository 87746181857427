import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllJournalists(obj){
    return await https.get(`${api_url}admin/journalists?${obj}`);
}

//get journalists by id
export async function getJournalist(id){
    return await https.get(`${api_url}admin/journalists/${id}`);
}

//get getJournalistOverview by id
export async function getJournalistOverview(id){
    return await https.get(`${api_url}admin/journalists/${id}/overview`);
}

// accept journalists
export async function unblockJournalist(id){
    return await https.post(`${api_url}admin/journalists/${id}/unblock`);
}

// block journalists
export async function blockJournalist(id){
    return await https.post(`${api_url}admin/journalists/${id}/block`);
}

// accept journalists
export async function acceptJournalist(id){
    return await https.post(`${api_url}admin/journalists/${id}/accept`);
}

// reject journalists
export async function rejectJournalist(id,data){
    return await https.post(`${api_url}admin/journalists/${id}/reject`,data);
}
