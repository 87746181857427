import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllTrainers(obj){
    return await https.get(`${api_url}admin/trainers?${obj}`);
}

//get trainers by id
export async function getTrainer(id){
    return await https.get(`${api_url}admin/trainers/${id}`);
}

//get trainersOverview by id
export async function getTrainerOverview(id){
    return await https.get(`${api_url}admin/trainers/${id}/overview`);
}

// accept trainers
export async function unblockTrainer(id){
    return await https.post(`${api_url}admin/trainers/${id}/unblock`);
}

// block trainers
export async function blockTrainer(id){
    return await https.post(`${api_url}admin/trainers/${id}/block`);
}

// accept trainers
export async function acceptTrainer(id){
    return await https.post(`${api_url}admin/trainers/${id}/accept`);
}

// reject trainers
export async function rejectTrainer(id,data){
    return await https.post(`${api_url}admin/trainers/${id}/reject`,data);
}
// get userCourses
export async function userCourses(id){
    return await https.get(`${api_url}admin/courses?user_id=${id}`);
}
