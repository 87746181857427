let obj = {
    business_name:"",
    email:"",
    username:"",
    birthday:'',
    latitude:'',
    longitude:"",
    city:"",
    country:"",
    phone:"",
    password_confirmation:"",
    password:"",
    media:""
}
export default obj;