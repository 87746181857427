import React, { useContext, useEffect } from 'react'
import Form from './Form';
import TitleContext from '../../../Contexts/TitleContext';
import { t } from 'i18next';
import { updateAcademyLevels } from '../../../Apis/academyLevels';
import { useParams } from 'react-router';
import i18n from '../../../i18n';

export default function EditAcademyLevels() {
    const { changeTitle } = useContext(TitleContext);
    const {id} = useParams();

    useEffect(() => {
        changeTitle(t('update-academy-level'));
        // eslint-disable-next-line
    }, [i18n.language]);

    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await updateAcademyLevels(id,data).then(res => {
            return 'done';          
        }).catch(err =>{
            console.log(err?.response?.status)
            if(err.response.status === 403){
                return {error :err.response.data.message , type: 'permissions'};
            }else if(err.response.status === 422){
                return {error: err.response.data.errors ,type:'validations'};
            }
        })
    };

    return (
        <div>
            <Form onSubmit={onSubmit} />
        </div>
    )
}
