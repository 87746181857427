import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getFederation, getFederationPresident, activateFederation, archiveFederation, getFederationOverview } from '../../Apis/federations';
//import PermissionsContext from '../../Contexts/permissionsContext';
import TitleContext from '../../Contexts/TitleContext';
import '../profile.scss';
import Videos from '../../Components/Videos/Videos';
import FederationUpdatePresident from './FederationPresdientForm/Update';
import FederationCreatePresident from './FederationPresdientForm/Create';
import { GoogleMap, Marker,  LoadScript } from '@react-google-maps/api';
import {  map_key } from '../../Configuration/config';
import OverviewCard from '../../Components/OverviewCard/OverviewCard';
import InfoCard from '../../Components/InfoCard/InfoCard';

export default function FederationDetails() {
    const { changeTitle } = useContext(TitleContext);
    const { id } = useParams();
    const [federation, setFederation] = useState();
    const [loader, setLoader] = useState(false);
    const [overview, setOverview] = useState([])
    const [presedent, setPresedent] = useState()
    //const { Permissions } = useContext(PermissionsContext);

    async function getData() {
        await getFederation(id).then((res) => {
            setFederation(res.data.data);
            setCoords({ lat: Number(res.data.data.latitude), lng: Number(res.data.data.longitude) })

            // console.log(res.data.data)
        })
    }
    async function getOverview() {
        await getFederationOverview(id).then((res) => {
            setOverview(res.data.data);
        })

    }
    function getPresident() {
        getFederationPresident(id).then((res) => {
            console.log("PRESEDENT:::::", res)
            setPresedent(res.data.data)
        }).catch((err)=> console.log(err))
    }


    useEffect(() => {
        getData();
        getOverview()
        getPresident()
        changeTitle(t('federation-details'));
        // eslint-disable-next-line
    }, [])


    /***********Location***** */
    const [libraries] = useState(['places']);
    const containerStyle = { width: '100%', height: '400px' };
    let [coords, setCoords] = useState({ lat: 21.492500, lng: 39.177570 });

    return (
        <React.Fragment>
            {federation && <section className={`profile pt-0 mt-4`}>
                <InfoCard user={federation} hasPresedent={true} presedent={presedent} />
                <OverviewCard overview={overview} />

                <div className='mb-5'>

                    <div className='filterAtProfile'>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center mb-0' id="myTab" role="tablist">
                            <div className={`tablinks active`} id={`videos-tab`} data-bs-toggle="tab" data-bs-target={`#videos`} >
                                {t("videos")}
                            </div>
                            <div className={`tablinks`} id={`location-tab`} data-bs-toggle="tab" data-bs-target={`#location`} >
                                {t("location")}
                            </div>
                        </div>
                    </div>
                    <div className='tab-content mt-0 pt-0' id="pills-tabContent">


                        <div className={`tab-pane fade m-0 p-0 active show`} id="videos" role="tabpanel" aria-labelledby="videos">
                            <Videos type={federation} />
                        </div>

                        <div className={`tab-pane fade m-4 show`} id="location" role="tabpanel" aria-labelledby="location">
                            <LoadScript id="script-loader" googleMapsApiKey={map_key} libraries={libraries}>
                                <GoogleMap mapContainerStyle={containerStyle} center={coords} zoom={18} draggable={true}  >
                                    <Marker position={coords} />
                                </GoogleMap>
                            </LoadScript>
                        </div>
                        
                        <div className="modal fade" id="presidentModal" tabIndex={-1} aria-hidden="true">
                            {
                                presedent ?
                                    <FederationUpdatePresident getNewData={getPresident} federationId={id} data={presedent} />
                                    :
                                    <FederationCreatePresident getNewData={getPresident} federationId={id} />
                            }
                        </div>

                    </div>
                </div>
            </section>}

        </React.Fragment>
    )
}
