import React from 'react'
import Form from './Form';
import { createFederationPresident } from '../../../Apis/federations';

export default function FederationCreatePresident(props) {
  const onSubmit = async (e, data) => {
    e.preventDefault();
    console.log(data);
    //to send request
    return await createFederationPresident(props.federationId,data).then(res => {
      console.log(res.data)
      props.getNewData()
      return 'done';
    }).catch(err => {
      console.log(err.response)
      return err.response.data.errors;
    })
  }                                             

  return (
    <div className="modal-dialog modal-dialog-centered">
      <Form onSubmit={onSubmit} id='create' federationId={props.federationId} />
    </div>
  )
}
