import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getReports(params) {
    return await https.get(`${api_url}admin/reports?${params}`);
}

export async function toggleRead(id,type){
    return await https.post(`${api_url}admin/reports/${id}/${type}`);
}


export async function deleteReport(id) {
    return await https.delete(`${api_url}admin/reports/${id}`);
}
export async function deleteVideo(id) {
    return await https.delete(`${api_url}admin/videos/${id}`);
}
export async function deleteComment(id) {
    return await https.delete(`${api_url}admin/comments/${id}`);
}
