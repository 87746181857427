import React, { useState, useEffect } from "react";
import "../tables.scss";
import "../forms.scss";
import { useContext } from "react";
import TitleContext from "../../Contexts/TitleContext";
import zoom from '../../Assets/Icons/zoomapp.svg'
import corseprofit from '../../Assets/Icons/corseprofit.svg'
import { getCoursesProfits, getZoomPrice, addCoursesProfits, addZoomPrice } from '../../Apis/financials'

import { useTranslation } from "react-i18next";
import { Button, Card, CardContent, div } from "@mui/material";
import PermissionsContext from "../../Contexts/permissionsContext";

export default function Financials() {
	const { changeTitle } = useContext(TitleContext);
	const [editableCourse, setEditableCourse] = useState(false);
	const [editable, setEditable] = useState(false);
	const [courseValue, setCourseValue] = useState();
	const [zoomValue, setZoomValue] = useState();
	const { t } = useTranslation()
	const [errors, setErrors] = useState()
	const { Permissions } = useContext(PermissionsContext);

	async function getAllFees() {
		await getCoursesProfits().then((res) => {
			setCourseValue(res.data.data[0]);
			console.log(res.data.data[0]);
		});
		await getZoomPrice().then((res) => {
			setZoomValue(res.data.data[0]);
			console.log(res.data.data[0]);
		});
	}

	useEffect(() => {
		getAllFees();
		changeTitle(t(`financials`), /* Permissions?.articles?.create && */);
		// eslint-disable-next-line
	}, []);

	const handleCourseChange = (event) => setCourseValue(event.target.value)
	const handleCourseEdit = () => setEditableCourse(true);
	const handleCourseSave = async () => {
		try {
			const res = await addCoursesProfits({ value: courseValue })
			console.log(res)
			setEditableCourse(false);
		} catch (err) {
			console.log(err)
			// setErrors(err.response.data)
		}
	};
	const handleCourseDelete = () => {
		getAllFees()
		setEditableCourse(false);
	}

	const handleZoomChange = (event) => setZoomValue(event.target.value);
	const handleZoomDelete = () => {
		getAllFees()
		setEditable(false);
	}
	const handleZoomEdit = () => setEditable(true);
	const handleZoomSave = async () => {
		try {
			const res = await addZoomPrice({ value: zoomValue })
			console.log(res)
			setEditableCourse(false)
			setEditable(false)
		} catch (err) {
			console.log(err)
		}
	};

	return (
		<div>
			{
				<section className="componentGlobalWrapper d-flex flex-wrap">
					<Card className="m-3 h-25 flex-grow-1 position-relative" >
						<CardContent className="d-flex flex-column justify-content-evenly  mx-4">
							<div className="d-flex justify-content-between"  >
								<div className="d-flex  align-items-center">
									<img className="p-2" style={{ width: "50px" }} src={corseprofit} alt="" />
									<h4 className="fw-bold m-0 p-0"> {t("courses-financials")}</h4>
								</div>
								<div className="d-flex  align-items-center">
									{ Permissions?.setting?.edit && 
									editableCourse ? (
										<>
											<Button className="m-2" variant="contained" color="primary" onClick={handleCourseSave}>
												{t("save-changes")}
											</Button>
											<Button className="m-2" variant="contained" color="error" onClick={handleCourseDelete}>
												{t("X")}
											</Button>
										</>
									) : ( Permissions?.setting?.edit && 
										<Button className="m-2" variant="contained" color="primary" onClick={handleCourseEdit}>
											{t("edit")}
										</Button>
									)}
								</div>
							</div>
							<div className="d-flex flex-column justify-content-center align-items-center">
								<label htmlFor="course">{`${t("amount")}  ( ${t('%')} )`}</label>
								<input
									className="m-2 mt-0"
									id="course"
									type="number"
									value={courseValue?.value}
									onChange={handleCourseChange}
									disabled={!editableCourse}
								/>
							</div>

						</CardContent>
					</Card>
					<Card className="m-3 h-25 flex-grow-1 position-relative" >

						<CardContent className="d-flex flex-column justify-content-evenly  mx-4 ">
							<div className="d-flex justify-content-between"  >
								<div className="d-flex  align-items-center">
									<img className="p-2" style={{ width: "50px" }} src={zoom} alt="" />
									<h4 className="fw-bold m-0 p-0">{t("zoom-min-price")}</h4>
								</div>
								<div className="d-flex  align-items-center">
									{ Permissions?.setting?.edit && 
									editable ? (
										<>
											<Button className="m-2" variant="contained" color="primary" onClick={handleZoomSave}>
												{t("save-changes")}
											</Button>
											<Button className="m-2" variant="contained" color="error" onClick={handleZoomDelete}>
												{t("X")}
											</Button>
										</>
									) : ( Permissions?.setting?.edit && 
										<Button className="m-2" variant="contained" color="primary" onClick={handleZoomEdit}>
											{t("edit")}
										</Button>
									)}
								</div>
							</div>

							<div className="d-flex flex-column justify-content-center align-items-center">
								<label htmlFor="zoom">{`${t("amount")}  ( ${t('sar')} )`}</label>
								<input
									className="m-2 mt-0 "
									id="zoom"
									type="number"
									value={zoomValue?.value}
									onChange={handleZoomChange}
									disabled={!editable}
								/>
							</div>
						</CardContent>
					</Card>
				</section>
			}
		</div>
	);
}
