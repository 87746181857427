import React from 'react'
import Form from './Form'
import { createAdmin } from '../../../Apis/admins';

export default function AddAdmin() {
    const onSubmit = async (data) => {
        //to send request
        console.log(data)
        try{ await createAdmin(data)
            return 'done'
        }catch(err) { return err.response.data.errors;}
    }

    return (
        <Form onSubmit={onSubmit}></Form>
    )
}
