import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllAcademyLevels(obj){
    return await https.get(`${api_url}admin/academy-levels?${obj}`);
}

//get AcademyLevels by id
export async function getAcademyLevels(id){
    return await https.get(`${api_url}admin/academy-levels/${id}`);
}

// create AcademyLevels
export async function createAcademyLevels(data){
    return await https.post(`${api_url}admin/academy-levels`,data);
}

// update AcademyLevels
export async function updateAcademyLevels(id,data){
    return await https.patch(`${api_url}admin/academy-levels/${id}`,data);
}

// delete AcademyLevels
export async function deleteAcademyLevels(id){
    return await https.delete(`${api_url}admin/academy-levels/${id}`);
}
