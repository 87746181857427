import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
//import PermissionsContext from '../../Contexts/permissionsContext';
import TitleContext from '../../Contexts/TitleContext';
import noImage from "../../Assets/Images/noImage.jpg";
import '../profile.scss';
import BlockModal from '../../Utilities/block';
import { blockPlayer, getPlayer, getPlayerOverview, unblockPlayer } from '../../Apis/players';
import Videos from '../../Components/Videos/Videos';
import OverviewCard from '../../Components/OverviewCard/OverviewCard';
import InfoCard from '../../Components/InfoCard/InfoCard';

export default function PlayerDetails() {
    const { changeTitle } = useContext(TitleContext);
    const { id } = useParams();
    const [player, setPlayer] = useState();
    const [loader, setLoader] = useState(false);
    const [overview, setOverview] = useState()
    //const { Permissions } = useContext(PermissionsContext);

    async function getData() {
        await getPlayer(id).then((res) => {
            setPlayer(res.data.data);
            console.log(res.data.data)
        })
    }
    async function getOverview() {
        await getPlayerOverview(id).then((res) => {
            setOverview(res.data.data);
            console.log("OverViewLLLLLL:::::",res.data.data)
        })
    }

    useEffect(() => {
        getData();
        getOverview()
        changeTitle(t('player-details'));
        // eslint-disable-next-line
    }, [])

    /* accept */
    function unblock() {
        setLoader(true);
        unblockPlayer(id).then(() => {
            getData();
            setLoader(false);
        }).catch((err) => {
            console.log(err.response);
        })
    }

    async function onSubmitBlock() {
        return await blockPlayer(id).then(res => {
            getData();
            return "done";
        }).catch(err => {
            return err.response.data.errors;
        })
    }



    return (
        <React.Fragment>
            {player && <section className={`profile pt-0 mt-4`}>

                <InfoCard user={player} blockBtn={true} unblock={unblock} loader={loader} />
                <OverviewCard overview={overview} />

                <div className='mb-5'>

                    <div className='filterAtProfile'>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                            <div className={`tablinks active`} id={`videos-tab`} data-bs-toggle="tab" data-bs-target={`#videos`} >
                                {t("videos")}
                            </div>
                        </div>
                    </div>

                    <div className='tab-content mt-4' id="pills-tabContent">
                        <div className={`tab-pane fade m-0 p-0 active show`} id="videos" role="tabpanel" aria-labelledby="videos">
                            <Videos type={player} />
                        </div>
                    </div>

                </div>

            </section>}

            <BlockModal onSubmitBlock={onSubmitBlock} name='player' ></BlockModal>
        </React.Fragment>
    )
}
