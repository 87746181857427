import React, { useState, useEffect, useContext } from 'react'
import '../tables.scss'
import { t } from 'i18next'
import i18n from '../../i18n'
import { useLocation } from 'react-router'
import Tooltip from '@mui/material/Tooltip'
import DeleteModal from '../../Utilities/deleteModal'
import TitleContext from '../../Contexts/TitleContext'
import delete_icon from "../../Assets/Icons/delete.svg"
import { getStickers, deleteSticker } from '../../Apis/stickers'
import { dateLanguage } from '../../Utilities/dateLanguage'
import Pagination from '../../Components/Pagination/Pagination'
import NotDataFound from '../../Components/NotDataFound/notDataFound'
import { Card, CardContent, Typography } from '@mui/material'
import PermissionsContext from '../../Contexts/permissionsContext'
import removeIcon from "../../Assets/Icons/removeIcon.svg"
import uploadImage from "../../Assets/Icons/uploadimage.svg"
import { AddSticker } from '../../Apis/stickers'
export default function Stickers() {

    const [stickers, setStickers] = useState([])
    const { pathname } = useLocation()
    const [deletedId, setDeletedId] = useState('')
    const [pageCount, setPageCount] = useState('')
    const { changeTitle } = useContext(TitleContext)
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '' })
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '' })
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(false)
    const { Permissions } = useContext(PermissionsContext)

    useEffect(() => {
        window.scrollTo(0, 0)
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    }, [pathname, i18n.language])


    useEffect(() => {
        changeTitle(t(`stickers`))
        StickersData(filterObj)
        // eslint-disable-next-line
    }, [])

    const StickersData = async (obj) => {
        let filter = { ...obj }
        let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}`;
        try {
            const res = await getStickers(params)
            setStickers(res?.data?.data)
            let total = res?.data?.pagination?.total;
            setPageCount(Math.ceil(total / filter?.per_page))
        } catch (err) {
            console.log(err)
        }
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        StickersData(filterObj)
        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        setfilterObj2(thisObj2);
    }

    async function onSubmitDelete() {
        return await deleteSticker(deletedId).then(res => {
            StickersData()
            return "done"
        }).catch(err => {
            console.log(err.response.data.message)
            return err.response.data.message
        })
    }

    /*************************** Actions ******************************/
    const [data, setData] = useState()
    const [img, setimg] = useState()

    const removeImage = () => { setData({sticker: "" }) }
    
    function upload(e) {
        setData({sticker: e.target.files[0] })
        setimg(URL.createObjectURL(e.target.files[0]))
        setErrors({ ...errors, sticker: "" })
    }

    const addSticker = async () => {
        setLoading(true)
        console.log(data)
        const res = await AddSticker(data)
        console.log(res)
        setLoading(false)
        StickersData(filterObj)
        setData({sticker:null })
    }

    return (
        <>
            {<section className='componentGlobalWrapper d-flex flex-column'>
                <div className='d-flex justify-content-end flex-column align-items-start'>
                    {Permissions?.sticker?.create && <div >
                        <label className='formLabel p-0 mb-0'>{t('sticker')} </label>
                        <div className={`position-relative mt-1 ${data?.sticker?.length > 0 && "border rounded"} border-0`} style={{ width: "11rem" }} >
                            {console.log(data?.sticker)}
                            {(data?.sticker) ? (
                                <>
                                    <Tooltip title={t('remove-cover')}>
                                        <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                            <img src={removeIcon} alt="delete" onClick={removeImage} />
                                        </label>
                                    </Tooltip>
                                    <div className='d-flex justify-content-center align-items-center flex-column'>
                                        <div className='globalPreviewedImage' style={{ backgroundImage: `url(${img})`, scale: ".9", backgroundPosition: "center", backgroundSize: "contain" }}></div>
                                        <p className='text-center' >{data?.sticker[0]?.type}</p>
                                    </div>
                                </>
                            ) :
                                <>
                                    <div className='px-0'>
                                        <div className='imageWrapper p-0 mt-2'>
                                            <label htmlFor='songCover' className='d-flex flex-column justify-content-center align-items-center'>
                                                <img id='blah' width='70px' height='50px' src={uploadImage} alt='upload' />
                                                <input   accept=".png, .jpg, .jpeg"  onChange={upload} name="image" type="file" className={`d-none`} id="songCover" />
                                                <span> {t('upload-sticker')} </span>
                                            </label>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className='text-danger validateToDelete'> {errors?.image}</div>

                            {!loading
                                ? <button onClick={addSticker} className='confirmBtn' type='submit' disabled={!data?.sticker}>{t(`create`)}</button>
                                : <button className='confirmBtn' disabled> <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>{t('saving')}...</button>
                            }
                        </div>
                        <hr />
                    </div>
                    }
                    <div className='d-flex flex-wrap justify-content-start align-items-start w-100'>

                        {stickers.length > 0
                            ? stickers.map((item, index) => {
                                return (
                                    <Card style={{width:'200px', height:'170px'}} key={index} className="m-3 position-relative d-flex justify-content-center align-items-center" >
                                        <CardContent className="d-flex flex-column justify-content-between ">
                                            <Typography className="d-flex justify-content-between align-items-center  w-75" gutterBottom >
                                                <img style={{width:'120px'}} src={item.sticker} alt="" />
                                                {Permissions?.sound?.delete && <Tooltip title={t("delete")}>
                                                    <img data-bs-toggle="modal" onClick={() => setDeletedId(item.id)} data-bs-target='#deleteModal' className='iconActions mx-1' style={{ cursor: "pointer" }} src={delete_icon} alt="delete" />
                                                </Tooltip>}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )
                            })
                            :
                            <div className='d-flex justify-content-center align-items-center w-100 '>
                                <NotDataFound />
                            </div>
                        }

                    </div>
                </div>
                <div className='d-flex justify-content-end mt-auto'>
                    <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
                </div>
                <DeleteModal onSubmitDelete={onSubmitDelete} name='sound'></DeleteModal>
            </section>}
        </>
    )
}
