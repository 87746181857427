import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function MessageModal({description}) {
    return(
        <div className="modal fade" id="readMsgModel" tabIndex={1} aria-labelledby="toggleblockIpModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content flex-column">
                <div className="modal-header w-100">
                    <div className="closeModalIcon d-flex mx-3 justify-content-between w-100" data-bs-dismiss="modal" aria-label="Close">
                        <label>the message content </label>
                        <FontAwesomeIcon className='text-end' icon={faXmark} />
                    </div>
                </div>
                <div className="modal-body my-1 mt-0">
                    <form className='w-100'>
                        <textarea style={{ height: '11.25rem' }} className='w-100' value={description} type='text' readOnly />
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}