import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllCurrencies(obj = '') {
    return await https.get(`${api_url}admin/currency?${obj}`);
}

//get Cities by id
export async function getCurrency(id) {
    return await https.get(`${api_url}admin/currency/${id}`);
}

// create Cities
export async function createCurrency(data) {
    return await https.post(`${api_url}admin/currency`, data);
}

// update Cities
export async function updateCurrency(id, data) {
    return await https.patch(`${api_url}admin/currency/${id}`, data);
}

// delete Cities
export async function deleteCurrency(id) {
    return await https.delete(`${api_url}admin/currency/${id}`);
}
