import '../forms.scss'
import { t } from 'i18next'
import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { preventEnter } from '../../Utilities/preventEnter'
import { api_url, basename } from '../../Configuration/config.js'
import uploadSong from '../../Assets/Icons/upload-song.svg'
import musicImg from '../../Assets/Icons/musiccc.svg'
import removeIcon from "../../Assets/Icons/removeIcon.svg"
import TitleContext from '../../Contexts/TitleContext'
import axios from 'axios'
import uploadImage from "../../Assets/Icons/uploadimage.svg"
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'
import { getSound } from '../../Apis/sounds'
import Swal from 'sweetalert2'

function MusicForm() {
    const { id } = useParams()
    let navigate = useNavigate()
    const { i18n } = useTranslation();
    const { pathname } = useLocation()
    const [data, setData] = useState()
    const [idData, setIdData] = useState()
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const { changeTitle } = useContext(TitleContext)
    const [selectedAudio, setSelectedAudio] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (id) {
            changeTitle(t(`update-sound`))
        } else {
            changeTitle(t(`add-sound`))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    useEffect(() => {
        if (id) Sound()
    }, [id])

    const Sound = async () => {
        await getSound(id).then((res) => {
            const obj = res?.data?.data
            setIdData(obj)
            setData(obj)
        })
    }
    const changeHandler = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
        setErrors({ ...errors, [name]: "" })
    }
    const uploadCover = (e) => {
        setData({ ...data, image: [...e.target.files] })
        setErrors({ ...errors, image: "" })
    }
    const SongUpload = (e) => {
        const file = e.target.files[0];
        setData({ ...data, sound: [...e.target.files] })
        setErrors({ ...errors, sound: "" })
        if (file && file.type.includes('audio')) {
            setSelectedAudio(URL.createObjectURL(file));
        } else {
            setSelectedAudio(null);
        }
    }
    const deleteSong = () => setData({ ...data, sound: "" })
    const removeImage = () => { setData({ ...data, image: "" }) }
    /************************* Actions ******************************/
    const token = `Bearer ${localStorage.getItem("Sport_Admin_Token")}`
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
            AccessControlAllowOrigin: '*',
            lang: 'en',
            country: 'uae',
        }
    }
    async function AddHandler(e) {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData();
        formData.append('title', data?.title);
        formData.append('singer', data?.singer);
        data?.sound && formData.append('sound', ...data?.sound);
        data?.image && formData.append('image', ...data?.image);
        try {
            await axios.post(`${api_url}admin/sounds`, formData, config)
            navigate(`${basename}/dashboard/sound`);
            setLoading(false)
        } catch (err) {
            setErrors(err?.response?.data?.errors)
            setLoading(false)
        }
    }
    async function updateHandler(e) {
        e.preventDefault()
        setLoading(true)
        let updating = { ...data }
        delete updating?.created_at
        delete updating?.id
        delete updating?.length
        delete updating?.size

        const formData = new FormData();
        
        formData.append('_method', "patch");
        
        if (idData?.image === updating?.image) {
            delete updating?.image
        } else {
            formData.append('image', (updating?.image ? {...updating?.image} : ''));
        }
        if (idData.sound === updating?.sound) {
            delete updating?.sound
        } else {
            console.log(updating?.sound)
            formData.append('sound', (updating?.sound ? updating?.sound[0] : '') );
        }
        if (idData?.title === updating?.title) {
            delete updating?.title
        } else {
            formData.append('title', updating?.title);
        }
        if (idData?.singer === updating?.singer) {
            delete updating?.singer
        } else {
            formData.append('singer', updating?.singer);
        }
        
        if (
            updating?.sound === '' ||
            updating?.image === '' ||
            updating?.title === '' ||
            updating?.singer === '' 
         ) {
        Swal.fire('Please fill all fields')    
        setLoading(false)

        }

        if (idData === updating) {
            navigate(`${basename}/dashboard/sound`);
        } else {
            try {
                await axios.post(`${api_url}admin/sounds/${id}`, formData, config)
                navigate(`${basename}/dashboard/sound`);
                setLoading(false)
            } catch (err) {
                setErrors(err.data.data)
                console.log(err)
                setLoading(false)
            }
        }
    }
    return (
        <section className='componentGlobalWrapper musicContainer' >
            <form id='adminForm' onSubmit={id ? (e) => updateHandler(e) : (e) => AddHandler(e)} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-start flex-column mb-5 w-100'>
                    <div className='mb-4' style={{ "width": "50%" }}>
                        <div className='d-flex flex-column'>
                            <label htmlFor={`sound-title`} className='formLabel'>{t('sound-title')}</label>
                            <input name="title" id={`sound-title`} type='text' placeholder={t('sound-title')} value={data?.title} onChange={(e) => changeHandler(e)} minLength={3} maxLength={20} required />
                        </div>
                        <div className='text-danger validateToDelete'> {errors?.title} </div>
                    </div>
                    <div className='mb-4' style={{ "width": "50%" }}>
                        <div className='d-flex flex-column'>
                            <label htmlFor={`singer`} className='formLabel '>{t('singer')}</label>
                            <input name="singer" id={`singer`} type='text' placeholder={t('singer')} value={data?.singer} onChange={(e) => changeHandler(e)} minLength={3} maxLength={20} required />
                        </div>
                        <div className='text-danger validateToDelete'> {errors?.singer} </div>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel p-0 mb-0'>{t('sound')} </label>
                        <div className={`position-relative mt-1 ${data?.sound?.length > 0 && "border rounded"} border-0`} style={{ width: "20rem" }} >
                            <input className="visually-hidden" type="file" accept="audio/*" id="sound" onChange={SongUpload} name="sound" />
                            {data?.sound?.length > 0 ? (
                                <>
                                    <Tooltip title={t('remove-sound')}>
                                        <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                            <img src={removeIcon} alt="delete" onClick={deleteSong} />
                                        </label>
                                    </Tooltip>
                                    <div>
                                        <p>{data?.sound[0]?.name}</p>
                                        {selectedAudio ?
                                            <audio controls>
                                                <source src={selectedAudio} />
                                                <source src={selectedAudio} type="audio/mpeg" />
                                                <source src={selectedAudio} type="audio/ogg" />
                                                <source src={selectedAudio} type="audio/wav" />
                                                Your browser does not support the audio element.
                                            </audio>
                                            : <div className='d-flex justify-content-center'>
                                                {idData?.title + ' ' + idData?.length}
                                            </div>
                                        }
                                    </div>
                                </>
                            ) : (
                                <label htmlFor="sound" style={{ width: "fit-content" }} className="form-label  my-2 ">
                                    <div className='imageWrapper ' style={{ "width": "11rem" }}>
                                        <img src={uploadSong} className='uploadSong' alt='Upload sound' />
                                        <span> {t('upload-sound')} </span>
                                    </div>
                                </label>
                            )}
                        </div>
                        <div className='text-danger validateToDelete'> {errors?.sound} </div>
                    </div>
                    <label className='formLabel p-0 mb-0'>{t('image')} </label>
                    <div className={`position-relative mt-1 ${data?.image?.length > 0 && "border rounded"} border-0`} style={{ width: "11rem" }} >
                        {(data?.image?.length > 0) ? (
                            <>
                                <Tooltip title={t('remove-cover')}>
                                    <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                        <img src={removeIcon} alt="delete" onClick={removeImage} />
                                    </label>
                                </Tooltip>
                                <div className='d-flex justify-content-center align-items-center flex-column'>
                                    <div className='globalPreviewedImage' style={{ backgroundImage: data.image[0].type ? `url(${URL.createObjectURL(data?.image[0])})` : `url(${data?.image})`, scale: ".9", backgroundPosition: "center", backgroundSize: "contain" }}></div>
                                    <p className='text-center' >{data?.image[0]?.type}</p>
                                </div>
                            </>
                        ) :
                            <>
                                <div className='px-0'>
                                    <div className='imageWrapper p-0 mt-2'>
                                        <label htmlFor='songCover' className='d-flex flex-column justify-content-center align-items-center'>
                                            <img id='blah' width='70px' height='50px' src={uploadImage} alt='upload' />
                                            <input accept="image/*" onChange={uploadCover} name="image" type="file" className={`d-none`} id="songCover" />
                                            <span> {t('upload-image')} </span>
                                        </label>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='text-danger validateToDelete'> {errors?.image}</div>
                </div>
                {!loading
                    ? <button className='confirmBtn' type='submit'>{id ? t(`save-changes`) : t(`create`)}</button>
                    : <button className='confirmBtn' disabled> <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>{t('saving')}...</button>
                }
            </form>
        </section>
    )
}
export default MusicForm





