import '../forms.scss'
import { t } from 'i18next'
import moment from 'moment'
import i18n from '../../i18n'
import '../tables.scss'
import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import TitleContext from '../../Contexts/TitleContext'
import search from "../../Assets/Icons/search.svg"
import edit from "../../Assets/Icons/edit.svg"
import profile from "../../Assets/Icons/profile.svg"
import noImg from "../../Assets/Images/noImg.png"
import { basename } from '../../Configuration/config'
import Tooltip from '@mui/material/Tooltip'
import { Link, useSearchParams } from 'react-router-dom'
import PermissionsContext from '../../Contexts/permissionsContext'
import { activateClub, archiveClub, getAllClubs } from '../../Apis/clubs'
import Pagination from '../../Components/Pagination/Pagination'
import { getStatuses } from '../../Apis/statics'
import { dateLanguage } from '../../Utilities/dateLanguage'
import { getAllCities } from '../../Apis/cities'
import NotDataFound from '../../Components/NotDataFound/notDataFound'
import block from "../../Assets/Icons/block.svg"
import unblock from "../../Assets/Icons/unblock.svg"

export default function Clubs() {
    let { Permissions } = useContext(PermissionsContext)
    const [searchParams] = useSearchParams()
    const [status, setStatus] = useState(searchParams.get("status") ?? 'active')
    const [statuses, setStatuses] = useState([])
    const [Clubs, setClubs] = useState([])
    const [cities, setcities] = useState([])
    //const [selectedId ,setSelectedId] = useState('')
    const { pathname } = useLocation()
    const { changeTitle } = useContext(TitleContext)
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '', status: status, city: '' })
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '', city: '' })
    const [pageCount, setPageCount] = useState('')
    const navigate = useNavigate()



    function getClubs(obj) {
        let filter = { ...obj }
        let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&status=${filter.status}&city=${filter.city}`
        getAllClubs(params).then(res => {
            console.log(res)
            setClubs(res.data.data)
            let total = res.data.pagination.total
            setPageCount(Math.ceil(total / filter.per_page))
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        // eslint-disable-next-line
    }, [pathname])

    useEffect(() => {
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    }, [i18n.language])

    useEffect(() => {
        getStatuses('club').then((res) => {
            setStatuses(res.data.data)
        })

        getAllCities().then((res) => {
            setcities(res.data.data)
        })
        changeTitle(t(`clubs`), Permissions?.club?.create && { url: 'app-users/clubs/create', text: t('add-club'), canAdd: Permissions?.club?.create })
        getClubs(filterObj)
        // eslint-disable-next-line
    }, [])

    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 }
        thisObj[e.target.name] = e.target.value.trim()
        setfilterObj2(thisObj)
    }

    function getFiltered() {
        let thisObj = { ...filterObj }
        thisObj.page = 1
        thisObj.per_page = filterObj2.per_page
        thisObj.search = filterObj2.search
        thisObj.city = filterObj2.city
        setfilterObj(thisObj)
        getClubs(thisObj)
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj }
        filter.page = data.selected + 1
        setfilterObj(filter)
        getClubs(filter)

        let thisObj2 = { ...filterObj2 }
        thisObj2.per_page = filter.per_page
        thisObj2.search = filter.search
        thisObj2.city = filter.city
        setfilterObj2(thisObj2)
    }

    function filterByStatus(value) {
        setStatus(value)
        let obj = { ...filterObj }
        obj.status = value
        obj.page = 1
        setfilterObj(obj)
        getClubs(obj)

        let thisObj2 = { ...filterObj2 }
        thisObj2.per_page = obj.per_page
        thisObj2.search = obj.search
        thisObj2.city = obj.city
        setfilterObj2(thisObj2)
        navigate(`${basename}/dashboard/app-users/clubs?status=${value}`)
    }

    function handleActivate(id) {
        activateClub(id).then(() => {
            getClubs(filterObj)
        })
    }

    function handleArchive(id) {
        archiveClub(id).then(() => {
            getClubs(filterObj)
        })
    }

    return (
        <>
            {<section className='componentGlobalWrapper d-flex flex-column'>
                <div>
                    <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                        {
                            statuses?.map((item, index) => {
                                return (
                                    <React.Fragment key={index} >
                                        {index ? <span className='separator'></span> : ''}
                                        <div onClick={() => filterByStatus(item.slug)} className={`tablinks ${status === item.slug && `active`}`} id={`${item.slug}-tab`} data-bs-toggle="tab" data-bs-target={`#${item.slug}`}>{item?.name} <span className='tabcounter'>{status === item.slug && "(" + Clubs.length + ")"}</span></div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>

                    <div className='searchActionsBar w-100'>
                        <div className='searchActionsBar mt-0 mb-0'>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                        </div>

                        {/* cities */}
                        <div className="cityFilter">
                            <select defaultValue='' name="city" onChange={changeFilterObj} className="form-select citySelect">
                                <option value="" >{t('all-cities')}</option>
                                {cities && cities.map((city, i) => {
                                    return <option key={i} value={city.id}>{city.name}</option>
                                })}
                            </select>
                        </div>

                        {/* show select */}
                        <div className='perPageContainer'>
                            <label htmlFor='selectShow' className='perPageLabel'>
                                {t('show')}
                            </label>
                            <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                            </select>
                        </div>

                        {/*** apply search btn  ***/}
                        <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
                    </div>

                    <div className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr>
                                    <th>{t('image')}</th>
                                    <th>{t('name')}</th>
                                    <th>{t('email')}</th>
                                    <th>{t('city')}</th>
                                    <th>{t('created-at')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Clubs.length > 0
                                    ? Clubs.map((item, index) => {
                                        return (
                                            <tr key={index} >
                                                <td className='sliderImg'>
                                                    <img src={item?.media ? item?.media?.url : noImg} alt="slider" />
                                                </td>
                                                <td className='desc'>
                                                    <div className='descriptionTd'>
                                                        {item?.business_name}
                                                    </div>
                                                </td>
                                                <td className='desc'>
                                                    <div className='descriptionTd'>
                                                        {item?.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    {item?.city}
                                                </td>
                                                <td>
                                                    {moment(item.created_at).format('DD-MMMM-YYYY')}
                                                </td>
                                                <td>
                                                    <Tooltip title={t('profile')}>
                                                        <Link to={`${basename}/dashboard/app-users/clubs/${item.id}/details`}>
                                                            <img className='iconActions' src={profile} alt="profile" />
                                                        </Link>
                                                    </Tooltip>
                                                    {Permissions?.club?.edit &&
                                                        <Tooltip title={t('edit')}>
                                                            <Link to={`${basename}/dashboard/app-users/clubs/${item.id}/update`}>
                                                                <img className='iconActions' src={edit} alt="edit" />
                                                            </Link>
                                                        </Tooltip>
                                                    }

                                                    {Permissions?.user['block-unblock'] &&
                                                        <>
                                                            {(item?.status === 'active') &&
                                                                <Tooltip title={t('block')}>
                                                                    <img onClick={() => handleArchive(item?.id)} className='iconActions' src={block} alt="block" />
                                                                </Tooltip>
                                                            }
                                                            {(item?.status === 'archived') &&
                                                                <Tooltip title={t('activate')}>
                                                                    <img onClick={() => handleActivate(item?.id)} className='iconActions' src={unblock} alt="activate" />
                                                                </Tooltip>}
                                                        </>}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )
                                    :
                                    <tr >
                                        <td colSpan={6} >
                                            <NotDataFound />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='d-flex justify-content-end mt-auto'>
                    <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
                </div>
            </section>}
        </>
    )
}
