import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllArticles(params){
    return await https.get(`${api_url}admin/blogs?${params}`);
}

//get blogs by id
export async function getArticle(id){
    return await https.get(`${api_url}admin/blogs/${id}`);
}

// create blogs
export async function createArticles(data){
    return await https.post(`${api_url}admin/blogs`,data);
}

// update blogs
export async function updateArticles(id,data){
    return await https.patch(`${api_url}admin/blogs/${id}`,data);
}

// toggle status
export async function activateArticle(id){
    return await https.patch(`${api_url}admin/blogs/${id}/activate`);
}

// delete blogs
export async function deleteArticles(id){
    return await https.delete(`${api_url}admin/blogs/${id}`);
}

// delete blogs
export async function deletePermanentlyArticles(id){
    return await https.delete(`${api_url}admin/blogs/${id}`);
}


// restore blogs
export async function restoreArticles(id){
    return await https.patch(`${api_url}admin/blogs/${id}/restore`);
}

// Archive blogs
export async function archiveArticles(id){
    return await https.patch(`${api_url}admin/blogs/${id}/archive`);
}