import { t } from 'i18next';
import React, { useState, useEffect, useContext } from 'react'
import TitleContext from '../../../Contexts/TitleContext';
import { supportedLanguages } from './../../../Utilities/SupportedLanguages';
import { getSetting, updateSetting } from '../../../Apis/settings';
import { useNavigate } from 'react-router';
import PermissionsContext from '../../../Contexts/permissionsContext';
import term from '../../../Assets/Icons/terms.svg'
import { useTranslation } from 'react-i18next';
export default function TermsAndConditions() {
    const { Permissions } = useContext(PermissionsContext);
    const { changeTitle } = useContext(TitleContext);
    const [Locales, setLocales] = useState({});
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()
    // let [errors, setErrors] = useState({})
    const {i18n} = useTranslation()
    useEffect(() => {
        changeTitle(t('terms-and-conditions'), '');
        TermsandConditionsData()
        // eslint-disable-next-line
    }, [])

    async function TermsandConditionsData() {
        try {
            const res = await getSetting("terms-and-conditions")
            let data = res.data.data?.locales;
            setLocales(data);
        } catch (err) {
            console.log(err.response);
        };
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        setLoader(true)
        let locales = { ...Locales };
        console.log("SUBMITTED", locales);
        try {
            await updateSetting("terms-and-conditions", locales)
            setLoader(false)
            navigate('/dashboard/settings/appearance')
        } catch (err) {
            console.log(err);
            // setErrors(err)
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setLocales((prevState) => ({
            ...prevState,
            [name]: {
                description: value
            }
        }));
        console.log(Locales)
    }

    return (
        <div className='componentGlobalWrapper' style={{ backgroundImage: `url(${term})`, backgroundRepeat: "no-repeat", backgroundPosition: i18n.language === "ar" ? "left" :"right"}}>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{t('page-content')}</h5>
                <hr/>

                <form className='pagesForm w-100 pt-5' onSubmit={submitHandler} >
                <div className='d-flex flex-column'>
                    {supportedLanguages?.map((language, index) => {
                        return <div key={index} className='d-flex flex-column mb-4'>
                            <label htmlFor='content' className='formLabel'>{`${t('content-in')} (${t(`${language.name}`)})`}</label>
                            <div {...(language.slug === 'ar' ? { 'dir': 'rtl' } : {})}>

                                <textarea disabled={!Permissions?.setting?.edit} name={`${language.slug}`} className='textArea w-50' value={Locales[language.slug]?.description} onChange={(e) => handleChange(e, language.slug)} />
                            </div>
                        </div>
                    })}
                    </div> 

                    { //Permissions?.appearance?.edit && 
                        <>
                            { Permissions?.setting?.edit && !loader ?
                                <div>
                                    <button className='confirmBtn' type='submit' >
                                        {t('save-changes')}
                                    </button>
                                </div>
                                :(Permissions?.setting?.edit &&
                                <button className='confirmBtn' disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {t('saving')}...
                                </button>)
                            }
                        </>}
                </form>
            </div>
        </div>
    )
}

