import React, { useRef ,useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { blockValidation, resetBlockInput } from './deleteHandler';
import { basename } from '../Configuration/config';
import {  useNavigate } from 'react-router';
import { t } from 'i18next';

export default function BlockModal(props, type) {
    const [loading, setLoading] = useState(false);
    const [validateBlock , setValidateBlock] = useState("");
    const blockModalRef = useRef();
    const closeBlockModalRef = useRef();
    let [errors, setErrors] = useState('');
    const navigate = useNavigate();

    const confirmBlock = async (e) =>{
        e.preventDefault();
        if (validateBlock === "BLOCK"){
            setLoading(true);
            let submitProps = await props.onSubmitBlock();
            console.log(submitProps)
            if(submitProps === "done"){
                setLoading(false);
                closeBlockModalRef.current.click();
                document.getElementById('blockForm').reset();
            }else if(submitProps === 'blockRole'){
                setLoading(false);
                closeBlockModalRef.current.click();
                navigate(`${basename}/dashboard/admins/roles/add`);
            }else{
                setLoading(false);
                setErrors(submitProps);
            }
        }else{
            blockModalRef.current.classList.remove("d-none");
        }
    }

    return (
        <div>
            <div className="modal fade" id="blockModal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div ref={closeBlockModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={()=> resetBlockInput("blockForm" ,blockModalRef ,setValidateBlock , setErrors)}>
                                <FontAwesomeIcon icon={faXmark}/>
                            </div>
                        </div>
                        <div className="modal-body">
                            <form id="blockForm" onSubmit={confirmBlock} style={{'width':'100%'}}>
                                <div className='formInputsWrapper' style={{'width':'100%'}}>
                                    <p>{t('do-you-want-to-delete-this')} {t(`${props.name}`)} {t('?')} </p>
                                    <div className='mb-4'>
                                        <div className="form-floating">
                                            <input autoComplete='off' onChange={(e)=> blockValidation(e , setValidateBlock  ,blockModalRef)} className='w-100 form-control' name="validate" id='floatingInput2' type='text' placeholder='BLOCK' required pattern="\S(.*\S)?" title="This field is required" />
                                            <label htmlFor="floatingInput2">{t('please-type')} <span>"BLOCK"</span> {t('to-confirm')}</label>
                                        </div>
                                        <div ref={blockModalRef} className='validateToDelete d-none text-danger mt-1'>
                                            {t('please-type-"BLOCK"-in-capital-letters')}.
                                        </div>
                                        {(errors)? <div className="validateToDelete text-danger mt-1">{errors}</div> : ''}
                                    </div>
                                    {/*** modal footer ***/}
                                    <div className='d-flex justify-content-end align-items-end'>
                                        <div className='cancelBtn' onClick={()=> resetBlockInput("blockForm" ,blockModalRef ,setValidateBlock , setErrors)} data-bs-dismiss="modal">{t('cancel')}</div>
                                        {!loading ? 
                                            <button className={validateBlock === "BLOCK" ? `deleteBtn` : `confirmBtn m-0`} type='submit'>{t('block')}</button>
                                            :
                                            <button className='confirmBtn deleteBtn m-0' disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {t('saving')}...
                                            </button>
                                        }                                            
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
