import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllCountries(){
    return await https.get(`${api_url}admin/countries`);
}

//get Countries by id
export async function getCountry(id){
    return await https.get(`${api_url}admin/countries/${id}`);
}

// create Countries
export async function createCountries(data){
    return await https.post(`${api_url}admin/countries`,data);
}

// update Countries
export async function updateCountries(id,data){
    return await https.patch(`${api_url}admin/countries/${id}`,data);
}

// delete Countries
export async function deleteCountries(id){
    return await https.delete(`${api_url}admin/countries/${id}`);
}
