import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getAllClubs(obj){
    return await https.get(`${api_url}admin/clubs?${obj}`);
}

//get clubs by id
export async function getClub(id){
    return await https.get(`${api_url}admin/clubs/${id}`);
}

//get clubsOverview by id
export async function getClubOverView(id){
    return await https.get(`${api_url}admin/clubs/${id}/overview`);
}
// toggle archive clubs
export async function archiveClub(id){
    return await https.post(`${api_url}admin/clubs/${id}/archive`);
}

// toggle activate clubs
export async function activateClub(id){
    return await https.post(`${api_url}admin/clubs/${id}/activate`);
}

// create club
export async function createClub(data){
    return await https.post(`${api_url}admin/clubs`,data);
}

// toggle update clubs
export async function updateClub(id,data){
    return await https.post(`${api_url}admin/clubs/${id}`,data);
}

/* *********************** club players ******************************* */

export async function getClubPlayers(obj){
    return await https.get(`${api_url}admin/club-players?${obj}`);
}

export async function getClubPlayer(id){
    return await https.get(`${api_url}admin/club-players/${id}`);
}

export async function createClubPlayer(data){
    return await https.post(`${api_url}admin/club-players`,data);
}

export async function updateClubPlayer(id,data){
    return await https.post(`${api_url}admin/club-players/${id}`,data);
}

export async function deleteClubPlayer(id){
    return await https.delete(`${api_url}admin/club-players/${id}`);
}


/* *********************** club president ******************************* */

export async function getClubPresident(id){
    return await https.get(`${api_url}admin/clubs/${id}/president`);
}

export async function createClubPresident(id,data){
    return await https.post(`${api_url}admin/clubs/${id}/president`,data);
}

export async function updateClubPresident(id,data){
    return await https.post(`${api_url}admin/clubs/${id}/president`,data);
}


/* *********************** club Plans ******************************* */

export async function getClubPlans(id){
    return await https.get(`${api_url}admin/plans?club_id=${id}`);
}

export async function createClubPlans(data){
    return await https.post(`${api_url}admin/plans`,data);
}

export async function updateClubPlan(data){
    return await https.patch(`${api_url}admin/plans`,data);
}


/* *********************** club Videos ******************************* */

export async function getVideos(id, filter){
    return await https.get(`${api_url}admin/videos?user_id=${id}&&${filter}`);
}
/* *********************** club Videos ******************************* */

export async function getCompitions(id){
    return await https.get(`${api_url}admin/competitions?user_id=${id}`);
}
/* *********************** club Pre Features ******************************* */

export async function getClubProFeatures(id){
    return await https.get(`${api_url}admin/clubs/${id}/club-features`);
}
export async function updateClubProFeatures(id, data){
    return await https.patch(`${api_url}admin/clubs/${id}/club-features`, data);
}
