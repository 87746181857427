    // to validate before deleting
    export function deleteValidation(e , setValidateDelete ,deleteModalRef){
        setValidateDelete(e.target.value);
        if(e.target.value === "DELETE"){
            deleteModalRef.current.classList.add("d-none");
        }
    }

       // reset delete modal data
    export function resetInput(id ,deleteModalRef ,setValidateDelete , setErrors){
        document.getElementById(id).reset();
        deleteModalRef.current.classList.add("d-none");
        setValidateDelete("");
        setErrors([]);
    }

        // to validate before block
        export function blockValidation(e , setValidateBlock ,blockModalRef){
            setValidateBlock(e.target.value);
            if(e.target.value === "BLOCK"){
                blockModalRef.current.classList.add("d-none");
            }
        }
    
           // reset block modal data
        export function resetBlockInput(id ,blockModalRef ,setValidateBlock , setErrors){
            document.getElementById(id).reset();
            blockModalRef.current.classList.add("d-none");
            setValidateBlock("");
            setErrors([]);
        }
    