import { t } from 'i18next';
import React , {useState , useEffect ,useContext /* , useRef */} from 'react';
import '../tables.scss';  
import { useLocation } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../Assets/Icons/edit.svg";
import delete_icon from "../../Assets/Icons/delete.svg";
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import { Link /* , useSearchParams */ } from 'react-router-dom';
import DeleteModal from '../../Utilities/deleteModal';
import i18n from '../../i18n';
import moment from 'moment';
import '../forms.scss';
import PermissionsContext  from '../../Contexts/permissionsContext';
import { deletePlayerPositions, getAllPlayerPositions } from '../../Apis/playerPositions';
import { dateLanguage } from '../../Utilities/dateLanguage';
import NotDataFound from '../../Components/NotDataFound/notDataFound';

export default function PlayerPositions() {
    let {Permissions} = useContext(PermissionsContext);
    const [playersPositions , setPlayersPositions] = useState([]);
    const [deletedId ,setDeletedId] = useState('');
    const { pathname } = useLocation();
    const {changeTitle} = useContext(TitleContext);

    function getPlayersPositions(){
        getAllPlayerPositions().then(res => {
            setPlayersPositions(res.data.data);
        })
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        
        // eslint-disable-next-line
    },[pathname]);

    useEffect(() => {
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    },[i18n.language])

    useEffect(() => {
        changeTitle(t(`player-positions`) ,Permissions['player-position']?.create && {url: 'player-positions/create' , text: t('add-position'), canAdd:Permissions['player-position']?.create});
        getPlayersPositions()
        // eslint-disable-next-line
    },[]);

    async function onSubmitDelete(){
        return await deletePlayerPositions(deletedId).then(res =>{
            getPlayersPositions();
            return "done";
        }).catch(err => {
            console.log(err.response.data.message)
            return err.response.data.message ;
        })
    }

    return (
        <>
            { <section className='componentGlobalWrapper d-flex flex-column'>
                <div>
                    {<div className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr>
                                    <th>{t('name')}</th>
                                    <th>{t('created-at')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {playersPositions.length > 0 ? playersPositions.map((position , index)=>{
                                    let lang = i18n.language;
                                    return(
                                        <tr key={index}>
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    { position?.locales && position?.locales[lang]?.name}
                                                </div>
                                            </td>
                                            <td>
                                                {moment(position.created_at).format('DD-MMMM-YYYY')}
                                            </td>
                                            <td>
                                                
                                            <div>
                                                {Permissions['player-position']?.edit && <Tooltip title={t('edit')}>
                                                    <Link to={`${basename}/dashboard/player-positions/${position.id}/update`}>
                                                        <img className='iconActions' src={edit_icon} alt="edit"/>
                                                    </Link>
                                                </Tooltip>
                                                }
                                                {Permissions['player-position']?.delete &&
                                                <Tooltip title={t("delete")}>
                                                    <img data-bs-toggle="modal" onClick ={()=>setDeletedId(position.id)} data-bs-target='#deleteModal' className='iconActions' src={delete_icon} alt="delete"/>
                                                </Tooltip>
                                                }
                                            </div>
                                            </td>
                                        </tr>
                                )})
                                :
                                <tr >
                                    <td colSpan={6} >
                                        <NotDataFound />
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                        <DeleteModal onSubmitDelete={onSubmitDelete} name='position'></DeleteModal>
                    </div>}
                </div>
            </section>}
        </>
    )
}
