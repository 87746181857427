import { t } from 'i18next';
import React, { useState, useEffect, useContext /* , useRef */ } from 'react';
import '../tables.scss';
import { useLocation } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../Assets/Icons/edit.svg";
import delete_icon from "../../Assets/Icons/delete.svg";
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import { Link /* , useSearchParams */ } from 'react-router-dom';
import DeleteModal from '../../Utilities/deleteModal';
import i18n from '../../i18n';
import moment from 'moment';
import '../forms.scss';
import PermissionsContext from '../../Contexts/permissionsContext';
import { deletePlayerFoots, getAllPlayerFoots } from '../../Apis/playerFoots';
import { dateLanguage } from '../../Utilities/dateLanguage';
import NotDataFound from '../../Components/NotDataFound/notDataFound';

export default function PlayerFoots() {
    let { Permissions } = useContext(PermissionsContext);
    const [PlayerFoots, setPlayerFoots] = useState([]);
    const [deletedId, setDeletedId] = useState('');
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);

    function getPlayerFoots() {
        getAllPlayerFoots().then(res => {
            setPlayerFoots(res.data.data);
        })
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    }, [i18n.language])

    useEffect(() => {
        changeTitle(t(`player-foots`), Permissions['player-footnesse']?.create && { url: 'player-foots/create', text: t('add-foot'), canAdd: Permissions['player-footnesse']?.create });
        getPlayerFoots()
        // eslint-disable-next-line
    }, []);

    async function onSubmitDelete() {
        return await deletePlayerFoots(deletedId).then(res => {
            getPlayerFoots();
            return "done";
        }).catch(err => {
            console.log(err.response.data.message)
            return err.response.data.message;
        })
    }

    return (
        <>
            {<section className='componentGlobalWrapper d-flex flex-column'>
                <div>
                    {<div className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr>
                                    <th>{t('name')}</th>
                                    <th>{t('created-at')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {PlayerFoots.length > 0 ? PlayerFoots.map((item, index) => {
                                    let lang = i18n.language;
                                    return (
                                        <tr key={index}>
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    {item?.locales && item?.locales[lang]?.name}
                                                </div>
                                            </td>
                                            <td>
                                                {moment(item.created_at).format('DD-MMMM-YYYY')}
                                            </td>
                                            <td>

                                                <div>
                                                    {Permissions['player-footnesse']?.edit && <Tooltip title={t('edit')}>
                                                        <Link to={`${basename}/dashboard/player-foots/${item.id}/update`}>
                                                            <img className='iconActions' src={edit_icon} alt="edit" />
                                                        </Link>
                                                    </Tooltip>
                                                    }
                                                    {Permissions['player-footnesse']?.delete &&
                                                        <Tooltip title={t("delete")}>
                                                            <img data-bs-toggle="modal" onClick={() => setDeletedId(item.id)} data-bs-target='#deleteModal' className='iconActions' src={delete_icon} alt="delete" />
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                    :
                                    <tr >
                                        <td colSpan={6} >
                                            <NotDataFound />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <DeleteModal onSubmitDelete={onSubmitDelete} name='foot'></DeleteModal>
                    </div>}
                </div>
            </section>}
        </>
    )
}
